import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Cross } from "../../components/mikro/Icon";
import { action_transaksi_get_lacak } from "../../store/action/transaksi_action";
import { useParams } from "react-router-dom";

const ModalLacakPengiriman = ({ data, panelShow,...props}) => {

  const dispatch = useDispatch()
  const {kodeToko} = useParams()
  const [bill, set_bill] = useState({
    kurir: '-',
    layanan: '-',
    no_resi: '-',
    riwayat: [],
    status: '-',
  })

  useEffect(()=>{
    if(panelShow)
      dispatch(action_transaksi_get_lacak(kodeToko,data?.transaksiId,data?.pesananId,(res)=>{
        set_bill(res.data)
      }))
  },[panelShow])
  return (
    <>
      <div
        className={
          "shop-fillter offer-filter offcanvas offcanvas-bottom bg-white" +
          (panelShow ? " show" : "")
        }
        style={{
          boxShadow: "rgb(0 0 0 / 10%) 0px 0px 3px 1px",
          height: props.height || "auto",
        }}
      >
        <div className="offcanvas-header d-flex flex-column border-bottom p-0">
          <div className="w-100 d-flex">
            <div
              class="p-3 pointer"
              onClick={() => props.onClose()}
            >
              <Cross width="20px" height="20px" />
            </div>
            <h4 className="title-color m-0 font-md fw-bold flex-fill w-100 text-start py-3">
              Status Pengiriman
            </h4>
          </div>
        </div>
        <div
          className="main-wrap offcanvas-body small pb-5 bg-white p-3"
          style={{
            height: props.height || "auto",
          }}
        >
          <div className="card mb-4">
            <div className="card-body">
              <table className="table table-sm table-borderless">
                <tbody>
                  <tr>
                    <th width={"10px"}>No Resi</th>
                    <td width={"1px"}>:</td>
                    <td>{bill?.no_resi}</td>
                  </tr>
                  <tr>
                    <th width={"10px"}>Kurir</th>
                    <td width={"1px"}>:</td>
                    <td>{bill?.kurir}</td>
                  </tr>
                  <tr>
                    <th width={"10px"}>Layanan</th>
                    <td width={"1px"}>:</td>
                    <td>{bill?.layanan}</td>
                  </tr>
                  <tr>
                    <th width={"10px"}>Status</th>
                    <td width={"1px"}>:</td>
                    <td>{bill?.status}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {bill?.riwayat.length > 0 && <ul class="timeline">
            {bill.riwayat.map((d,i)=>(
              <li class="event" data-date={d?.manifest_date + " " + d?.manifest_time} key={i}>
                  <p>{d?.manifest_description}: {d.city_name}</p>
              </li>
            ))}
          </ul>}
        </div>
        {/* <div className="offcanvas-footer">
          <h6 className="text-center mb-2">Butuh Bantuan ?</h6>
          <div className="btn-box">
            <div className="btn btn-primary py-2 bg-white text-primary font-sm w-100">
              WA: 86201209023902
            </div>
          </div>
        </div> */}
      </div>

    </>
  );
}

export default ModalLacakPengiriman;
