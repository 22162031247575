import React, { useEffect } from "react";
import { useState } from "react";
import CountDown from "../../../components/makro/CountDown";
import Slide from "../../../components/makro/Slide";
import { getList } from "../../../utils/api";
import { useParams } from "react-router-dom";

const SectionFlashSale = () => {
  const [data, set_data] = useState([])
  const {kodeToko} = useParams();

  useEffect(()=>{
    getList(`${kodeToko}/list-flashsale`)
    .then((res) => {
      if(res?.code === 200)
        set_data(res?.data);
    });
  },[]);
  const today = new Date();
  
  const jadwal1 = new Date(today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate()+" 12:00:00");
  const jadwal2 = new Date(today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate()+" 18:00:00");
  const jadwal3 = new Date(today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate()+" 23:59:59");
  
  const seconds1 = Math.abs(jadwal1.getTime() - today.getTime())/1000;
  const seconds2 = Math.abs(jadwal2.getTime() - today.getTime())/1000;
  const seconds3 = Math.abs(jadwal3.getTime() - today.getTime())/1000;

  const seconds = Math.min(...[seconds1,seconds2,seconds3]);
  return (
    <div>
      {data && data.length > 0 && <section className="category low-price-section bg-white mb-2  inside-wrap">
        <div className="top-content w-100">
          <div className="d-flex justify-content-between w-100">
            <h4 className="title-color mt-0 me-2">Flash Sale</h4>
            <CountDown seconds={seconds} isWithoutTitle={true}/>
          </div>
        </div>

        <div className="product-slider">
          <Slide data={data} isFlashSale={true}/>
        </div>
      </section>}
    </div>
  );
};

export default SectionFlashSale;
