import React, { Component } from "react";
import Header1 from "../../../parts/app/Header1";
import api from "../../../utils/api";
import { decodeParams, encodeDataToURL } from "../../../utils/LinkUrl";
import ModalOptionsList from "../../../components/makro/ModalOptionsList";
import Loading from "../../../components/mikro/Loading";
import ModalAlamatPengiriman from "../../../parts/app/ModalAlamatPengiriman";
import NumberFormat from "react-number-format";

import {
  ArrowRight,
  ColorTruck,
  TriangleWarning,
} from "../../../components/mikro/Icon";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      panelShow: {
        lokasi: false,
        alamat: false,
      },
      search: "",
      params: decodeParams(),
      redirect: false,

      deskripsi: {
        lokasi: {
          label: "",
          lokasi_id: "",
        },
        paket: {
          berat: "",
          lebar: "",
          panjang: "",
          tinggi: "",

          berat_label: "",
          ukuran_label: "",
        },

        produk_label: "",
      },

      lokasi_pengiriman: {
        label: "",
        tujuan_id: "",
      },

      dataJasaPengiriman: [],
    };
  }
  componentDidMount() {
    this.getProduk();
  }
  getProduk() {
    api
      .get("berberkah/cek-ongkos-kirim/produk", {
        params: {
          produkId: this.state?.params?.idProduk || 0,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          this.setState({
            deskripsi: res.data.data,
            isLoading: false,
          });
        }
      });
  }

  getCost() {
    api
      .get("berberkah/cek-ongkos-kirim", {
        params: {
          produkId: this.state?.params?.idProduk || 0,
          tujuanId: this.state?.lokasi_pengiriman.tujuan_id || 0,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          this.setState({
            dataJasaPengiriman: res?.data?.data || [],
            isLoading: false,
          });
        }
      });
  }

  handleChangeLokasi(v) {
    this.setState(
      {
        lokasi_pengiriman: {
          label: v.label || "",
          tujuan_id: v.subdistrict_id || 0,
        },
        isLoading: true,
      },
      () =>
        this.state.lokasi_pengiriman.tujuan_id !== "" &&
        this.state.lokasi_pengiriman.tujuan_id !== null &&
        this.getCost()
    );
  }
  handleChangeAlamat(v) {
    this.setState(
      {
        lokasi_pengiriman: {
          label: v.label_wilayah || "",
          tujuan_id: v.kecamatan_id || 0,
        },
        isLoading: true,
      },
      () =>
        this.state.lokasi_pengiriman.tujuan_id !== "" &&
        this.state.lokasi_pengiriman.tujuan_id !== null &&
        this.getCost()
    );
  }
  render() {
    const {
      isLoading,
      panelShow,
      lokasi_pengiriman,
      deskripsi,
      dataJasaPengiriman,
    } = this.state;
    return (
      <div
        style={{
          paddingTop: "56px",
          height: "100%",
        }}
      >
        <Header1 title="Cek Ongkir" linkNotification={false} linkCart={false} />
        <main className="p-0 main-wrap-height search-page account-page bg-white h-100">
          <section className=" check-delivery-section low-price-section mb-2  pt-2 bg-white">
            <div className="service-section">
              <ul className="list-group list-group-flush">
                <li className="list-group-item  px-4 py-3">
                  <div
                    class="alert alert-primary d-flex align-items-center border-0"
                    role="alert"
                  >
                    <TriangleWarning />
                    <div>
                      Ongkir diperkirakan lebih tinggi karena ada perhitungan
                      ukuran, volume, atau berat produk.
                    </div>
                  </div>
                  {deskripsi?.lokasi?.label && (
                    <div className="mb-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-box"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5 8 5.961 14.154 3.5 8.186 1.113zM15 4.239l-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z" />
                      </svg>
                      &nbsp; Dikirim dari&nbsp;
                      <span className="fw-bold">
                        {deskripsi?.lokasi?.label || ""}
                      </span>
                    </div>
                  )}
                  {deskripsi?.paket?.berat_label && (
                    <div className="font-sm text-dark mb-1">
                      Berat satuan &nbsp;
                      <span className="fw-bold text-success">
                        {deskripsi?.paket?.berat_label || ""}
                      </span>
                    </div>
                  )}
                  {deskripsi?.paket?.ukuran_label && (
                    <div className="font-sm text-dark mb-1">
                      Volume:&nbsp;
                      <span className="fw-bold">
                        {deskripsi?.paket?.ukuran_label || ""}
                      </span>
                    </div>
                  )}
                  <div className="font-xs text-muted">
                    Total ongkir akhir akan dihitung saat Checkout.
                  </div>
                </li>
                <li className="list-group-item px-4 py-3">
                  <div className="w-100  d-flex justify-content-between mb-3">
                    <div>
                      <h4 className="title-color font-md m-0">
                        <i class="iconly-Location icli font-lg"></i> Lokasi
                        Pengiriman
                      </h4>
                      <span className="font-xs"></span>
                    </div>
                    <div
                      className="pointer font-theme"
                      onClick={() =>
                        this.setState({
                          panelShow: { ...panelShow, alamat: true },
                        })
                      }
                    >
                      <span className="font-sm font-theme">Pilih Alamat</span>
                      &nbsp;
                      <ArrowRight width="24" height="24" />
                    </div>
                  </div>
                  <div className="custom-form">
                    <div className="text-muted font-sm mb-1">
                      Provinsi, Kota/Kabupaten, Kecamatan
                    </div>
                    <div className="input-box mb-2 pointer">
                      <i class="iconly-Search icli search"></i>
                      <textarea
                        name="area_domisili"
                        placeholder="Pilih Wilayah Tujuan"
                        readOnly={true}
                        value={lokasi_pengiriman.label || ""}
                        className="form-control bg-white pointer"
                        onClick={() =>
                          this.setState({
                            panelShow: { ...panelShow, lokasi: true },
                          })
                        }
                      ></textarea>
                    </div>
                  </div>
                </li>
                {dataJasaPengiriman && dataJasaPengiriman.length > 0 && (
                  <li className="list-group-item px-4 py-3">
                    <div className="w-100  d-flex justify-content-between mb-3">
                      <div>
                        <h4 className="title-color font-md m-0">
                          <ColorTruck />
                          &nbsp;&nbsp;Jasa Pengiriman
                        </h4>
                        <span className="font-xs"></span>
                      </div>
                    </div>
                    <div className="w-100">
                      <ListJasaPengiriman data={dataJasaPengiriman} />
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </section>
        </main>

        <ModalOptionsList
          onRef={(ref) => (this.refModal = ref)}
          url={"berberkah/cek-ongkos-kirim/wilayah"}
          panelShow={panelShow.lokasi}
          isSingleValue={true}
          title={"Lokasi Detail"}
          height={"80%"}
          onChangeValue={(e) => this.handleChangeLokasi(e)}
          onClose={() =>
            this.setState({ panelShow: { ...panelShow, lokasi: false } })
          }
        />

        <ModalAlamatPengiriman
          onRef={(ref) => (this.refModal2 = ref)}
          panelShow={panelShow.alamat}
          title={"Alamat Pengiriman"}
          height={"100%"}
          onChangeValue={(e) => this.handleChangeAlamat(e)}
          onClose={() =>
            this.setState({ panelShow: { ...panelShow, alamat: false } })
          }
        />

        {isLoading ? <Loading /> : ""}
      </div>
    );
  }
}

function ListJasaPengiriman({ data }) {
  return (
    <>
      {data.map((d, i) => (
        <div key={i}>
          <div className="fw-bold font-sm mb-1">{d.name}</div>
          <ol class="list-group list-group-flush mb-3">
            {d.costs.map((service, ci) => (
              <li
                class="list-group-item d-flex justify-content-between align-items-start px-2 border-bottom mb-2"
                key={ci}
              >
                <div>
                  <div className="fw-bold font-sm">
                    {(d.code || "").toUpperCase()} {service?.service || ""}
                  </div>
                  <div className="fw-500">
                    Estimasi tiba&nbsp;
                    {service?.cost[0]?.etd != "" ? (
                      <span className="fw-bold">
                        {service?.cost[0]?.etd || "-"} hari
                      </span>
                    ) : (
                      "tidak tersedia"
                    )}
                  </div>
                  <div>
                    {service?.description && service?.description !== "" ? (
                      <small className="text-muted">
                        # {service?.description}
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {service?.cost[0]?.value > 0 ? (
                  <div className="text-danger fw-bold">
                    <small>Rp</small>
                    <NumberFormat
                      value={service?.cost[0]?.value || 0}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                    />
                  </div>
                ) : (
                  ""
                )}
              </li>
            ))}
          </ol>
        </div>
      ))}
    </>
  );
}

export default index;
