import React from "react";
import { Link, useParams } from "react-router-dom";
import {useSelector} from 'react-redux'
const BottomBar = () => {
  const {kodeToko} = useParams()
  const toko = useSelector(state => state.toko);
  return (
    <footer className="footer-wrap">
      <ul className="footer">
        <li className="footer-item">
          <Link to={"/"+kodeToko} className="footer-link">
            <i className="iconly-Home icli"></i>
            <span>Beranda</span>
          </Link>
        </li>
        <li className="footer-item">
          <Link to={"/"+kodeToko+"/kategori"} className="footer-link">
            <i className="iconly-Category icli"></i>
            <span>Kategori</span>
          </Link>
        </li>
        
        <li className="footer-item">
          <Link to={"/"+kodeToko+"/keranjang"} className="footer-link">
            <i className="iconly-Bag-2 icli"></i>
            <span className="offer">Keranjang</span>
          </Link>
        </li>
        <li className="footer-item">
          <a href={"https://wa.me/"+toko.wa+"?text=Assalamualaikum%20kak"} target="_blank" className="footer-link">
            <i className="iconly-Search icli"></i>
            <span>Tanya</span>
          </a>
        </li>
        <li className="footer-item">
          <Link to={"/"+kodeToko+"/akun"} className="footer-link">
            <i className="iconly-Profile icli"></i>
            <span>Akun</span>
          </Link>
        </li>
      </ul>
    </footer>
  );
};

export default BottomBar;
