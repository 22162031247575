import React from "react";
import Image from "react-bootstrap/esm/Image";
import Button from "react-bootstrap/esm/Button";
import { useNavigate, useParams } from "react-router-dom";

function PembayaranSelesai(){
  const navigate = useNavigate();
  const {kodeToko} = useParams()
  return (
    <div
      style={{
        minHeight:"100vh"
      }}
    >
      <main
        class="main-wrap order-success-page mb-xxl"
        style={{ backgroundColor: "#f8f8f8", paddingBottom: "65px", minHeight:'100vH' }}
      >
        <section className="banner-section">
          <div className="banner-wrap">
            <Image fluid={true} src="https://themes.pixelstrap.com/fastkart-app/assets/svg/order-success.svg"/>
          </div>

          <div className="content-wrap">
            <h1 class="font-lg title-color">Menunggu Pembayaran</h1>
            <p class="font-sm content-color">Selesaikan pembayaran dan dapatkan 1 Poin!</p>
            <div className="my-4">
              <Button 
                variant="primary" 
                className="font-sm py-2 px-5"
                onClick={() =>
                  navigate('/'+kodeToko+"/transaksi-saya")
                }
              >Lihat Daftar Pesanan</Button>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
export default PembayaranSelesai;
