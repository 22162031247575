import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import CardProduct from "./CardProduct";
import { useParams } from "react-router-dom";

export default function Slide({ data, ...props }) {
  const {kodeToko} = useParams()
  return (
    data &&
    data.length > 0 && (
      <div style={{ padding: "20px 0" }}>
        <Swiper
          modules={[FreeMode]}
          className="mySwiper"
          grabCursor={true}
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            250: {
              slidesPerView: 2.1,
              spaceBetween: 10,
            },

            650: {
              slidesPerView: 2.8,
              spaceBetween: 10,
            },
            800: {
              slidesPerView: 3.2,
              spaceBetween: 10,
            },
          }}
        >
          {data &&
            data.map((d, i) => {
              return (
                <SwiperSlide key={i}>
                  <CardProduct data={{...d, linkTo:`/${kodeToko}/p/${d.id}`}}/>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    )
  );
}
