import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate, Link, useParams } from "react-router-dom";
import { Cart, Notification } from "../../components/mikro/Icon";
import { ArrowLeft } from "../../components/mikro/Icon";
import SearchBox from "../../components/mikro/SearchBox";
const Header1 = (props) => {
  const navigate = useNavigate();
  const {kodeToko} = useParams()
  return (
    <div className="header-wrap" style={{ zIndex: 4 }}>
      <header
        class="header bg-white inside-wrap border-bottom d-flex flex-column justify-content-between p-0"
        style={{
          minHeight: "56px",
        }}
      >
        <div className="d-flex w-100">
          <div className="logo-wrap  text-dark flex-fill ">
            {props.linkLeft || (
              <div onClick={() => navigate(props.linkBackTo || '/'+kodeToko, { replace: true })}>
                <div className="avatar-wrap pointer">
                  <span class="font-xl p-1">
                    <ArrowLeft width="24px" height="24px" />
                  </span>
                </div>
              </div>
            )}
            <h4 className="title-color m-0 font-md fw-bold flex-fill w-100 text-start py-3">
              {props.title || "Header "}
            </h4>
          </div>
          <div className="avatar-wrap">
            {props.linkCart && 
              <Link to={"/"+kodeToko+"/keranjang"} className="text-dark px-3">
                <i className="font-sm">
                  <Cart width={"24px"} height={"24px"} />
                </i>
              </Link>}
          </div>
        </div>
        {props.isSearch ? (
          <div className="w-100 pb-2 px-3 ">
            <SearchBox
              placeholder={
                props.searchBoxPlaceholder || " Ketik yang anda cari disini "
              }
              readOnly={false}
              onChange={(v) => props.onChange(v)}
                onEnter={(e)=>{}}

            />
          </div>
        ) : (
          ""
        )}
      </header>
    </div>
  );
};

Header1.propTypes = {
  title: PropTypes.string,
};

export default Header1;
