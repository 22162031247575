import React from 'react';
import NumberFormat from 'react-number-format';
const SectionHarga = ({data}) => {
    return (
        <section className="low-price-section unmain-wrap mb-2 inside-wrap  px-2 py-3 bg-white">
          <div className="d-flex flex-row w-100 justify-content-between">
              <div className="mb-1 d-flex flex-column gap-2 flex-fill">
                <span className="text-dark">Harga</span>
                {data?.harga_diskon && <div class="price m-0 font-xs text-secondary">
                  <span>Rp</span>&nbsp;
                  <del>
                    <NumberFormat
                      value={data?.harga || 0}
                      displayType={"text"}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                    />
                  </del>
                </div>}
                <span className="title-color text-danger">
                  <span className='font-xs fw-bold'>Rp</span>&nbsp;
                  <NumberFormat
                    value={data?.harga_diskon || data?.harga || 0}
                    displayType={"text"}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    className="font-md  fw-bold"
                  />
                </span>
              </div>
              <div className="title-color mb-1 d-flex flex-column gap-2 flex-fill">
                <span className="font-sm text-dark">Stok Tersedia</span>
                <span className="font-md  fw-bold">
                  <NumberFormat
                    value={data?.stok || 0}
                    displayType={"text"}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                  />
                </span>
              </div>
            </div>
        </section>
    );
};

export default SectionHarga;