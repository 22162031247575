import React from "react";
import { Link } from "react-router-dom";

const index = () => {
  return (
    <div>
      <iframe src="https://home.berberkah.com" 
      style={{position:'fixed',
        top:0, 
        left:0, 
        bottom:0, 
        right:0, 
        width:'100%', 
        height:'100%', 
        border:'none',
        margin:0, 
        padding:0, 
        overflow:'hidden',
        zIndex:'999999',
      }}></iframe>
    </div>
  );
};

export default index;
