import React, { Fragment } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Dot } from "../mikro/Icon";
import Image from 'react-bootstrap/Image'
import NumberFormat from "react-number-format";
import {Belum_Bayar, Kadaluarsa, Selesai, Menunggu_Konfirmasi, Diproses, Dikirim, Diterima,COD} from "../../enums/status_transaksi";
import {
  BelumBayarMessage,
  KadaluarsaMessage,
  BelumBayarFooter,
  SudahBayarFooter,
  DikirimFooter,
  DiterimaFooter,
  SelesaiFooter,
} from './../mikro/ButtonTransaction'

const CardTransaction = ({data, app}) => {
  const navigate = useNavigate();
  const {kodeToko} = useParams()
  const handleClickBayar = () =>{
    navigate("/"+kodeToko+"/transaksi/pilih-pembayaran/" + data.id);
  }

  const handleClickList = () =>{
    navigate("/"+kodeToko+"/transaksi-saya/" + data.id);
  }

  return (
      <div className="review-wrap bg-white default-card-shadow mb-3 pointer">
        <ul class="navigation">
          {/* PESANAN CODE */}
          <li className="d-flex align-items-center inside-wrap  py-3"  onClick={()=>handleClickList()}>
            <div className="title-color font-sm flex-grow-1 flex-column gap-0 justify-content-start">
              <div className="text-start fw-bold">Pesanan #{data.kode}</div>
              <div className="text-start font-xs m-0 text-muted">{data.waktu_pemesanan_label} WIB</div>
            </div>
            <div className="arrow">
              <span className="text-danger font-xs fw-bold align-items-center d-flex">
                <Dot width={"24"} height={"24"}/> <span>{data.status_label}</span>
              </span>
            </div>
          </li>
          {/* TIMER */}
          {(data.status === Belum_Bayar || data.status === Kadaluarsa) && <li className="d-flex align-items-center inside-wrap py-2 bg-page gap-3" onClick={()=>handleClickList()}>
          {data.status === Belum_Bayar && <BelumBayarMessage data={data}/>}
          {data.status === Kadaluarsa && <KadaluarsaMessage data={data}/>}
          </li>}
          
          <li className="d-flex align-items-center inside-wrap py-2 bg-white gap-3"  onClick={()=>handleClickList()}>
            <div className="text-center align-self-center"> 
              <Image 
                rounded={true}
                src={data.gambar}
                className="img-fluid"
                width={'75px'}
                height={'75px'}
              />
            </div>
            <div className="title-color font-sm flex-grow-1 flex-column justify-content-start">
              <div className="w-100 text-start m-0 text-muted font-xs">Jumlah Pembelian:</div>
              <div className="w-100 text-start mb-1 font-xs fw-600">
                <NumberFormat
                    value={data.jumlah || 0}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    suffix=" Barang"
                  /></div>
              <div className="w-100 text-start m-0 text-muted font-xs">Total Pembayaran:</div>
              <div className="w-100 text-start m-0 fw-bold text-danger">
                <NumberFormat
                    value={data.total_pembayaran || 0}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix="Rp"
                  />
              </div>
            </div>
          </li>
          <li className="d-flex align-items-center inside-wrap  py-3 border-top">
            {data.status === Belum_Bayar && <BelumBayarFooter onClickBayar={() => handleClickBayar()}  onClearTime={()=>this.props.onClearTime()}/>}
            {(data.status === COD)  && <SudahBayarFooter status={data.status} />}
            {(data.status === Menunggu_Konfirmasi || data.status === Diproses) &&<SudahBayarFooter status={data.status} />}
            {data.status === Dikirim && <DikirimFooter status={data.status} onClick={()=>handleClickList()}/>}
            {data.status === Diterima && <DiterimaFooter status={data.status} onClick={()=>handleClickList()}/>}
            {data.status === Selesai && <SelesaiFooter/>}
          </li>
        </ul>
      </div>
  );
};
export default CardTransaction;


