import React, { Component } from "react";
import api, { getList } from "../../utils/api";
import { Cross, Star } from "../../components/mikro/Icon";
import CardListComments from "../../components/makro/CardListComments";
import { withRouter } from "../../utils/WithRouter";
import FilterButtonAndBadge from "../../components/mikro/FilterButtonAndBadge";
class ModalUlasanPembeli extends Component {
  constructor(props) {
    super(props);
    this.state = {

      items: [],
      current_page: 0,
      last_page: 0,
      per_page: 15,
      total: 0,
      
      rating: null,
      isLoading: false,
    };
  }
  componentDidMount(){

    this.refs.iScrollUlasan.addEventListener("scroll", () => {
      if(this.refs.iScrollUlasan.scrollTop % this.state.per_page === 0)
        this.setState({
          scrollNumber:this.refs.iScrollUlasan.scrollTop
        });
      if(this.refs.iScrollUlasan.scrollTop + this.refs.iScrollUlasan.clientHeight >= this.refs.iScrollUlasan.scrollHeight - 60)
      {
        this.getUlasan();
      }
    });
  }

  componentDidUpdate(prevProps){
    if(prevProps.panelShow !== this.props.panelShow && this.props.panelShow === true)
      this.getUlasan()
  }

  getUlasan() {
    const { items, current_page, rating, per_page, isLoading} = this.state;

    if(items.length % per_page === 0 && isLoading === false)
      this.setState({isLoading:true},()=>{
        const payload = {
          page: current_page + 1,
          rating: rating,
          produk_id: this.props.id,
        }
        getList(this.props.params.kodeToko+"/list-ulasan-pembeli", payload).then((res) => {
          if (res.code === 200) {
            let { items } = this.state;
            this.setState({
              items:
                current_page > 0
                  ? items.concat(res?.data)
                  : res?.data,
              current_page: res?.meta?.current_page || 0,
              last_page: res?.meta?.last_page || 0,
              per_page: res?.meta?.per_page || 0,
              total: res?.meta?.total || 0,
              isLoading: false
            });
          }
        });
      });
  }

  handleClose(v, i) {
    this.setState({ hasMore: false }, () => this.props.onClose());
  }
  handleFilter(rating) {
    this.setState({ rating: rating.length > 0 ? rating : null, current_page: 0 }, () => this.getUlasan());
  }

  render() {
    const { items } = this.state;
    return (
      <>
        <div
          className={
            "shop-fillter offer-filter offcanvas offcanvas-bottom bg-white  search-page" +
            (this.props.panelShow ? " show" : "")
          }
          style={{
            boxShadow: "rgb(0 0 0 / 10%) 0px 0px 3px 1px",
            height: this.props.height || "auto",
          }}
        >
          <div className="offcanvas-header d-flex flex-column border-bottom p-0">
            <div className="w-100 d-flex">
              <div
                class="p-3 pointer"
                onClick={() => {
                  this.handleClose();
                }}
              >
                <Cross width="20px" height="20px" />
              </div>
              <h4 className="title-color m-0 font-md fw-bold flex-fill w-100 text-start py-3">
                Ulasan Pembeli
              </h4>
            </div>
            <div className="w-100 inside-wrap pb-1">
              <FilterButtonAndBadge data={[
                {label: starList('5'), value:5},
                {label: starList('4'), value:4},
                {label: starList('3'), value:3},
                {label: starList('2'), value:2},
                {label: starList('1'), value:1},
              ]} onChangeValue={(e) => this.handleFilter(e)}/>
            </div>
          </div>
          <div
            className="main-wrap offcanvas-body small bg-white px-0"
            ref="iScrollUlasan"
            style={{
              height: "100%",
            }}
          >
            {items.length > 0 ? <CardListComments data={items} /> : <h6 className="py-5 text-center">Tidak ada ulasan</h6>}
          </div>
          
        </div>

      </>
    );
  }
}

function starList(label) {
  return <div className="d-flex align-items-center">
    <Star width="11" height="11"/>&nbsp;<span>{label}</span>
  </div>
}

export default withRouter(ModalUlasanPembeli);