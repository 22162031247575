import React from 'react';
import PropTypes from 'prop-types';

const EmptyListPage = props => {
    return (
            <h6 className='text-center'>Data tidak tersedia</h6>
    );
};

EmptyListPage.propTypes = {
    
};

export default EmptyListPage;