import React from "react";
import PropTypes from "prop-types";
import { formatNumber } from "../../utils/NumberFormat";

class NumericFormatInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isEditing: false };
  }

  onChange(event) {
    this.props.onChange(event);
  }

  toCurrency(number) {
    return formatNumber(number);
  }

  toggleEditing() {
    this.setState({ isEditing: !this.state.isEditing });
  }

  render() {
    return this.state.isEditing ? (
      <input
        type="number"
        className={this.props.className + " form-control"}
        name={this.props.name}
        placeholder={this.props.placeholder}
        value={this.props.value}
        onChange={this.onChange.bind(this)}
        onBlur={this.toggleEditing.bind(this)}
      />
    ) : (
      <input
        type="text"
        className={this.props.className + " form-control bg-white"}
        name={this.props.name}
        placeholder={this.props.placeholder}
        value={this.toCurrency(this.props.value)}
        onFocus={this.toggleEditing.bind(this)}
        readOnly
      />
    );
  }
}

NumericFormatInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default NumericFormatInput;
