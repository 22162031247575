import api, { getList } from "../../utils/api";
import { 
  UPDATE_RESPONSE,
  UPDATE_LOADING,
  SET_DATA_TOKO
} from './../../enums/redux_action'


export const action_get_data_toko = (id) => (dispatch) => {
  dispatch({
    type: UPDATE_LOADING,
    value: true,
  });
  getList("toko/"+id).then((res) => {
    if (parseInt(res.code) === 200)
        dispatch({
        type: SET_DATA_TOKO,
        value: res.data
        });
    dispatch({
      type: UPDATE_RESPONSE,
      value: {
        status: res.status,
        message: res.message,
        code: res.code,
      }
    });

    dispatch({
      type: UPDATE_LOADING,
      value: false,
    });
  }).catch((err)=>{
    dispatch({
      type: UPDATE_LOADING,
      value: false,
    });
  });
};