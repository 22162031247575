import React, { useState,useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import SearchBox from "../../components/mikro/SearchBox";
import SectionFilter from "../../pages/app/Home/SectionFilter";

const HeaderHome = (props) => {
  //change nav color when scrolling
  const [color, setColor] = useState(false);
  const [showFilter, set_showFilter] = useState(false);
  const {kodeToko} = useParams()

  const navigate = useNavigate();

  useEffect(() => {
    changeColor(props.scrollY);
  }, [props.scrollY]);

  const changeColor = (scrollY) => {
    if (scrollY >= 30 && color === false) setColor(true);
    else if (scrollY < 30) setColor(false);

    if (scrollY >= 720 && showFilter === false) set_showFilter(true);
    else if(scrollY < 720) set_showFilter(false);
  };


  const handleSearch = (value) => {
    navigate("/"+kodeToko+"/search?q="+value)
  }
  return (
    <div className="header-wrap">
      <header className={color ? "header bg-white border-bottom" : "header"}>
        <div
          className="logo-wrap d-flex"
          style={{ width: "-webkit-fill-available" }}
        >
          <SearchBox 
            readOnly={false} 
            onChange={(e)=>null} 
            onEnter={value=>handleSearch(value)} 
          />
        </div>
      </header>
      {showFilter && <SectionFilter total={props.total}/>}
    </div>
  );
};

export default HeaderHome;
