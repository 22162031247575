import React, { useEffect, useState } from "react";

const BtnCounter = (props) => {
  const [count, setCount] = useState(props.value || 1);

  useEffect(() => {
    const timer = setTimeout(() => {
      props.onChange(count);
    }, 1000);

    return () => clearTimeout(timer);
  }, [count]);

  useEffect(() => {
    setCount(props.value || 1);
  }, [props.value]);

  return (
    <div className="plus-minus p-0">
      <div
        className="py-1 px-2"
        onClick={() => count > 1 && setCount(count - 1)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-minus sub"
        >
          <line x1="5" y1="12" x2="19" y2="12"></line>
        </svg>
      </div>
      <div className="p-1 prevent-select">
        <input
          type="number"
          value={parseInt(count)}
          className="text-center"
          style={{ width: "55px" }}
          onChange={(e) =>
            e.target.value === ""
              ? setCount(0)
              : parseInt(e.target.value) <= (props.max || 9999) &&
                setCount(parseInt(e.target.value))
          }
        />
      </div>
      <div
        className="py-1 px-2"
        onClick={() => count < props.max && setCount(count + 1)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-plus add"
        >
          <line x1="12" y1="5" x2="12" y2="19"></line>
          <line x1="5" y1="12" x2="19" y2="12"></line>
        </svg>
      </div>
    </div>
  );
};

export default BtnCounter;
