import React, { Component } from "react";
import ShopFilter from "../../components/makro/ShopFilter";
import ShopFilterSection from "../../components/makro/ShopFilterSection";
import { getList } from "../../utils/api";
import { decodeParams } from "../../utils/LinkUrl";
import { withRouter } from "../../utils/WithRouter";
import NumericFormatInput from "../../components/mikro/NumericFormatInput";

class FilterPencarian extends Component {
    state = {
      panelShow: false,
      rekomendasi: [],
      params: decodeParams(),
      filter: {
        merek: [],
        harga_awal: "",
        harga_akhir: "",
        urutan: 'Recommended',
      },
    };
  componentDidMount() {
    this.getApiFilter();
  }

  getApiFilter() {
    getList(this.props.params.kodeToko+"/filter-pencarian/rekomendasi").then((res) => {
      if (res?.code === 200) {
        this.setState({
          rekomendasi: res?.data,
        });
      }
    });
  }
  handleReset() {
    this.refUrutan.handleReset();
    this.refHarga.handleReset();
    this.refMerek.handleReset();

    this.setState({
      filter: {
        merek: [],
        harga_awal: "",
        harga_akhir: "",
        urutan: 'Recommended',
      },
    });
  }

  handleFilter() {
      this.props.onChange(this.state.filter)
  }

  render() {
    const { params, filter, rekomendasi, panelShow } = this.state;
    return (
      <>
        <ShopFilter
          panelShow={this.props.isPanelShow}
          height={"100%"}
          onClose={() => this.props.onClose()}
          onReset={() => this.handleReset()}
          onFilter={() => this.handleFilter()}
        >
          <div className="mt-0">
            <ShopFilterSection
              onRef={(ref) => (this.refUrutan = ref)}
              title="Urutan"
              multiple={false}
              seeAll={false}
              data={[
                { active: false, title: "Produk Terbaru" },
                { active: false, title: "Produk Lama" },
                { active: false, title: "A to Z" },
                { active: false, title: "Z to A" },
              ]}
              onChangeValue={(v) =>
                this.setState({
                  filter: {
                    ...filter,
                    urutan: v.active === true ? v.title : "Produk Terbaru",
                  },
                })
              }
            />
            <ShopFilterSection
              onRef={(ref) => (this.refHarga = ref)}
              title="Harga"
              multiple={false}
              seeAll={false}
              data={[
                { active: false, title: "0K - 10K", start: 0, end: 10000 },
                {
                  active: false,
                  title: "10K - 50K",
                  start: 10000,
                  end: 50000,
                },
                {
                  active: false,
                  title: "50K - 100K",
                  start: 50000,
                  end: 100000,
                },
              ]}
              onChangeValue={(v) =>
                this.setState({
                  filter: {
                    ...filter,
                    harga_awal: v.active === true ? v.start : "",
                    harga_akhir: v.active === true ? v.end : "",
                  },
                })
              }
            >
              <div className="row mb-1">
                <div className="col-6">
                  <label className="mb-1">Harga Minimal</label>
                  <div className="input-group">
                    <span className="input-group-text font-xs">Rp</span>
                    <NumericFormatInput
                      className="form-control text-right input-sm"
                      value={filter.harga_awal}
                      name="paket_berat"
                      onChange={(e) =>{
                          this.setState({
                            filter: {
                              ...filter,
                              harga_awal: e.target.value,
                            },
                          })
                        }
                      }
                      placeholder="Harga Minimal"
                    />
                    
                  </div>
                </div>
                <div className="col-6">
                  <label className="mb-1">Harga Maksimal</label>
                  <div className="input-group">
                    <span className="input-group-text font-xs">Rp</span>
                    <NumericFormatInput
                      className="form-control text-right input-sm"
                      value={filter.harga_akhir}
                      name="paket_berat"
                      onChange={(e) =>
                        this.setState({
                          filter: {
                            ...filter,
                            harga_akhir: e.target.value,
                          },
                        })
                      }
                      placeholder="Harga Maksimal"
                    />
                  </div>
                </div>
              </div>
            </ShopFilterSection>

            <ShopFilterSection
              onRef={(ref) => (this.refMerek = ref)}
              url={this.props.params.kodeToko+"/list-merek"}
              title="Merek"
              multiple={true}
              seeAll={true}
              data={rekomendasi?.merek || []}
              onChangeValue={(v) =>
                this.setState({ filter: { ...filter, merek: v } })
              }
            />
          </div>
        </ShopFilter>
      </>
    );
  }
}

export default withRouter(FilterPencarian);
