import React, { Component } from "react";
import Overlay from "../../components/mikro/Overlay";
import SearchBox from "../../components/mikro/SearchBox";
import { getList } from "../../utils/api";
import { Cross } from "../../components/mikro/Icon";
import CardListChecklist from "../../components/mikro/CardListChecklist";
import ModalTambahAlamatPengiriman from "./ModalTambahAlamatPengiriman";
import { withRouter } from "../../utils/WithRouter";

class ModalAlamatPengiriman extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countActive: 0,
      selected: null,

      items: [],
      current_page: 0,
      last_page: 0,
      per_page: 15,
      total: 0,
      search: "",

      isLoading:false

    };
  }
  componentDidMount(){
    this.refs.iScrollModalAlamat.addEventListener("scroll", () => {
      if(this.refs.iScrollModalAlamat.scrollTop + this.refs.iScrollModalAlamat.clientHeight >= this.refs.iScrollModalAlamat.scrollHeight - 10){
        this.getAlamat();
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.panelShow !== this.props.panelShow && this.props.panelShow === true) {
      this.setState({ isLoading: false, current_page: 0 },()=>this.getAlamat());
    }
  }

  getAlamat() {
    const { items, current_page, search,isLoading,per_page } = this.state;
    if(items.length % per_page === 0 && isLoading === false)
      this.setState({ isLoading:true },
        ()=>{
          getList(this.props.params.kodeToko+"/alamat", {
            page: current_page + 1,
            search: search,
          })
          .then((res) => {
            if (res.code === 200) {
              this.setState({
                items:
                  current_page > 0
                    ? items.concat(res?.data)
                    : res?.data,
                current_page: res?.meta?.current_page || 0,
                last_page: res?.meta?.last_page || 0,
                per_page: res?.meta?.per_page || 0,
                total: res?.meta?.total || 0,
                isLoading:false
              });
            }
          });
        });
  }

  handleClick(v, i) {
    let { items } = this.state;
    let countActive = 0;
    let selected = null;
    for (let x = 0; x < items.length; x++) {
      if (x === i) items[i].checked = !items[i].checked;
      else items[x].checked = false;

      if (items[x].checked === true) {
        countActive++;
        selected = items[x];
      }
    }

    this.setState({
      items: items,
      countActive: countActive,
      selected: selected,
    });
  }

  handleFilter() {
    if (this.state.selected) {
      this.props.onChangeValue(this.state.selected || null);
      this.handleClose();
    } else {
      alert("Pilih 1 Alamat untuk diterapkan");
    }
  }

  handleClose() {
    this.setState({ hasMore: false }, () => this.props.onClose());
  }

  handleSearch(search) {
    this.scroll.pageLoaded = 0;
    this.setState({ search: search, hasMore: true, current_page: 0, items:[] }, () =>
      this.getAlamat()
    );
  }

  render() {
    const { countActive,  items } = this.state;
    return (
      <>
        <div
          className={
            "shop-fillter offer-filter offcanvas offcanvas-bottom bg-white" +
            (this.props.panelShow ? " show" : "")
          }
          style={{
            boxShadow: "rgb(0 0 0 / 10%) 0px 0px 3px 1px",
            height: this.props.height || "auto",
          }}
        >
          <div className="offcanvas-header d-flex flex-column border-bottom p-0">
            <div className="w-100 d-flex">
              <div
                class="p-3 pointer"
                onClick={() => {
                  this.handleClose();
                }}
              >
                <Cross width="20px" height="20px" />
              </div>
              <h4 className="title-color m-0 font-md fw-bold flex-fill w-100 text-start py-3">
                Pilih Alamat Pembeli
              </h4>
            </div>
            <div className="w-100 pb-2 px-3 ">
              <SearchBox
                placeholder={"Cari Alamat"}
                readOnly={false}
                onChange={(v) => this.handleSearch(v)}
                onEnter={(e)=>{}}
              />
            </div>
          </div>
          <div
            className="main-wrap offcanvas-body small  bg-white px-0"
            style={{
              height: "100%",
            }}
            ref="iScrollModalAlamat"
          >
            <ul className="list-group list-group-flush prevent-select order-history pt-1">
              { items.map((v, i) => (
                <li
                  className="list-group-item d-flex justify-content-between align-items-center py-2  border-0"
                  key={i}
                  onClick={() => this.handleClick(v, i)}
                >
                  <div
                    className={
                      "order-box w-100 pointer " +
                      (v.checked ? "card-list-active" : "border")
                    }
                  >
                    {v.checked && this.props.panelShow && <CardListChecklist />}
                    <div className="media  mb-2">
                      <div className="content-box flex-fill">
                        <div className="font-xs text-dark fw-bold">
                          {v.judul}
                        </div>
                        <div className="font-md text-dark fw-bold">
                          {v.nama_penerima}
                        </div>
                        <div className="font-xs text-dark fw-500">
                          HP: {v.nomor_hp}
                        </div>
                        <div className="font-xs text-dark fw-500">
                          {v.label_alamat}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="offcanvas-footer">
            <div className="btn-box">
              <button
                className="btn btn-primary py-2 bg-white text-primary font-sm  w-50"
                onClick={() =>
                  this.setState({
                    panelShow: true,
                  })
                }
              >Tambah Alamat</button>
              <button
                className={ "btn btn-primary py-2 font-sm  w-50 " + (countActive <= 0 ? "disabled" : "")}
                onClick={() => this.handleFilter()}
                disabled={countActive <= 0}
              >
                Terapkan
              </button>
            </div>
          </div>
        </div>

        {this.props.panelShow && (
          <div>
            <ModalTambahAlamatPengiriman
              panelShow={this.state.panelShow}
              onClose={() =>
                this.setState({ panelShow: false, hasMore:true,  current_page: 0, isLoading:false, items:[] }, () =>
                  this.getAlamat()
                )
              }
            />
            <Overlay />
          </div> )}
      </>
    );
  }
}

export default  withRouter(ModalAlamatPengiriman);
