import React from "react";
import { Navigate, useParams } from "react-router-dom";
import AuthUser from "../../../utils/AuthUser";

const Keluar = () => {
  const { token, logout } = AuthUser();
  if (token !== undefined) logout();


  const{kodeToko} = useParams()

  return <Navigate to={"/"+kodeToko} />;
}

export default Keluar
