import React from "react";

export function getSearchHistory() {
  return JSON.parse(localStorage.getItem("s_h")) || [];
}

export function appendSearchHistory(search) {
  let history = JSON.parse(localStorage.getItem("s_h")) || [];

  let newHistory = [search];
  let i = 0;
  for (let index = 0; index < history.length; index++) {
    if (
      i < 4 &&
      search !== history[index] &&
      search !== "" &&
      search !== null
    ) {
      newHistory.push(history[index]);
      i++;
    }
  }
  localStorage.setItem("s_h", JSON.stringify(newHistory));

  return true;
}
