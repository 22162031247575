import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useParams } from "react-router";
import BottomBar from "../../parts/app/BottomBar";
import { action_get_data_toko } from "../../store/action/toko_action";
import { getList } from "../../utils/api";

const ApplicationPage = ({ isUseBottomBar }) => {

  const { kodeToko } = useParams()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(action_get_data_toko(kodeToko))
  }, [])
  

  return (
    <div>
      <Outlet />
      {isUseBottomBar && <BottomBar />  }
    </div>
  );
}

export default ApplicationPage;