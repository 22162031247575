import React, { Component, useEffect, useState } from "react";
import api, { getList } from "../../../utils/api";
import { Cross } from "../../../components/mikro/Icon";
import NumberFormat from "react-number-format";
import { set_loading } from "../../../store/action";
import { connect } from "react-redux";
import { withRouter } from "../../../utils/WithRouter";

class ModalLayananPengiriman extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      countActive: 0,
      selected: null,
      data:[],
    };
  }

  componentDidUpdate(prevProps){
    if( this.props.pesananId !== null 
        && this.props.pesananId !== "" 
        && prevProps.pesananId !== this.props.pesananId 
        && this.props.panelShow )
      this.getCost()
  }
  getCost() {
    this.props.reduxSetLoading(true)
    getList(this.props.params.kodeToko+"/cek-ongkos-kirim-pesanan", {
      pesananId: this.props.pesananId,
    })
    .then((res) => {
      this.props.reduxSetLoading(false)

      if (res?.code === 200) {
        this.setState({
          data: res?.data || [],
        });
      }
    }).catch(err=>{
      this.props.onClose()   
      this.props.reduxSetLoading(false)
    })
  }
  handleChange(){
    this.props.onChange(this.state.selected)
    this.props.onClose()   
  }

  render() {
    const { data, selected } = this.state;
    return (
      <>
        <div
          className={
            "shop-fillter offer-filter offcanvas offcanvas-bottom" +
            (this.props.panelShow ? " show" : "")
          }
          style={{
            boxShadow: "rgb(0 0 0 / 10%) 0px 0px 3px 1px",
            height: this.props.height || "auto",
          }}
        >
          <div className="offcanvas-header d-flex flex-column border-bottom p-0  bg-white">
            <div className="w-100 d-flex">
              <div
                class="p-3 pointer"
                onClick={() => {
                  this.props.onClose()
                  this.setState({ items:[]})
                }}
              >
                <Cross width="20px" height="20px" />
              </div>
              <h4 className="title-color m-0 font-md fw-bold flex-fill w-100 text-start py-3">
                Pilih Pengiriman
              </h4>
            </div>
          </div>
          <div
            className="main-wrap offcanvas-body small pb-5 px-0 bg-light scrollable-hiddenbar"
            style={{
              height: this.props.height || "auto",
            }}
          >
            <ListJasaPengiriman data={data} selected={selected} onSelected={(v)=>this.setState({
              selected:{...v,pesananId:this.props.pesananId}
            })} />
          </div>
          <div className="offcanvas-footer">
            <div className="btn-box">
              <button
                className={"btn btn-primary py-2 text-white font-sm "+(!selected ?"disabled":"" )}
                onClick={() => this.handleChange() }
              >
                Terapkan
              </button>
            </div>
          </div>
        </div>
      
      </>
    );
  }
}



const ListJasaPengiriman = ({ data, selected, ...props }) => {
  const [arrData,setArrData] = useState([])

  useEffect(()=>{
    data.forEach((d,i) => {
      data[i]['cost_length'] = d.costs.length;
      data[i]['etd_length'] = 0;
      d.costs.forEach((dc,ic) => {
        data[i]['costs'][ic].is_active = false
      });
    });
    setArrData(data)
  },[data])

  const handleClick = (kurirIndex, serviceIndex) => {
    arrData.forEach((kurir,arrKurirIndex) => {
      kurir['costs'].forEach((d,costIndex) => {
        if(serviceIndex === costIndex && kurirIndex === arrKurirIndex)
          arrData[arrKurirIndex]['costs'][costIndex].is_active = true
        else
          arrData[arrKurirIndex]['costs'][costIndex].is_active = false
      }); 
    });
    
    setArrData([...arrData])

    if(arrData[kurirIndex]?.code && arrData[kurirIndex]?.costs[serviceIndex]?.service)
      props.onSelected({
        kode: arrData[kurirIndex]?.code,
        layanan: arrData[kurirIndex]?.costs[serviceIndex]?.service,
      })
  }
  return (
    <>
      {arrData.map((d, i) => (
        d.etd_length < 1 && <div key={i}>
          <div className="fw-bold font-sm inside-wrap py-3 border-top border-bottom bg-light">{d.name}</div>
          <ol className="list-group list-group-flush">
            {d.costs.map((service, ci) => (
              <li
                className={`list-group-item px-4 py-3 border-bottom  ${d.is_active ? "bg-white pointer":"bg-light"}`}
                key={ci}
                onClick={() => d.is_active && handleClick(i,ci)}
              >
                <div className="d-flex  justify-content-between align-items-start">
                  <div className="me-3 align-self-center">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="option"
                      readOnly={true}
                      checked={service.is_active}
                      disabled={!d.is_active}
                    />
                  </div>
                  <div className={`flex-fill ${d.is_active ? 'text-dark':'text-secondary'}`}>
                    <div className={`fw-600 font-sm `}>
                      { d.code !== service?.service ? ((d.code || "").toUpperCase())+" - "+(service?.service || "") : d.code }
                    </div>
                    {service?.cost?.length > 0 && service?.cost?.[0]?.etd !== "" && (
                      <div>
                        Estimasi tiba&nbsp;
                          <span className="fw-bold">
                            {service?.cost?.[0]?.etd?.replace("HARI","") || "-"} hari
                          </span>
                      </div>
                    )}
                    {d?.type && <p className="text-secondary m-0">
                    {service?.description}
                  </p>}
                  </div>
                  {service?.cost?.[0]?.value > 0 &&
                    <div className={`fw-bold align-self-center text-end flex-fill ${d.is_active ? 'text-danger':'text-secondary'} `} style={{minWidth: '60px'}}>
                      <small>Rp</small>
                      <NumberFormat
                        value={service?.cost[0]?.value || 0}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                      />
                    </div>}
                  </div>
                  
              </li>
            ))}
          </ol>
        </div>
      ))}
    </>
  );
}

const reduxState = (state) => ({
  isLoading: state.isLoading,
});

const reduxDispatch = (dispatch) => ({
  reduxSetLoading: (isLoading) => dispatch(set_loading(isLoading)),
});

export default connect(reduxState, reduxDispatch)(withRouter(ModalLayananPengiriman));