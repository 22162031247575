import React, { useState, useEffect, useRef } from "react";

const ListBadgeHorizontal = (props) => {
  const [selected, setSelected] = useState();
  const prevSelected = usePrevious(selected);
  useEffect(() => {
    if (props.default !== selected && prevSelected === selected) {
      setSelected(props.default);
    }
  });

  useEffect(() => {
    props.onChange(selected);
  }, [selected]);

  return (
    <section className="recent-search py-3 px-1">
      <ul className="custom-scroll-hidden px-2">
        {props.data &&
          props.data.map((d, i) =>
            d.nama && (
              <li
                className={
                  "font-sm title-color nowrap " +
                  (d.nama === selected ? "bg-primary text-white" : "bg-white") +
                  " border"
                }
                onClick={() => setSelected(d.nama)}
                key={i}
              >
                {d.nama}
              </li>
            )
          )}
      </ul>
    </section>
  );
};

export default ListBadgeHorizontal;

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
