import React from "react";
import { ArrowRight } from "../../../components/mikro/Icon";
import { Dibatalkan, Dikirim, Diterima } from "../../../enums/status_transaksi"
const SectionPesananStatus = ({ data, ...props }) => {
  return (
    <>
      <section className="product-section p-0 pointer" onClick={()=>props.onClick()}>
        <div className="top-content d-flex justify-content-between inside-wrap py-2" style={{
          background: '#f4fff4',
          color:'#00a441'
        }}>
          <div className="font-sm fw-bold align-self-center">Pesanan {data.status}</div>
          <div className="align-self-center d-flex flex-column gap-2">
            { data.status  == Dibatalkan 
              && data.alasan_pembatalan 
              && data.alasan_pembatalan !== "" 
              && typeof data.alasan_pembatalan !== "undefined" 
              && <span className="font-xs"><b>Catatan:</b> {data.alasan_pembatalan}</span>}
          </div>
          { ( data.status === Dikirim || data.status === Diterima ) && <div className="font-sm fw-bold align-self-center">Lacak <ArrowRight/></div>}
        </div> 
      </section>
    </>
  );
};

export default SectionPesananStatus;
