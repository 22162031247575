import React from "react";
import NumberFormat from "react-number-format";
const SectionKodePemesanan = ({ data, isKeranjang}) => {
  return (
    <section className="product-section p-0 border-top">
      <div className="d-flex w-100 py-3 inside-wrap justify-content-between">
        <div className="font-sm fw-bold">Kode Pemesanan</div>
        {data?.kode && <div className="font-sm fw-bold">#{data.kode}</div>}
      </div>
    </section>
  );
};

export default SectionKodePemesanan;
