import React, { Component } from "react";
import Header1 from "../../../../parts/app/Header1";
import { getList } from "../../../../utils/api";

import FilterButtonAndBadge from "../../../../components/mikro/FilterButtonAndBadge";
import CardTransaction from "../../../../components/makro/CardTransaction";
import { TAB_PROSES_TRANSAKSI } from "../../../../enums/tab_menu";
import { withRouter } from "../../../../utils/WithRouter";
import { Navigate } from "react-router-dom";
import ImageFrame from "../../../../components/mikro/ImageFrame";
import NoOrderImg from "./../../../../assets/images/no-order.png"

import InfiniteScroll from "react-infinite-scroller";
import Spinner from "../../../../components/mikro/Spinner";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {
        status: TAB_PROSES_TRANSAKSI[0].title,
      },
      redirect: false,
      data: {
        items: [],
        kategori: [],
        current_page: 0,
        last_page: 0,
        per_page: 0,
        total: 0,
        hasMore: false,
      },
      history: [],
      pathRedirect:""
    };
  }

  componentDidMount() {
    this.getList()
  }

  getList() {
    getList(this.props.params.kodeToko+"/transaksi", { ...this.state.params, page: this.state.data.current_page + 1 })
    .then((res) => {
      if (res.code === 200) {
        this.setState({data:{
          items: parseInt(res?.meta?.current_page) > 1 ? this.state.data.items.concat(res?.data) : res.data,
          current_page: res?.meta?.current_page || 0,
          last_page: res?.meta?.last_page || 0,
          per_page: res?.meta?.per_page || 0,
          total: res?.meta?.total || 0,
          hasMore: res.data.length > 0,
        }})
      }
      if(res.code === 401)
        this.setState({
          pathRedirect: "/"+this.props.params.kodeToko+"/login",
        });
    });
  }

  handleResetPage(){
    this.setState({
      data:{
        ...this.state.data,
        current_page: 0,
      }
    })
  }
  render() {
    const { data, params } = this.state;
    const {kodeToko} = this.props.params
    if(this.state.pathRedirect !== '') return <Navigate to={this.state.pathRedirect} />
    return (
      <InfiniteScroll
          pageStart={0}
          loadMore={() =>  data.current_page >= 1 && this.getList()}
          hasMore={data.hasMore}
          loader={<Spinner />}
          useWindow={true}
        >
      <div
        style={{
          paddingTop: "56px",
          minHeight:'100vH',
          overflowY: 'scroll',
        }}
        className="scrollable-hiddenbar bg-page-dark position-relative"
      >
        <Header1
          title={"Transaksi Saya"}
          linkBackTo={'/'+kodeToko}
          linkCart={true}
        />
        <main className="main-wrap main-wrap-height main-wrap product-page search-page index-page search-page h-100 pt-2">
          <FilterButtonAndBadge
            isWithoutAllBtn={true}
            data={TAB_PROSES_TRANSAKSI}
            onChangeValue={(value) =>
              this.setState(
                {
                  params: {
                    ...params,
                    status: value,
                  },
                  data:{
                    ...data,
                    current_page: 0,
                  }
                },
                () => this.getList()
              )
            }
          />
          <div className="unmain-wrap py-1">
              {data?.items.length > 0 && data?.items?.map((d, i) => (
                <div  key={i}>
                  <CardTransaction 
                    data={d} 
                    onClearTime={()=>this.handleResetPage()}
                  />
                </div>
              ))}

              {data?.items?.length === 0  && <div className="d-flex align-items-center text-center justify-content-center"  style={{ minHeight:'calc(100vH - 130px)' }}>
                <div>
                  <ImageFrame 
                    className="mb-2" 
                    style={{ width:'250px', }} 
                    src={NoOrderImg}
                  />
                  <h4 className="fw-bold mb-2">Belum Ada Pesanan, nih</h4>
                  <p>Yuk pemesan produk, dan cek riwayatnya di sini.</p>
                </div> 

              </div>}
          </div>
        </main>
      </div>
      </InfiniteScroll>
    );
  }
}


export default withRouter(index);