import React from "react";
import { decrypt } from "./crypt";

export function getAuth() {
  return JSON.parse(localStorage.getItem("u"));
}

export function getToken() {
  return JSON.parse(localStorage.getItem("t"));
}

export function setAuth(data) {
  localStorage.setItem("u", JSON.stringify(data));

  return true;
}

export function setToken(data) {
  localStorage.setItem("t", JSON.stringify(data));

  return true;
}