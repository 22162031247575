import React from "react";
import SectionPesananProdukDetail from "./SectionPesananProdukDetail";
const SectionPesananProduk = ({
  partner,
  produk,
  alamat,
  isKeranjang,
  ...props
}) => {
  return (
    <section className="mt-1 product-section p-0 border-top">
      
      {produk &&
        produk.map((prd, i) => (
          <div
            className={
              "d-flex w-100 py-3 inside-wrap " +
              (i < produk.length - 1 ? "border-bottom" : "")
            }
            key={i}
          >
            <SectionPesananProdukDetail
              data={prd}
              alamat={alamat}
              isKeranjang={isKeranjang}
            />
          </div>
        ))}
    </section>
  );
};

export default SectionPesananProduk;
