import { RemoveHtmlTag } from "./RemoveHtmlTag";
import {rupiah} from './number'
export function urlDetailProduk(nama = null, uuid = null,kodeToko =null) {
  return nama !== null && uuid !== null
    ? "/"+kodeToko+"/p/"+uuid : "";
}

export function decodeParams() {
  var pairs = window.location.search.substring(1).split("&"),
    obj = {},
    pair,
    i;

  for (i in pairs) {
    if (pairs[i] === "") continue;

    pair = pairs[i].split("=");
    obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
  }

  return obj;
}

export function encodeDataToURL(data) {
  return Object.keys(data)
    .map((value) => `${value}=${encodeURIComponent(data[value])}`)
    .join("&");
}


export function waUrl(data) {
  return window.open(
                "https://api.whatsapp.com/send?text="+
                `*${data.nama}*`+
                "%0A"+
                "%0A"+
                (data.deskripsi ? `${(RemoveHtmlTag(data.deskripsi)).substr(1, 103)}...` : '')+
                "%0A"+
                "%0A"+
                `Harga: ${data?.variasi[0]?.harga_customer >= 0 && data?.variasi[0]?.harga_diskon_customer >= 0 ? '~' + rupiah(data?.variasi[0]?.harga_customer) + '~' : ''} ${data?.variasi[0]?.harga_diskon_customer > 0 ? '*' + rupiah(data?.variasi[0]?.harga_diskon_customer) + '*' : ''} ${data?.harga > 0 ? '*' + rupiah(data?.harga) + '*' : ''}`+
                
                "%0A"+
                "%0A"+
                "*Produk 100%25 original*"+
                "%0A"+
                "%0A"+
                
                (data.share_link && "Klik disini untuk beli produk:"+
                "%0A"+
                `${data.share_link}`), "_blank")
}

export function waComplain(message,wa) {
  return window.open("https://api.whatsapp.com/send?phone="+wa+"&text="+message, "_blank")
}


export function searchFilter(type,value){
  return "/search?type=" + type + "&value=" +encodeURIComponent(value) 
}