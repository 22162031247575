import React, { Component } from "react";
import Overlay from "../../components/mikro/Overlay";
import SearchBox from "../mikro/SearchBox";
import api, { getList } from "../../utils/api";
import { Cross } from "../mikro/Icon";
import Spinner from "../mikro/Spinner";
import InfiniteScroll from "react-infinite-scroller";

class ModalOptionsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      current_page: 0,
      last_page: 0,
      per_page: 0,
      total: 0,
      search: "",

      hasMore: this.props.panelShow,
    };
  }

  componentDidMount() {
    this.props.onRef(this);
  }
  componentWillUnmount() {
    this.props.onRef(null);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.panelShow !== this.props.panelShow) {
      this.setState({ hasMore: this.props.panelShow });
    }
  }

  fetchMoreData() {
    const { data, current_page, search } = this.state;
    getList(this.props.url, {
          page: current_page + 1,
          search: search,
      })
      .then((res) => {
        if (res.code === 200) {
          let { items } = this.state;
          this.setState({
            items: items.concat(res?.data),
            current_page: res?.meta?.current_page || 0,
            last_page: res?.meta?.last_page || 0,
            per_page: res?.meta?.per_page || 0,
            total: res?.meta?.total || 0,
            hasMore: res.data.length > 0 ? true : false,
          });
        }
      });
  }

  handleClick(v, i) {
    let { items } = this.state;

    if (this.props.isSingleValue)
      for (let x = 0; x < items.length; x++) {
        if (x === i) items[i].checked = !items[i].checked;
        else items[x].checked = false;
      }
    else items[i].checked = !items[i].checked;

    this.setState({ items: items });
  }

  handleFilter() {
    let selected = this.props.isSingleValue ? "" : [];

    this.state.items.forEach((e) => {
      if (e.checked)
        this.props.isSingleValue
          ? (selected = e)
          : selected.push(e[this.props.valueIndexName || "nama"]);
    });
    if (selected !== "") {
      this.props.onChangeValue(selected);
      this.handleClose();
    }
  }

  handleReset() {
    this.setState({
      items: [],
      current_page: 0,
      last_page: 0,
      per_page: 0,
      total: 0,
    });
  }
  handleClose(v, i) {
    this.setState({ hasMore: false }, () => this.props.onClose());
  }

  handleSearch(search) {
    this.setState({ search: search, hasMore: true }, () => this.handleReset());
  }

  render() {
    return (
      <>
        <div
          className={
            "shop-fillter offer-filter offcanvas offcanvas-bottom bg-white" +
            (this.props.panelShow ? " show" : "")
          }
          style={{
            height: this.props.height || "auto",
          }}
        >
          <div className="offcanvas-header d-flex flex-column border-bottom p-0">
            <div className="w-100 d-flex ">
              <div
                class="p-3 pointer"
                onClick={() => {
                  this.handleReset();
                  this.handleClose();
                }}
              >
                <Cross width="20px" height="20px" />
              </div>
              <h4 className="title-color m-0 font-md fw-bold flex-fill w-100 text-start py-3">
                {this.props.title || ""}
              </h4>
              <label
                className="font-sm pointer fw-bold text-primary w-100 text-end ps-2  py-3 pe-3"
                onClick={() => this.handleReset()}
              >
                Atur Ulang
              </label>
            </div>
            <div className="w-100 pb-2 px-3 ">
              <SearchBox
                placeholder={"Cari " + (this.props.title || "")}
                readOnly={false}
                onChange={(v) => this.handleSearch(v)}
                onEnter={(e)=>{}}
              />
            </div>
          </div>
          <div
            className="offcanvas-body small pb-3 bg-white px-0"
            style={{
              height: this.props.height || "auto",
            }}
          >
            <InfiniteScroll
              pageStart={0}
              loadMore={() => this.fetchMoreData()}
              hasMore={this.state.hasMore}
              loader={<Spinner />}
              useWindow={false}
            >
              <ul class="list-group list-group-flush prevent-select">
                {this.state.items.map((v, i) => (
                  <li
                    class="list-group-item d-flex justify-content-between align-items-center px-4 py-3  pointer"
                    key={i}
                    onClick={() => this.handleClick(v, i)}
                  >
                    <label class="form-check-label pointer">{v.label}</label>
                    <span onClick={() => this.handleClick(v, i)}>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        name="option"
                        readOnly={true}
                        checked={v.checked || false}
                        onClick={() => this.handleClick(v, i)}
                      />
                    </span>
                  </li>
                ))}
              </ul>
            </InfiniteScroll>
          </div>
          <div className="offcanvas-footer">
            <div className="btn-box">
              <button
                className="btn-solid font-md"
                onClick={() => this.handleFilter()}
              >
                Terapkan
              </button>
            </div>
          </div>
        </div>
        {this.props.panelShow ? <Overlay /> : ""}
      </>
    );
  }
}

export default ModalOptionsList;
