import axios from "axios";
import { getToken } from "./authUtils";

const routePath = () =>{
  if(process.env.REACT_APP_ENV === "DEVELOPMENT")
      return  process.env.REACT_APP_API_DEVELOPMENT
  if(process.env.REACT_APP_ENV === "TESTING")
      return  process.env.REACT_APP_API_TESTING
  if(process.env.REACT_APP_ENV === "PRODUCTION")
    return process.env.REACT_APP_API_PRODUCTION
}
const api = axios.create({
  baseURL: routePath(),
});
// Request interceptor for API calls
api.interceptors.request.use(
  async (config) => {
    config.headers = {
      Authorization: "Bearer " + getToken(),
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    return config;
  },
  (error) => {
    if (error.response.status === 401) {
      // window.location.href = '/';
    }
    return Promise.reject(error);
  }
);

export default api;


export const getList = (url, filter = {}) => {
  
  return api
    .get(url, {
      params: filter,
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      // Error
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.data) return error.response.data;
      }
    });
};

export const addItem = (url, data) => {
  return api
    .post(url, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      // Error
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.data) return error.response.data;
      }
    });
};

export const postItem = addItem;


export const showItem = (url, id) => {
  return api
    .get(`${url}/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      // Error
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.data) return error.response.data;
      }
    });
};

export const deleteItem = (url, id) => {
  return api
    .delete(`${url}/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      // Error
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.data) return error.response.data;
      }
    });
};

export const updateItem = (url, data, id) => {
  return api
    .put(`${url}/${id}`, data)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      // Error
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (error.response.data) return error.response.data;
      }
    });
};

