import { useParams } from "react-router-dom";
import api, { addItem, deleteItem, getList } from "../../utils/api";
import { 
  TAMBAH_PRODUK,
  EDIT_PRODUK,
  GET_KERANJANG,
  DELETE_PRODUK,
  UPDATE_RESPONSE,
  UPDATE_LOADING,
  UPDATE_REDIRECT
} from './../../enums/redux_action'


export const action_keranjang_add_produk =
  (data, isUpdateData, kodeToko, callBack = null) => (dispatch) => {
    dispatch({
      type: UPDATE_LOADING,
      value: true,
    });

    addItem(kodeToko+"/keranjang", { ...data, type: TAMBAH_PRODUK })
      .then((res) => {
        if(res.code === 200)
          callBack()
        dispatch({
          type: UPDATE_RESPONSE,
          value: {
            name: TAMBAH_PRODUK,
            status: res.status,
            message: res.message,
            code: res.code,
          },
        });

        // LOADING FALSE
        dispatch({
          type: UPDATE_LOADING,
          value: false,
        });

        if (isUpdateData)   getKeranjang(dispatch,kodeToko);

      });
  };
export const action_keranjang_edit_produk = (data,kodeToko, callback = null) => (dispatch) => {
  // LOADING TRUE
  dispatch({
    type: UPDATE_LOADING,
    value: true,
  });

  addItem(kodeToko+"/keranjang", { ...data, type: EDIT_PRODUK }).then((res) => {
    if(res.code === 200 && callback)
      callback()

    dispatch({
      type: UPDATE_RESPONSE,
      value: {
        name: EDIT_PRODUK,
        status: res.status,
        message: res.message,
        code: res.code,
      },
    });

      getKeranjang(dispatch,kodeToko);

  });
};

export const action_keranjang_delete_produk = (id,kodeToko) => (dispatch) => {
  // LOADING TRUE
  dispatch({
    type: UPDATE_LOADING,
    value: true,
  });

  deleteItem(kodeToko+"/keranjang", id).then((res) => {
    dispatch({
      type: UPDATE_RESPONSE,
      value: {
        name: DELETE_PRODUK,
        status: res.status,
        message: res.message,
        code: res.code,
      },
    });
  getKeranjang(dispatch,kodeToko);
  });
};

export const action_get_data_keranjang = (kodeToko) => (dispatch) => {
  getKeranjang(dispatch,kodeToko);
};

const getKeranjang = (dispatch,kodeToko) => {
  dispatch({
    type: UPDATE_LOADING,
    value: true,
  });

  getList(kodeToko+"/keranjang").then((res) => {
    if (res.code === 200) {
      dispatch({
        type: "SET_DATA_KERANJANG",
        value: res.data,
      });
    } else if(res.code === 401)
      dispatch({
        type: UPDATE_REDIRECT,
        value: "/"+kodeToko+"/login",
      });
    else
      dispatch({
        type: UPDATE_RESPONSE,
        value: {
          name: GET_KERANJANG,
          status: res.status,
          message: res.message,
          code: res.code,
        },
      });
    dispatch({
      type: UPDATE_LOADING,
      value: false,
    });
  });
};

// ############## ALAMAT ###############
export const action_keranjang_edit_alamat = (data, kodeToko) => (dispatch) => {
  // LOADING TRUE
  dispatch({
    type: UPDATE_LOADING,
    value: true,
  });

  addItem(kodeToko+"/keranjang/alamat", data).then((res) => {
    dispatch({
      type: UPDATE_RESPONSE,
      value: {
        status: res.status,
        message: res.message,
        code: res.code,
      },
    });

    getKeranjang(dispatch, kodeToko);
  });
};

// ############## KURIR ###############
export const action_keranjang_edit_kurir = (data, kodeToko) => (dispatch) => {
  // LOADING TRUE
  dispatch({
    type: UPDATE_LOADING,
    value: true,
  });

  addItem(kodeToko+"/checkout/kurir", data).then((res) => {
    dispatch({
      type: UPDATE_RESPONSE,
      value: {
        status: res.status,
        message: res.message,
        code: res.code,
      },
    });

    getKeranjang(dispatch, kodeToko);
  });
};


export const action_keranjang_edit_kupon = (data, kodeToko) => (dispatch) => {
  // LOADING TRUE
  dispatch({
    type: UPDATE_LOADING,
    value: true,
  });

  addItem(kodeToko+"/checkout/kupon", data).then((res) => {
    dispatch({
      type: UPDATE_RESPONSE,
      value: {
        status: res.status,
        message: res.message,
        code: res.code,
      },
    });

    getKeranjang(dispatch, kodeToko);
  });
};

// ############## FUNGSIONAL ###############
export const set_redirect = (redirectTo) => (dispatch) => {
  dispatch({
    type: UPDATE_REDIRECT,
    value: redirectTo,
  });
};


export const set_loading = (isLoading) => (dispatch) => {
  dispatch({
    type: UPDATE_LOADING,
    value: isLoading,
  });
};
