import React, { Component } from "react";
import ShopFilter from "../../../components/makro/ShopFilter";
import ShopFilterSection from "../../../components/makro/ShopFilterSection";
import HeaderPencarian from "./../../../parts/app/HeaderPencarian";
import api, { getList } from "../../../utils/api";
import { decodeParams } from "../../../utils/LinkUrl";
import { withRouter } from "../../../utils/WithRouter";
import NumericFormatInput from "../../../components/mikro/NumericFormatInput";

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      panelShow: false,
      rekomendasi: [],
      params: decodeParams(),
      filter: {
        merek: [],
        harga_awal: "",
        harga_akhir: "",
        urutan: 'Recommended',
        search: "",
      },
    };
  }
  componentDidMount() {
    this.getApiFilter();

    let ft = { 
      ...this.state.filter
    }
    if (this.state.params?.q !== "")
      ft = { 
        ...ft, 
        search: this.state.params?.q || "", 
      }
    if (this.state.params?.merek !== "")
      ft = { 
        ...ft, 
        merek:[
            ...this.state.filter.merek, 
            this.state.params?.merek || ''
        ] 
      }

    this.setState(
      {
        filter: ft,
      },
      () => this.props.onFilter(this.state.filter)
    );
  }

  getApiFilter() {
    getList(this.props.params.kodeToko+"/filter-pencarian/rekomendasi").then((res) => {
      if (res.code === 200) {
        this.setState({
          rekomendasi: res?.data,
        });
      }
    });
  }
  handleReset() {
    this.refUrutan.handleReset();
    this.refHarga.handleReset();
    this.refMerek.handleReset();

    this.setState({
      filter: {
        search: this.state.params?.q || "",
        merek: [],
        harga_awal: "",
        harga_akhir: "",
        urutan: 'Recommended',
      },
    });
  }

  handleFilter() {
    this.setState({ panelShow: false }, () =>
      this.props.onFilter(this.state.filter)
    );
  }

  render() {
    const { params, filter, rekomendasi, panelShow } = this.state;
    return (
      <div
        ref="iScroll"
        style={{
          paddingTop: "56px",
          height: "100vH",
        }}
      >
        <HeaderPencarian
          btnFilter={true}
          onClickFilter={() => this.setState({ panelShow: true })}
          onSearch={(search) =>
            this.setState({ filter: { ...filter, search: search } }, () =>
              this.handleFilter()
            )
          }
          searchValue={params.q || ""}
        />
        <main
          className="main-wrap search-page py-3"
          style={{ backgroundColor: "#efefef", minHeight: "calc(100vH - 56px)" }}
        >
          <div>{this.props.children}</div>
        </main>
        <ShopFilter
          panelShow={panelShow}
          height={"100%"}
          onClose={() => this.setState({ panelShow: false })}
          onReset={() => this.handleReset()}
          onFilter={() => this.handleFilter()}
        >
          <div className="mt-0">
            <ShopFilterSection
              onRef={(ref) => (this.refUrutan = ref)}
              title="Urutan"
              multiple={false}
              seeAll={false}
              data={[
                { active: false, title: "Produk Terbaru" },
                { active: false, title: "Produk Lama" },
                { active: false, title: "A to Z" },
                { active: false, title: "Z to A" },
              ]}
              onChangeValue={(v) =>
                this.setState({
                  filter: {
                    ...filter,
                    urutan: v.active === true ? v.title : "Produk Terbaru",
                  },
                })
              }
            />
            <ShopFilterSection
              onRef={(ref) => (this.refHarga = ref)}
              title="Harga"
              multiple={false}
              seeAll={false}
              data={[
                { active: false, title: "0K - 10K", start: 0, end: 10000 },
                {
                  active: false,
                  title: "10K - 50K",
                  start: 10000,
                  end: 50000,
                },
                {
                  active: false,
                  title: "50K - 100K",
                  start: 50000,
                  end: 100000,
                },
              ]}
              onChangeValue={(v) =>
                this.setState({
                  filter: {
                    ...filter,
                    harga_awal: v.active === true ? v.start : "",
                    harga_akhir: v.active === true ? v.end : "",
                  },
                })
              }
            >
              <div className="row mb-1">
                <div className="col-6">
                  <label className="mb-1">Harga Minimal</label>
                  <div className="input-group">
                    <span className="input-group-text font-xs">Rp</span>
                    <NumericFormatInput
                      className="form-control text-right input-sm"
                      value={filter.harga_awal}
                      name="paket_berat"
                      onChange={(e) =>
                        this.setState({
                          filter: {
                            ...filter,
                            harga_awal: e.target.value,
                          },
                        })
                      }
                      placeholder="Harga Minimal"
                    />
                    
                  </div>
                </div>
                <div className="col-6">
                  <label className="mb-1">Harga Maksimal</label>
                  <div className="input-group">
                    <span className="input-group-text font-xs">Rp</span>
                    <NumericFormatInput
                      className="form-control text-right input-sm"
                      value={filter.harga_akhir}
                      name="paket_berat"
                      onChange={(e) =>
                        this.setState({
                          filter: {
                            ...filter,
                            harga_akhir: e.target.value,
                          },
                        })
                      }
                      placeholder="Harga Maksimal"
                    />
                  </div>
                </div>
              </div>
            </ShopFilterSection>

            <ShopFilterSection
              onRef={(ref) => (this.refMerek = ref)}
              url={this.props.params.kodeToko+"/list-merek"}
              title="Merek"
              multiple={true}
              seeAll={true}
              data={rekomendasi?.merek || []}
              onChangeValue={(v) =>
                this.setState({ filter: { ...filter, merek: v } })
              }
            />
          </div>
        </ShopFilter>
      </div>
    );
  }
}

export default withRouter(Filter);
