import React from "react";
import { useParams } from "react-router-dom";

import { Link } from "react-router-dom";
import ImageFrame from "../../../components/mikro/ImageFrame";
import { encodeDataToURL } from "../../../utils/LinkUrl";

 const SectionKategori = ({items}) => {

  const {kodeToko} = useParams()
  return (
    <section className="category low-price-section mb-2 py-3 inside-wrap  bg-white default-card-shadow w-100">
      <div className="top-content">
        <div className="d-flex flex-column">
            <h4 className="title-color mt-0 me-2">Kategori Tersedia</h4>
        </div>
      </div>

      <div className="row">
        {items.map((d, i) => (
            <div
                className="col-lg-2  col-md-2 col-sm-3 col-4"
                key={i}
            >
                <Link
                to={
                    "/"+kodeToko+"/search?" + encodeDataToURL({ q: d.nama || "" })
                }
                className="staples-box pointer text-center px-2   mb-2"
                >
                <div className="mb-2">
                    <ImageFrame
                    className="img-fluid"
                    fallbackSrc={require("../../../assets/images/brand/default.png")}
                    src={d.gambar || ""}
                    />
                </div>
                <div className="title-color font-xs">
                    {d.nama || ""}
                </div>
                </Link>
            </div>
            ))}
      </div>
    </section>
  );
}
export default SectionKategori