import React, { useState } from "react";
import Overlay from "../../components/mikro/Overlay";
import { Cross } from "../mikro/Icon";
const ShopFilter = (props) => {
  return (
    <>
      <div
        className={
          "shop-fillter offer-filter offcanvas offcanvas-bottom bg-white" +
          (props.panelShow ? " show" : "")
        }
        id="filter"
        style={{
          height: props.height || "auto",
        }}
      >
        <div className="offcanvas-header d-flex flex-column border-bottom p-0">
          <div className="w-100 d-flex ">
            <div className="p-3 pointer" onClick={() => props.onClose()}>
              <Cross width="20px" height="20px" />
            </div>
            <h4 className="title-color m-0 font-md fw-bold flex-fill w-100 text-start py-3">
              Filter
            </h4>
            <label
              className="font-sm pointer fw-bold text-primary w-100 text-end ps-2  py-3 pe-3"
              onClick={() => props.onReset()}
            >
              Atur Ulang
            </label>
          </div>
        </div>
        <div
          className="offcanvas-body small pb-3"
          style={{
            height: props.height || "auto",
            backgroundColor: "rgb(239, 239, 239)",
          }}
        >
          {props.children}
        </div>
        <div className="offcanvas-footer">
          <div className="btn-box">
            <button
              className="btn-solid font-md"
              onClick={() => props.onFilter()}
            >
              Terapkan
            </button>
          </div>
        </div>
      </div>
      {props.panelShow ? <Overlay /> : ""}
    </>
  );
};

export default ShopFilter;
