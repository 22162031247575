import React, { useEffect, useState } from "react";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import "./assets/css/vendors/bootstrap.css";
import "./assets/css/iconly.css";
import "./assets/css/vendors/slick.css";
import "./assets/css/vendors/slick-theme.css";
import "./assets/css/style.css";
import "./App.css";
import "./styles/timeline.css";
import { useSnackbar } from "react-simple-snackbar";
// others
import P404 from "./pages/others/P404";

// Auth
import Login from "./pages/auth/Login";

// Application
import Home from "./pages/app/Home";
import Kategori from "./pages/app/Kategori";
import Merek from "./pages/app/Merek";

import Akun from "./pages/app/Akun";
import LandingPage from "./pages/app/LandingPage";

import EditProfil from "./pages/app/EditProfil";
import Alamat from "./pages/app/Alamat";
import AlamatForm from "./pages/app/AlamatForm";
import Search from "./pages/app/Search";
import ProductDetail from "./pages/app/ProductDetail";
import CekOngkosKirim from "./pages/app/CekOngkosKirim";
import Keranjang from "./pages/app/Keranjang";
import Keluar from "./pages/app/Keluar";

import TransaksiSaya from "./pages/app/Transaksi/TransaksiSaya";
import DetailTransaksi from "./pages/app/Transaksi/DetailTransaksi";
import TransaksiPilihPembayaran from "./pages/app/Transaksi/PilihPembayaran";
import TransaksiPembayaran from "./pages/app/Transaksi/Pembayaran";
import TransaksiPembayaranSelesai from "./pages/app/Transaksi/PembayaranSelesai";

//function
import ApplicationPage from "./pages/layouts/ApplicationPage";
import { useDispatch, useSelector } from "react-redux";
import { set_redirect } from "./store/action";
import ProtectedRoute from "./routes/ProtectedRoutes";
import LoadingOverlay from 'react-loading-overlay-ts';

export default function App() {
  //SETTER
  const store = useSelector((state) => state);
  const navigate = useNavigate();
  const [openSnackbar] = useSnackbar();
  const dispatch = useDispatch();

  //PREVPROPS SAVER
  const [isRedirect, setIsRedirect] = useState("");
  const [response, setResponse] = useState({});

  useEffect(() => {
    if (store.redirect !== isRedirect) {
      setIsRedirect(store.redirect);
      if (store.redirect !== "") navigate(store.redirect);

      setTimeout(() => {
        dispatch(set_redirect(""));
      }, 100);
    }
    if (store.response !== response) {
      setResponse(store.response);

      if (store?.response?.message && store?.response?.message !== "")
        openSnackbar(store.response.message, 2000);
    }
  }, [store]);

  return (
    <div
      
      style={{
        boxShadow: "0 0 3px 1px rgb(0 0 0 / 10%)",
        height: "100%",
        minHeight: "100vH",
      }}
    >
      <LoadingOverlay
        active={store.isLoading}
        spinner
      >
        <Routes>
          <Route path="/" element={<LandingPage />} />

          {/* WITH BOTTOM BAR */}
          <Route path=":kodeToko/*" element={<ApplicationPage isUseBottomBar={true} />}>
            <Route path="" element={<Home />} />
            
            <Route path="kategori" element={<Kategori />} />

            <Route path="merek" element={<Merek />} />
            <Route index path="akun" element={<Akun />} />
          </Route>

          {/* WITHOUT BOTTOM BAR */}
          <Route path="/:kodeToko/*" element={<ApplicationPage isUseBottomBar={false}/>}>
            <Route path="login" element={<Login />} />
            <Route index path="search" element={<Search />} />

            <Route path="p/:id" element={<ProductDetail />} />
            <Route index path="cek-ongkos-kirim" element={<CekOngkosKirim />} />
            <Route
              index
              path="keranjang"
              element={<Keranjang />}
            />
            <Route element={<ProtectedRoute />}>
              <Route index path="akun/alamat" element={<Alamat />} />
              <Route
                index
                path="akun/alamat/tambah-alamat"
                element={<AlamatForm />}
              />
              <Route index path="akun/alamat/:id/ubah" element={<AlamatForm />} />
            </Route>
          

            <Route index path="transaksi/pilih-pembayaran/:idTransaksi" element={<TransaksiPilihPembayaran />} />
            <Route index path="transaksi/pembayaran/:idTransaksi/:idBank" element={<TransaksiPembayaran />} />
            <Route index path="transaksi/pembayaran/selesai" element={<TransaksiPembayaranSelesai />} />
            <Route index path="transaksi-saya" element={<TransaksiSaya />} />
            <Route index path="transaksi-saya/:idTransaksi" element={<DetailTransaksi />} />

            <Route index path="akun/profil" element={<EditProfil />} />
           
            <Route index path="akun/keluar" element={<Keluar />} />
          </Route>

          {/* <Route path="/syarat-ketentuan" element={<SignIn />} /> */}
          {/* <Route path="about" element={<About />} /> */}
          <Route path="*" element={<P404 />} />
        </Routes>
      </LoadingOverlay>
    </div>
  );
}
