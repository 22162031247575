import React from "react";
import { Link, useParams } from "react-router-dom";
import { Cart, WA } from "../../../components/mikro/Icon";
import { useSelector } from 'react-redux'
const SectionFooter = ({data,...props}) => {
  const {kodeToko} = useParams()

  const toko = useSelector((state) => state.toko)

  return (
    <footer className="footer-wrap bg-white prevent-select py-0 inside-wrap">
      <div className="d-flex gap-2">
        <div className="text-success text-center d-flex flex-column justify-content-center py-2  align-middle bg-white">
          <Link to={'/'+kodeToko} className="btn btn-outline-secondary btn-lg font-sm"  style={{ width:"50px"}}>
            <i className="iconly-Home icli"></i>
          </Link>
        </div>
        <div className="flex-fill  text-success text-center d-flex flex-column justify-content-center pr-2 py-2 align-middle bg-white">
          {toko?.wa && <a href={`https://wa.me/${toko?.wa}?text=Hallo Kak, saya tertarik dengan produk di Toko kakak. Mohon informasinya https://berberkah.com/${kodeToko}+/p/${data.id}`} target="_blank" className="btn btn-outline-secondary font-sm btn-lg align-items-center" rel="noreferrer">
            <WA width="15px" height="15px" /> &nbsp;Chat Seller
          </a>}
        </div>
        <div 
          className="flex-fill  text-success text-center d-flex flex-column justify-content-center  py-2 align-middle bg-white"
          onClick={() => props.onClickPesan()}
        >
          <button type="button" className="btn btn-primary btn-lg align-items-center font-sm">
            <Cart width="15px" height="15px" /> &nbsp;Pesan Sekarang
          </button>
        </div>
      </div>
    </footer>
  );
};

export default SectionFooter;
