import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import CardBrand from "../../../components/makro/CardBrand";
import { useParams } from "react-router-dom";

 const SectionBrand = ({items}) => {

  const {kodeToko} = useParams()
  return (
    <section className="category low-price-section mb-2 py-3 inside-wrap  bg-white default-card-shadow">
      <div className="top-content">
        <div className="d-flex flex-column">
            <h4 className="title-color mt-0 me-2">Merek Tersedia</h4>
        </div>
      </div>

      <div className="product-slider">
        <div style={{ padding: "10px 0" }}>
          <Swiper
            modules={[FreeMode]}
            className="mySwiper"
            grabCursor={true}
            breakpoints={{
              0: {
                slidesPerView: 1.5,
                spaceBetween: 10,
              },
              250: {
                slidesPerView: 4,
                spaceBetween: 10,
              },

              650: {
                slidesPerView: 5.2,
                spaceBetween: 10,
              },
              800: {
                slidesPerView: 6.5,
                spaceBetween: 10,
              },
            }}
          >
            {items &&
              items.map((d, i) => {
                return (
                  <SwiperSlide key={i}>
                    <CardBrand data={{...d, linkTo:`/${kodeToko}/search?merek=${d.nama}`}} />
                  </SwiperSlide>
                );
              })}
          </Swiper>
        </div>
      </div>
    </section>
  );
}
export default SectionBrand