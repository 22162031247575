import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import Header1 from "../../../parts/app/Header1";
import api from "../../../utils/api";
import ImageFrame from "../../../components/mikro/ImageFrame";
import { encodeDataToURL } from "../../../utils/LinkUrl";
import InfiniteScroll from "react-infinite-scroller";
import FilterButtonAndBadge from "../../../components/mikro/FilterButtonAndBadge";
import Spinner from "../../../components/mikro/Spinner";

export default class Kategori extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      kategori: [],
      current_page: 0,
      last_page: 0,
      per_page: 0,
      total: 0,
      hasMore: false,
    };
  }

  componentDidMount() {
    this.getIndex();
  }

  getIndex() {
    api
      .get("berberkah/merek", {
        params: {
          page: this.state.current_page + 1,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          let { items } = this.state;
          this.setState({
            items: items.concat(res?.data?.data),
            current_page: res?.data?.meta?.current_page || 0,
            last_page: res?.data?.meta?.last_page || 0,
            per_page: res?.data?.meta?.per_page || 0,
            total: res?.data?.meta?.total || 0,
            hasMore: res.data.data.length > 0 ? true : false,
          });
        }
      });
  }

  render() {
    const { items, kategori } = this.state;

    return (
      <div
        style={{
          paddingTop: "56px",
        }}
      >
        <Header1
          linkLeft={
            <Link to="/s" className="pointer">
              <i class="iconly-Search icli  text-dark"></i>
            </Link>
          }
          title="Merek"
          linkNotification={true}
          linkCart={true}
        />
        <div
          className="main-wrap catagories-wide"
          style={{ position: "relative", marginBottom: "60px" }}
        >
          <div className="catagories-wrap">
            <FilterButtonAndBadge
              data={kategori || []}
              onChangeValue={(value) =>
                this.setState(
                  {
                    params: {
                      ...this.state.params,
                      kategori_id: value,
                    },
                  },
                  () => this.getIndex()
                )
              }
            />

            <div
              className="footer-wrap"
              style={{
                overflowY: "auto",
                height: "calc(100vH - 56px)",
                top: "56px",
                bottom: "0px",
                padding: "12px",
                width: "100%",
                background: "#fff",
                zIndex: 1,
              }}
            >
              <InfiniteScroll
                pageStart={0}
                loadMore={() => this.getIndex()}
                hasMore={this.state.hasMore}
                loader={<Spinner />}
                useWindow={false}
              >
                <div className="w-100 h-100">
                  <div className="row g-3 pb-5">
                    {items.map((d, i) => (
                      <div
                        className="col-lg-2 col-md-3 col-sm-3  col-4"
                        key={i}
                      >
                        <Link
                          to={"/search?" + encodeDataToURL({ q: d.nama || "" })}
                          className="staples-box pointer text-center px-1 mb-2"
                        >
                          <div className="mb-2">
                            <ImageFrame
                              className="img-fluid"
                              fallbackSrc={require("../../../assets/images/brand/default.png")}
                              src={d.gambar || ""}
                            />
                          </div>
                          <div className="title-color font-xs">
                            {d.nama || ""}
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
              </InfiniteScroll>
            </div>
          </div>
          {/* Catagories Section End -*/}
        </div>
      </div>
    );
  }
}
