import React, { Component } from "react";
import Overlay from "../../components/mikro/Overlay";

export default function ModalConfirm(props) {
  return (
    <>
      <div
        className={
          "shop-fillter offer-filter offcanvas offcanvas-bottom bg-white" +
          (props.panelShow ? " show" : "")
        }
      >
        <div className="offcanvas-header d-flex flex-column border-bottom p-0">
          <div className="w-100 d-flex inside-wrap">
            <h4 className="title-color m-0 font-md fw-bold flex-fill w-100 text-start py-3">
              {props.title || ""}
            </h4>
          </div>
        </div>
        <div className="offcanvas-body small pb-3  bg-white p-3">
          {props.content}
        </div>
        <div className="offcanvas-footer">
          <div className="btn-box mb-2">
            <button
              className="btn py-2 btn-outline-secondary border-secondary"
              onClick={() => props.onClose()}
            >
              Tutup
            </button>
            <button
              className="btn py-2 border-danger btn-danger"
              onClick={() => props.onConfirm()}
            >
              {props.btnTitle || "Ya, Hapus"}
            </button>
          </div>
        </div>
      </div>
      {props.panelShow ? <Overlay /> : ""}
    </>
  );
}
