import api, { addItem, getList, postItem } from "../../utils/api";
import { 
  UPDATE_RESPONSE,
  UPDATE_LOADING
} from '../../enums/redux_action'
import { KURIR_TYPE } from "../../enums/aplikasi";

export const action_transaksi_checkout = (kodeToko, payment_method, callback) => (dispatch) => {
  dispatch({
    type: UPDATE_LOADING,
    value: true,
  });
  postItem(kodeToko+"/transaksi/checkout").then((res) => {
    if(parseInt(res.code) === 200 && res?.data?.id)
      callback(
      payment_method === KURIR_TYPE.REGULER 
       ? "/"+kodeToko+"/transaksi/pilih-pembayaran/"+(res?.data?.id || '')
       : "/"+kodeToko+"/transaksi-saya/"+(res?.data?.id || '')
      );
    dispatch({
      type: UPDATE_RESPONSE,
      value: {
        status: res.status,
        message: res.message,
        code: res.code,
      },
    });
    dispatch({
      type: UPDATE_LOADING,
      value: false,
    });
  }).catch((err)=>{
    dispatch({
      type: UPDATE_LOADING,
      value: false,
    });
  });
};

export const action_transaksi_charge_payment = (kodeToko,params,callback) => (dispatch) => {
  dispatch({
    type: UPDATE_LOADING,
    value: true,
  });
  postItem(kodeToko+"/payment/charge",params).then((res) => {
    if(parseInt(res.code) === 200)
      callback("/"+kodeToko+"/transaksi/pembayaran/"+(params.transaksi_id || '')+"/"+(params.kode_bank || ''));

    if(parseInt(res.code) !== 200)
      dispatch({
        type: UPDATE_RESPONSE,
        value: {
          status: res.status,
          message: res.message,
          code: res.code,
        },
      });
    dispatch({
      type: UPDATE_LOADING,
      value: false,
    });
  }).catch((err)=>{
    dispatch({
      type: UPDATE_LOADING,
      value: false,
    });
  });
};


export const action_transaksi_batal = (kodeToko, transaksiId,callback) => (dispatch) => {
  dispatch({
    type: UPDATE_LOADING,
    value: true,
  });
  postItem(kodeToko+'/transaksi/'+transaksiId+'/cancel').then((res) => {
    if(parseInt(res.code) === 200)
      callback(res);

    if(parseInt(res.code) !== 200)
      dispatch({
        type: UPDATE_RESPONSE,
        value: {
          status: res.status,
          message: res.message,
          code: res.code,
        },
      });
    dispatch({
      type: UPDATE_LOADING,
      value: false,
    });
  }).catch((err)=>{
    dispatch({
      type: UPDATE_LOADING,
      value: false,
    });
  });
};


export const action_transaksi_check_status_payment = (kodeToko, transaksiId,callback) => (dispatch) => {
  dispatch({
    type: UPDATE_LOADING,
    value: true,
  });
  getList(kodeToko+"/payment/"+transaksiId+"/status").then((res) => {
    if(parseInt(res?.code) === 200)
      callback({
        status:res?.data?.status,
        redirectLink:'/'+kodeToko+"/transaksi/"+transaksiId
      });

    if(parseInt(res.code) !== 200)
      dispatch({
        type: UPDATE_RESPONSE,
        value: {
          status: res.status,
          message: res.message,
          code: res.code,
        },
      });
    dispatch({
      type: UPDATE_LOADING,
      value: false,
    });
  }).catch((err)=>{
    dispatch({
      type: UPDATE_LOADING,
      value: false,
    });
  });
};

export const action_transaksi_show_detail = (kodeToko, transaksiId,callback) => (dispatch) => {
  dispatch({
    type: UPDATE_LOADING,
    value: true,
  });
  getList(kodeToko+"/transaksi/"+transaksiId).then((res) => {
    if(parseInt(res?.code) === 200)
      callback(res?.data);
    if(parseInt(res.code) !== 200)
      dispatch({
        type: UPDATE_RESPONSE,
        value: {
          status: res.status,
          message: res.message,
          code: res.code,
        },
      });
    dispatch({
      type: UPDATE_LOADING,
      value: false,
    });
  }).catch((err)=>{
    dispatch({
      type: UPDATE_LOADING,
      value: false,
    });
  });
};


export const action_transaksi_get_lacak = (kodeToko,transaksiId,pesananId,callback) => (dispatch) => {
  dispatch({
    type: UPDATE_LOADING,
    value: true,
  });
  getList(kodeToko+"/transaksi/"+transaksiId+"/pesanan/"+pesananId+"/lacak").then((res) => {
    if(parseInt(res?.code) === 200)
      callback(res);

    if(parseInt(res.code) !== 200)
      dispatch({
        type: UPDATE_RESPONSE,
        value: {
          status: res.status,
          message: res.message,
          code: res.code,
        },
      });
    dispatch({
      type: UPDATE_LOADING,
      value: false,
    });
  }).catch((err)=>{
    dispatch({
      type: UPDATE_LOADING,
      value: false,
    });
  });
};
export const action_transaksi_set_pesanan_komplain = (kodeToko, transaksiId, pesananId, callback) => (dispatch) => {
  dispatch({
    type: UPDATE_LOADING,
    value: true,
  });
  addItem(`${kodeToko}/transaksi/${transaksiId}/pesanan/${pesananId}/komplain`).then((res) => {
    if(parseInt(res?.code) === 200)
      callback(res);

    dispatch({
      type: UPDATE_RESPONSE,
      value: {
        status: res.status,
        message: res.message,
        code: res.code,
      },
    });

    dispatch({
      type: UPDATE_LOADING,
      value: false,
    });
  }).catch((err)=>{
    dispatch({
      type: UPDATE_LOADING,
      value: false,
    });
  });
};

export const action_transaksi_set_pesanan_selesai = (kodeToko,transaksiId, pesananId, callback) => (dispatch) => {
  dispatch({
    type: UPDATE_LOADING,
    value: true,
  });
  postItem(kodeToko+`/transaksi/${transaksiId}/pesanan/${pesananId}/selesai`).then((res) => {
    if(parseInt(res?.code) === 200)
      callback();

    dispatch({
      type: UPDATE_RESPONSE,
      value: {
        status: res.status,
        message: res.message,
        code: res.code,
      },
    });

    dispatch({
      type: UPDATE_LOADING,
      value: false,
    });
  }).catch((err)=>{
    dispatch({
      type: UPDATE_LOADING,
      value: false,
    });
  });
};