import React from "react";

const Overlay = () => {
  return (
    <div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
};

export default Overlay;
