import React from "react";
import Carousel from "../../../components/makro/Carousel";
const SectionSlide = (props) => {
  return (
    <section className="banner-section ratio2_1 unmain-wrap pt-5 pb-0 bg-white">
      <div className="h-banner-slider">
        <Carousel height={"450px"} data={props.data || []} bgSize="contain" />
      </div>
    </section>
  );
};
export default SectionSlide;
