import React, { useEffect, useState, Component } from "react";

import NumberFormat from "react-number-format";

import { connect } from "react-redux";
import { action_get_data_keranjang, action_keranjang_edit_kurir } from "../../../store/action";
import Loading from "../../../components/mikro/Loading";
import Header1 from "../../../parts/app/Header1";
import EmptyCart from "../../../parts/app/keranjang/EmptyCart";
import SectionPesanan from "../../../parts/app/keranjang/SectionPesanan";
import SectionPromo from "../../../parts/app/keranjang/SectionPromo";
import SectionTotalBayar from "../../../parts/app/keranjang/SectionTotalBayar";
import ModalLayananPengiriman from "../../../parts/app/ModalLayananPengiriman";
import { ArrowLeft } from "../../../components/mikro/Icon";
import { Link } from "react-router-dom";
import { withRouter } from "../../../utils/WithRouter";
import SectionKodePemesanan from "../../../parts/app/keranjang/SectionKodePemesanan";

class Keranjang extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kurir:{
        isPanelShow:false,
        pesananId:"",
      }
    };
  }
  componentDidMount() {
    this.props.reduxGetDataKeKeranjang(this.props.params.kodeToko);
  }

  handleKurir(id){
    this.setState({ 
      kurir:{
        ...this.state.kurir, 
        isPanelShow:true,
        pesananId:id
      }
    })
  }

  render() {
    const { kurir } = this.state;
    const { cart, isLoading } = this.props;
    return (
      <div
        className="pb-1"
        style={{
          minHeight: "calc(100vH - 58px)",
          paddingTop: "58px",
        }}
      >
        <Header1 linkLeft={
          (<Link to={"/"+this.props.params.kodeToko}>
            <div className="avatar-wrap pointer">
              <span class="font-xl p-1">
                <ArrowLeft width="24px" height="24px" />
              </span>
            </div>
          </Link>)
        } title={"Keranjang"} />

        <div
          className="main-wrap product-page search-page index-page"
          style={{
            position: "relative",
            paddingBottom: "100px",
            backgroundColor: "rgb(238, 238, 238)",
            minHeight: "calc(100vH - 58px",
          }}
        >
        {cart && cart?.keranjang?.length < 1 && !isLoading && <EmptyCart />}
        {cart && cart?.keranjang?.length > 0 && 
            <SectionKodePemesanan 
              data={cart} />}
          {cart &&
            cart?.keranjang &&
            cart?.keranjang.map((d, i) => (
              <div>
                <SectionPesanan
                  data={d}
                  isKeranjang={true}
                  onClickKurir={id=>this.handleKurir(id)}
                />
              </div>
            ))}

            {cart &&
            cart?.keranjang && cart?.keranjang?.length > 0 && 
                <SectionPromo
                  data={cart}
                />
            }
            {/* ###################### */}
            {cart && cart?.keranjang?.length > 0 && 
            <div className="card" >
              <div className="card-body" >
                <ul class="list-group list-group-flush">
                { cart &&
                  cart?.keranjang &&
                  cart?.keranjang.map((d,i) =>(
                  <li class="list-group-item px-2">
                    <h5 className="fw-bold font-xs ">{d?.alamat?.nama_penerima}</h5>
                    <div className="d-flex justify-content-between inside-wrap pt-1  align-items-center">
                      <div className="font-xs fw-500">Total Harga&nbsp;
                      <NumberFormat
                          value={d.total_jumlah || 0}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator=","
                          prefix={"("}
                          suffix={" Barang)"}
                        />
                      </div>
                      <div  className="font-xs fw-bold ms-2">
                        <NumberFormat
                          value={d.total_harga || 0}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator=","
                          prefix={"Rp"}
                        />
                      </div>
                    </div>
                    {d.kurir && <div className="d-flex justify-content-between inside-wrap pt-1  align-items-center">
                      <div className="font-xs fw-500">Total Ongkir&nbsp;
                        <NumberFormat
                          value={d.total_berat || 0}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator=","
                          prefix={"("}
                          suffix={" gr)"}
                        />
                      </div>
                      <div  className="font-xs fw-bold ms-2">
                        <NumberFormat
                          value={d?.kurir?.harga || 0}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator=","
                          prefix={"Rp"}
                        />
                      </div>
                    </div>}
                    
                    <div className="d-flex justify-content-between inside-wrap pt-1  align-items-center">
                      <div className="font-xs fw-bold">Subtotal</div>
                      <div  className="font-xs fw-bold ms-2">
                        <NumberFormat
                          value={d.subtotal || 0}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator=","
                          prefix={"Rp"}
                        />
                      </div>
                    </div>
                  </li>
                ))}
                {cart?.kupon?.jenis && cart?.total_diskon > 0 && 
                  <li class="list-group-item px-2">
                    <div className="d-flex justify-content-between pe-3 pt-1  align-items-center">
                      <div className="font-xs fw-bold">Total Diskon {cart?.kupon?.jenis}</div>
                      <div  className="font-xs fw-bold ms-2">
                        <NumberFormat
                          value={cart?.total_diskon || 0}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator=","
                          prefix={"-Rp"}
                        />
                      </div>
                    </div>
              </li>}
              </ul>
              </div>
            </div>}
        </div>
        {/* Main End */}

        {/* Footer Start */}
        {cart && cart?.keranjang?.length > 0 &&
          <SectionTotalBayar
            isUsePoin={true}
            isKeranjang={false}
            data={cart}
          />}

        <ModalLayananPengiriman 
          panelShow={kurir.isPanelShow} 
          pesananId={kurir.pesananId}
          onChange={(kurir)=>this.props.reduxKeranjangEditKurir(kurir,this.props.params.kodeToko)} 
          onClose={()=>this.setState({ kurir:{...kurir, isPanelShow:false, pesananId:""} })}
        />
          
      </div>
    );
  }
}

const reduxState = (state) => ({
  cart: state.keranjang,
  isLoading: state.isLoading,
});

const reduxDispatch = (dispatch) => ({
  reduxGetDataKeKeranjang: (kodeToko) => dispatch(action_get_data_keranjang(kodeToko)),
  reduxKeranjangEditKurir: (kurir,kodeToko) => dispatch(action_keranjang_edit_kurir(kurir,kodeToko)),
});

export default connect(reduxState, reduxDispatch)(withRouter(Keranjang));
