import React, { Component } from "react";
import HeaderHome from "../../../parts/app/HeaderHome";
import { getList } from "../../../utils/api";
import CardContentHeader from "./CardContentHeader";
import SectionBrand from "./SectionBrand";
import SectionFilter from "./SectionFilter";
import SectionFlashSale from "./SectionFlashSale";
import SectionProduk from "./SectionProduk";
import {withRouter} from './../../../utils/WithRouter';
import { getAuth, getToken } from "./../../../utils/authUtils";

class Home extends Component {
  constructor(props) {
   super(props);
   this.state = {
      produk:{
        items: [],
        current_page: 0,
        last_page: 0,
        per_page: 30,
        total: 0,
        hasMore: false,
        isLoading: false
      },
      merek:[],
      scrollNumber: 1,
      loadingState: false,
      filter:{
        merek: [],
        harga_awal: "",
        harga_akhir: "",
        urutan: 'Recommended',
      }
    };
  }
  componentDidMount() {
    this.getMerek();
        this.getProduk();

    this.refs.iScroll.addEventListener("scroll", () => {
      if(this.refs.iScroll.scrollTop % 10 === 0)
        this.setState({
          scrollNumber:this.refs.iScroll.scrollTop
        });
      if (this.refs.iScroll.scrollTop + this.refs.iScroll.clientHeight >= this.refs.iScroll.scrollHeight - 20){
        this.getProduk();
      }
    });
  }


  getProduk(){
    const { current_page,items, per_page,isLoading } = this.state.produk;
    if(items.length % per_page === 0 && isLoading === false)
      this.setState({produk:{
        ...this.state.produk,
        isLoading:true,
      }},()=>{

      getList(this.props.params.kodeToko+"/list-produk", {
        ...this.state.filter,
        page: current_page + 1,
      })
      .then((res) => {
        if (res?.code === 200) {
          this.setState({produk:{
            ...this.state.produk,
            items: current_page + 1 === 1 ? res?.data : items.concat(res?.data),
            current_page: res?.meta?.current_page || 0,
            last_page: res?.meta?.last_page || 0,
            per_page: res?.meta?.per_page || 0,
            total: res?.meta?.total || 0,
            hasMore: res.data.length > 0 ? true : false,

            isLoading:false,
          }});
        }
      })
    });

  }


  getMerek(){
    getList(this.props.params.kodeToko+"/list-merek")
    .then((res) => {
      if (res?.code === 200) {
        this.setState({merek:res?.data});
      }
    })
  }
  
  handleFilter(value){
    this.setState({ 
      isPanelShow: false, 
      produk:{
        ...this.state.produk,
        current_page: 0,
      },
      filter:{
        ...value
      }
    },()=>{
      this.getProduk();
    })
  }

  render(){
    const {produk,scrollNumber, merek} = this.state
    return (
      <div
          style={{
            paddingBottom: "56px",
            height:'100vH',
            overflowY: 'scroll',
            width:'100%',
            position:"absolute"
          }}
          ref="iScroll"
          className="scrollable-hiddenbar bg-page-dark position-relative"
        >
        
        <main
          class="main-wrap index-page   order-success-page pt-0 px-0"
          style={{ backgroundColor: "#eeeeee", position:'relative',  }}
        >
        <HeaderHome scrollY={scrollNumber} total={produk.total}/>
          <CardContentHeader/>
          <SectionBrand items={merek}/>
          <SectionFlashSale />
          <SectionFilter
            onChange={(value) => this.handleFilter(value) } 
            total={produk.total}/>
          <SectionProduk items={produk.items} hasMore={produk.items}/>
        </main>
      </div>
    );
  }
}
export default withRouter(Home)