import React from "react";
import NumberFormat from "react-number-format";
const SectionPesananTotalHarga = ({ data, isKeranjang}) => {
  return (
    <section className="product-section p-0 border-top">
      <div className="d-flex w-100 py-3 inside-wrap justify-content-between">
        <div>
          <span className="font-sm fw-bold">Total Harga</span>{" "}
          <small className="font-xs text-muted">
            <NumberFormat
              value={data.total_jumlah || 0}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator=","
              suffix={" Barang"}
            />
            <span>&nbsp;-&nbsp;</span>
            <NumberFormat
              value={data.total_berat || 0}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator=","
              suffix={" gr"}
            />
          </small>
        </div>
        <div className="font-md text-danger fw-bold">
          <NumberFormat
            value={data?.subtotal}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator=","
            prefix={"Rp"}
          />
        </div>
      </div>
    </section>
  );
};

export default SectionPesananTotalHarga;
