import React from "react";
import { Star } from "./../mikro/Icon";
const CardListComments = (props) => {
  return (
    <div className=" service-section  order-id-section product-review p-0 unmain-wrap bg-white">
      <div className="review-wrap">
        <ul class="navigation">
          {props.data.map((v, i) => (
            <List data={v} i={i} />
          ))}
        </ul>
      </div>
    </div>
  );
};
export default CardListComments;

const List = ({ data, i }) => {
  return (
      <li className={"border-bottom px-2" + (i === 0 ? " border-top" : "")}>
        <div className="review-box bg-white m-0 px-4 py-3">
          <div className="d-flex justify-content-between">
            <div className="flex-fill">
              <h4 className="font-sm fw-bold">{data.nama}</h4>
            </div>
            <div className=" d-flex align-items-center">
              <Star width="14" height="14" />&nbsp;
              <span className="font-xs content-color pt-1">{data.rating}</span>
            </div>
          </div>
          <div className="ps-2">
            {data.ulasan && <p class="font-xs mb-1">{data.ulasan }</p>}
            <p className=" text-muted"><small>{data.timestamp || ''} | Variasi: <b>{data.variasi_produk || ''}</b></small></p>
          </div>
        </div>
      </li>
  );
};
