import React, { Component } from "react";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import BtnCounter from "../../../components/mikro/BtnCounter";
import CartTrashButton from "../../../components/mikro/CartTrashButton";
import SectionPesananTambahCatatan from "./SectionPesananTambahCatatan";
import { urlDetailProduk } from "../../../utils/LinkUrl";
import { connect } from "react-redux";
import {
  action_keranjang_delete_produk,
  action_keranjang_edit_produk,
} from "../../../store/action";
import { withRouter } from "../../../utils/WithRouter";

class SectionPesananProdukDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataArr: {
        jumlah: props?.data?.jumlah,
        catatan: props?.data?.catatan,
      },
      dataArrOld: {
        jumlah: props?.data?.jumlah,
        catatan: props?.data?.catatan,
      },
    };
  }

  handleUpdate() {
    const { dataArr, dataArrOld } = this.state;
    const { data, alamat } = this.props;
    if (
      dataArrOld?.catatan !== dataArr.catatan ||
      dataArrOld?.jumlah !== dataArr.jumlah
    ) {
      this.setState({ dataArrOld: dataArr }, () => {
        this.props.action_keranjang_edit_produk({
          produkId: data.produk_variansi_id || null,
          jumlah: dataArr.jumlah || 0,
          catatan: dataArr.catatan || "",
        }, this.props.params.kodeToko,()=>{});
      });
    }
  }
  render() {
    const { data, isKeranjang } = this.props;
    const { dataArr } = this.state;
    return (
      <div className="flex-fill">
        <div className={"product-list media p-0 "+(isKeranjang ? "":"mb-2" )}>
          <Link
            to={urlDetailProduk(data?.nama, data?.produk_id, this.props.params.kodeToko)}
            className="rounded"
            style={{
              width: "120px",
              height: "110px",
              background:
                "url(" +
                (data.thumb !== ""
                  ? data.thumb
                  : require("../../../assets/images/product/default.png")) +
                ") no-repeat center center",
              backgroundSize: " 100%",
            }}
          ></Link>
          <Link
            to={urlDetailProduk(data?.nama, data?.produk_id, this.props.params.kodeToko)}
            className="media-body border-0"
          >
            <a class="font-sm fw-bold text-limit-2rows mb-1">
              {data?.nama || "Nama Produk"}
            </a>
            <div className="text-dark font-sm mb-1" style={{ gap: "1px" }}>
              Variasi:{" "}
              <span className="fw-bold">
                {data.tipe}, {data.ukuran}
              </span>
            </div>
            { isKeranjang ? <div className="text-danger font-lg  mb-1 " style={{ gap: "2px" }}>
              <small className="font-xs fw-bold">Rp</small>
              <NumberFormat
                className="fw-bold font-md"
                value={data.harga}
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator=","
              />
              &nbsp;
              <small className="font-xs fw-400 text-muted">perbarang</small>
            </div> : 
            <div className="text-dark font-xs fw-bold  mb-1 " style={{ gap: "2px" }}>
              <small className="font-xs fw-bold">Rp</small>
              <NumberFormat
                value={data.harga}
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator=","
              />&nbsp;x&nbsp;
              <NumberFormat
                value={data.jumlah}
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator=","
              />
              &nbsp;
              <small className="font-xs fw-400 text-muted">
                <NumberFormat
                  value={data.total_berat || 0}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator=","
                  prefix="("
                  suffix=" gr)"
                />
              </small>
            </div>}
          </Link>
          {isKeranjang && (<CartTrashButton
            onClick={() => this.props.action_keranjang_delete_produk(data.id, this.props.params.kodeToko)}
          />)}
        </div>
        {isKeranjang && (
          <div className="product-list media inside-wrap flex-row-reverse m-0 py-1">
            <BtnCounter
              max={data.stok}
              value={data.jumlah}
              onChange={(value) =>
                this.setState({ dataArr: { ...dataArr, jumlah: value } }, () =>
                  this.handleUpdate()
                )
              }
            />
            <small className="font-xs text-muted">
              Stok Tersedia {data.stok}
            </small>
          </div>
        )}
        
        <SectionPesananTambahCatatan
          isKeranjang={isKeranjang}
          catatan={dataArr.catatan}
          onChange={(value) =>
            this.setState({ dataArr: { ...dataArr, catatan: value } })
          }
          onClickOutside={() => this.handleUpdate()}
        />
      </div>
    );
  }
}

const reduxDispatch = (dispatch) => ({
  action_keranjang_delete_produk: (id,kodeToko) =>
    dispatch(action_keranjang_delete_produk(id,kodeToko)),
  action_keranjang_edit_produk: (data,kodeToko) =>
    dispatch(action_keranjang_edit_produk(data,kodeToko)),
});

export default connect(null, reduxDispatch)( withRouter(SectionPesananProdukDetail));
