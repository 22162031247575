import React from "react";
const SectionPesananButtonKonfirmasi = ({status,...props}) => {
  return (
    <section className="product-section p-0 border-top">
      <div className="d-flex w-100 py-3 inside-wrap justify-content-between">
        <div className="btn btn-sm w-50 mx-2 btn-warning" onClick={()=>props.onClickKomplain()}>Komplain</div>
        <div className={`btn btn-sm w-50 mx-2 btn-success`} onClick={()=>props.onClickSelesai()}>Pesanan Selesai</div>
      </div>
    </section>
  );
};

export default SectionPesananButtonKonfirmasi;
