import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ArrowLeft,FilterIcon } from "../../components/mikro/Icon";
import SearchBox from "../../components/mikro/SearchBox";
const HeaderPencarian = ({ btnFilter, ...props }) => {
  const {kodeToko} = useParams();
  return (
    <div className="header-wrap">
      <header className="header bg-white border-bottom">
        <div
          className="logo-wrap d-flex"
          style={{ width: "-webkit-fill-available" }}
        >
          <Link to={"/"+kodeToko}>
            <div className="avatar-wrap">
              <span className="font-xl p-1">
                <ArrowLeft width="24px" height="24px" />
              </span>
            </div>
          </Link>
          <SearchBox
            readOnly={false}
            value={props.searchValue || ""}
            onChange={(search) => props.onSearch(search)}
            onEnter={(search) => props.onSearch(search)}
          />
          {btnFilter ? (
            <button
              className="filter font-md p-2"
              type="button"
              onClick={() => props.onClickFilter()}
            >
              <div className="px-1 py-0 d-flex gap-2 pointer">
                <FilterIcon width="20" height="20"/><span>Filter</span>
              </div>
            </button>
          ) : (
            ""
          )}
        </div>
      </header>
    </div>
  );
};

export default HeaderPencarian;
