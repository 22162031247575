import React, { Component } from "react";
import Overlay from "../../components/mikro/Overlay";
import ImageFrame from "../../components/mikro/ImageFrame";
import api from "../../utils/api";
import { Cross } from "../../components/mikro/Icon";
import ListBadgeHorizontal from "../../components/mikro/ListBadgeHorizontal";
import NumberFormat from "react-number-format";
import BtnCounter from "../../components/mikro/BtnCounter";

class ModalPilihVariasiProduk extends Component {
  state = {
    id: "",
    gambar: "",
    harga: "",
    harga_diskon: "",
    tipe: "",
    ukuran: "",
    stok: "",
    jumlah: 1,
    variasiSelected: null,
  };

  componentDidMount() {
    this.setDataFromPropsData(0);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setDataFromPropsData(0);
    }
  }

  setDataFromPropsData(i) {
    const { data } = this.props;
    if (data && data?.variasi?.length > 0) {
      if (data.variasi && data.variasi.length > 0) {
        let gambar = data.tipe.filter(
          (v) => v.nama === data.variasi[i]["tipe"]
        );
        if (gambar.length > 0) {
          gambar = gambar[0].thumb;
        }
        this.setState({
          ukuran: data.variasi[i]["ukuran"],
          tipe: data.variasi[i]["tipe"],
          stok: data.variasi[i]["stok"],
          harga: data.variasi[i]["harga"],
          harga_diskon: data.variasi[i]["harga_diskon"],
          gambar: gambar,
          id: data.variasi[i]["uuid"],
          variasiSelected: data.variasi[i],
        });
      }
    }
  }

  handleInfoDetail() {
    const { tipe, ukuran } = this.state;
    if (
      tipe !== "" &&
      ukuran !== "" &&
      tipe !== null &&
      ukuran !== null &&
      typeof tipe !== "undefined" &&
      typeof ukuran !== "undefined"
    ) {
      let { data } = this.props;
      const index =
        data &&
        data.variasi &&
        data.variasi.length > 0 &&
        data.variasi.findIndex(
          (v) =>
            v.ukuran.toLowerCase() === (ukuran || "a").toLowerCase() &&
            v.tipe.toLowerCase() === (tipe || "a").toLowerCase()
        );
      if (index >= 0) this.setDataFromPropsData(index);
    }

    // setHargaReseller(data.variasi ? data.variasi[index]?.harga_reseller : 0);
    // setHargaCustomer(data.variasi ? data.variasi[index]?.harga_customer : 0);
    // setKomisi(data.variasi ? data.variasi[index]?.komisi_reseller : 0);
    // setVariasiSelected((data.variasi && data.variasi[index]) || {});
  }
  render() {
    const { data } = this.props;
    const { id, gambar, harga, harga_diskon, tipe, ukuran, stok, variasiSelected, jumlah } =
      this.state;

    return (
      <>
        <div
          className={
            "shop-fillter offer-filter offcanvas search-page offcanvas-bottom bg-white" +
            (this.props.panelShow ? " show" : "")
          }
          style={{
            boxShadow: "rgb(0 0 0 / 10%) 0px 0px 3px 1px",
            height: this.props.height || "auto",
          }}
        >
          <div className="offcanvas-header d-flex flex-column border-bottom p-0">
            <div className="w-100 d-flex">
              <div
                class="p-3 pointer"
                onClick={() => {
                  this.props.onClose();
                }}
              >
                <Cross width="20px" height="20px" />
              </div>
              <h4 className="title-color m-0 font-md fw-bold flex-fill w-100 text-start py-3">
                Pilih Varian Produk
              </h4>
            </div>
          </div>
          <div className="main-wrap offcanvas-body small bg-white  pb-5 px-3 pt-3 h-100">
            <div className="product-list media p-0">
              <a style={{ width: "120px" }}>
                <img
                  src={gambar}
                  className="img-fluid"
                  alt={data?.nama || ""}
                  // onError={() =>
                  //   this.setState({
                  //     gambar: require("../../assets/images/product/default.png"),
                  //   })
                  // }
                />
              </a>
              <div className="media-body">
                <a class="font-sm fw-bold text-limit-2rows mb-1">
                  {data?.nama || ""}
                </a>
                {harga_diskon && <div class="price m-0 font-xs text-secondary">
                  <span >Rp</span>&nbsp;
                  <del>
                    <NumberFormat
                      value={harga || 0}
                      displayType={"text"}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      
                    />
                  </del>
                </div>}
                <div
                  class="text-danger font-lg fw-bold mb-1 "
                  style={{ gap: "2px" }}
                >
                  <small className="font-xs">Rp</small>
                  <NumberFormat
                    value={harga_diskon || harga || 0}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator=","
                  />
                </div>
                <div className="text-dark font-sm" style={{ gap: "1px" }}>
                  {tipe}, {ukuran}
                </div>
                <div className="text-dark font-sm" style={{ gap: "1px" }}>
                  Stok: <span className="fw-bold">{stok}</span>
                </div>
              </div>
            </div>
            <section className="low-price-section  recent-search unmain-wrap mb-2 inside-wrap  px-2 py-3 bg-white">
              <div className="title-color mb-1 d-flex flex-column">
                <span className="font-sm text-dark fw-bold">Pilih Tipe</span>
                <ListBadgeHorizontal
                  data={data.tipe || []}
                  onChange={(value) =>
                    tipe !== value &&
                    value !== "" &&
                    value !== null &&
                    typeof value !== "undefined" &&
                    this.setState({ tipe: value }, () =>
                      this.handleInfoDetail()
                    )
                  }
                  default={tipe}
                />
              </div>
              <div className="title-color mb-1 d-flex flex-column">
                <span className="font-sm text-dark fw-bold">Pilih Ukuran</span>
                <ListBadgeHorizontal
                  data={data.ukuran || []}
                  default={ukuran}
                  onChange={(value) =>
                    ukuran !== value &&
                    value !== "" &&
                    value !== null &&
                    typeof value !== "undefined" &&
                    this.setState({ ukuran: value }, () =>
                      this.handleInfoDetail()
                    )
                  }
                />
              </div>
            </section>
          </div>
          
          <div className="offcanvas-footer border-top" style={    {boxShadow: '0rem -0.2rem 0.25rem rgba(0,0,0,.075)'}}>
            {this.props.counterShow && <div className="d-flex flex-row justify-content-between py-2 ">
              <div className="my-auto fw-bold font-md">Jumlah</div>
              <div>
                <BtnCounter
                  onChange={(count) => this.setState({ jumlah: count })}
                  max={stok}
                />
              </div>
            </div>}
            {this.props.counterShow && <div className="d-flex flex-row justify-content-between py-2 mb-2">
              <div className="my-auto fw-bold font-sm">Total Harga</div>
              <div className="text-danger font-sm fw-bold mb-1">
                <div
                  className="text-danger font-md fw-bold mb-1 "
                  style={{ gap: "2px" }}
                >
                  <small className="font-xs">Rp</small>
                  <NumberFormat
                    value={(harga_diskon || harga || 0) * jumlah}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator=","
                  />
                </div>
              </div>
            </div>}
            <div className="btn-box">
              <button
                className="btn btn-primary py-2 font-sm  w-50"
                onClick={() => {
                  this.props.onChange({
                    ...variasiSelected,
                    ...{ jumlah: jumlah },
                  });
                  this.props.onClose();
                }}
              >
                {this.props.buttonTitle || "Pilih"}
              </button>
            </div>
          </div>
        </div>
        {this.props.panelShow ? <Overlay /> : ""}
      </>
    );
  }
}

export default ModalPilihVariasiProduk;
