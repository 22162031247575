import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ArrowLeft,Cart } from "../../components/mikro/Icon";
import SearchBox from "../../components/mikro/SearchBox";

const HeaderDetailProduk = (props) => {
  const {kodeToko} = useParams();
  const navigate = useNavigate()
  return (
    <div className="header-wrap">
      <header className="header bg-white border-bottom">
        <div
          className="logo-wrap d-flex"
          style={{ width: "-webkit-fill-available" }}
        >
          <Link to={"/"+kodeToko}>
            <div className="avatar-wrap">
              <span className="font-xl p-1">
                <ArrowLeft width="24px" height="24px" />
              </span>
            </div>
          </Link>
          <SearchBox
            readOnly={false}
            value=""
            onChange={(search) => {}}
            onEnter={(search) => navigate("/"+kodeToko+"/search?q="+search)}
          />
          <Link to={"/"+kodeToko+"/keranjang"} className="text-dark px-3">
            <i className="font-sm">
              <Cart width={"24px"} height={"24px"} />
            </i>
          </Link>
        </div>
      </header>
    </div>
  );
};

export default HeaderDetailProduk;
