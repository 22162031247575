import React, { Component } from "react";

import api, { getList } from "../../../../utils/api";
import { withRouter } from "../../../../utils/WithRouter";
import { withSnackbar } from "react-simple-snackbar";
import Banner from "../../../../components/mikro/Banner";
import CountDown from "../../../../components/makro/CountDown";
import CopyToClipboard from "react-copy-to-clipboard";
import Accordion from 'react-bootstrap/Accordion';
import Footer from "./Footer";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { action_transaksi_check_status_payment } from "../../../../store/action/transaksi_action";
import {Sudah_Bayar} from '../../../../enums/status_transaksi'
import { Link, Navigate } from "react-router-dom";
import ImageFrame from "../../../../components/mikro/ImageFrame";
import Header1 from "../../../../parts/app/Header1";
import { ArrowLeft, Cross } from "../../../../components/mikro/Icon";
var intervalId;
class KuponDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idTransaksi: props.params.idTransaksi,
      copied_nomor_rekening: false,
      copied_jumlah: false,
      payment:{},
      navigateTo:"",
    };
  }
  componentDidMount() {
    this.getDetail();
    let self = this
    intervalId = setInterval(() => {
      this.props.reduxTransaksiCheckStatusPayment(this.props.params.idTransaksi,function({status, redirectLink}){
        if(status === Sudah_Bayar)
        self.setState({
          navigateTo: redirectLink
        });
      });
    }, 15000);
  }

  componentWillUnmount(){
    clearInterval(intervalId);
  }

  getDetail(){
    const {idTransaksi} = this.state
    getList(`${this.props.params.kodeToko}/payment/${idTransaksi}`)
      .then((res) => {
        if (res.code === 200) {
          this.setState({
            payment: res.data
          });
        }
      });
  }
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleClickCopiedNomorRekening(){
    this.setState({
      copied_nomor_rekening: true,
    });

    setTimeout(()=>{
      this.setState({
        copied_nomor_rekening: false,
      });
    },3000)
  }

  handleClickCopiedJumlah(){
    this.setState({
      copied_jumlah: true,
    });

    setTimeout(()=>{
      this.setState({
        copied_jumlah: false,
      });
    },3000)
  }
  
  render() {
    const {copied_nomor_rekening,copied_jumlah,payment,navigateTo} = this.state
    if(navigateTo !== "")
    return <Navigate to={navigateTo}/>
    return (
      <div
        className="bg-white"
        style={{ paddingTop:"56px" }}
      >
        <Header1 linkLeft={(<Link to={"/transaksi"}>
          <div class="avatar-wrap pointer">
            <span class="font-xl p-1">
              <Cross width="24px" height="24px" />
            </span>
          </div>
        </Link>)} 
        title={"Detail Pembayaran"}
      />
        <main class="main-wrap py-2 px-0">
          <section className="px-4 mb-2" >
            <div className="mb-4">
              <p className="m-0"><small class="text-muted">Order Code #{payment.kode_transaksi} </small></p>
              <h4 className="mb-1">Mohon transfer ke rekening Malmora </h4>
            </div>
            <div className="card mb-4">
              <div className="card-body">
                <div className="d-flex flex-row w-100 gap-3 mb-4">
                  <div style={{ width:"50px", height:"50px"}}>
                    <ImageFrame style={{ width:"50px", height:"50px"}} className="rounded" src="https://api.kreatipay.malmora.com/images/default/bank_icon.png"/>
                  </div>
                  <div  style={{ width:"85%"}} className="d-flex flex-column gap-2">
                    <h3 className="fw-bold">Bank {payment?.kode_bank?.toUpperCase()}</h3>
                    <h5>{payment?.pemilik_rekening || '-'}</h5>
                  </div>
                </div>
                <h5 className="mb-2">Nomor Rekening</h5>
                <div className="mb-4 d-flex flex-row justify-content-between align-items-center border-bottom px-3  py-2 rounded" style={{ backgroundColor:"#dbdbdb" }}>
                  <label className="fw-bold font-lg text-dark">{payment?.nomor_rekening || '-'}</label>
                  <div className="ps-3 font-md fw-bold text-primary align-self-end pointer">
                    <CopyToClipboard text={payment?.nomor_rekening || '-'}>
                      <div className={"btn rounded btn-light fw-bold " + ( !copied_nomor_rekening ? "text-primary" : "text-success")}  onClick={()=>this.handleClickCopiedNomorRekening()}>
                        {!copied_nomor_rekening ? "Salin" : "Disalin"}
                      </div>    
                    </CopyToClipboard>
                  </div>
                </div>

                <h5 className="mb-2">Jumlah Transfer</h5>
                <div className="d-flex flex-row justify-content-between align-items-center border-bottom px-3  py-2 rounded" style={{ backgroundColor:"#dbdbdb" }}>
                  <label className="fw-bold font-lg text-dark">  
                  
                  <NumberFormat
                    className="fw-bold"
                    value={payment?.total_pembayaran || 0}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator=","
                    prefix="Rp"
                  /> 
                  </label>
                  <div className="ps-3 font-md fw-bold text-primary align-self-end pointer">
                    <CopyToClipboard text={payment?.total_pembayaran}>
                      <div className={"btn rounded btn-light fw-bold " + ( !copied_jumlah ? "text-primary" : "text-success")}  onClick={()=>this.handleClickCopiedJumlah()}>
                        {!copied_jumlah ? "Salin" : "Disalin"}
                      </div>    
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div class="d-flex flex-column w-100  gap-2 ">
                  <div className="text-center">Sisa Waktu Pembayaran</div>
                  <div className="w-100 d-flex justify-content-center"><CountDown  seconds={payment.sisa_waktu_pembayaran}/></div>
                </div>

              </div>
            </div>
          </section>
          <section className=" check-delivery-section low-price-section mb-2 inside-wrap bg-white">
            <div className="p-2 font-sm fw-500 text-dark">
              <div className="font-sm fw-bold text-dark py-2 d-flex flex-row gap-2 text-primary">
                <div className="align-self-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-question-circle-fill" viewBox="0 0 16 16">
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z"/>
                </svg>
                </div>
                <div className="align-self-center">Cara Bayar ?</div>
              </div>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>ATM</Accordion.Header>
                  <Accordion.Body>
                    <ol>
                      <li>
                      <div>Pilih <strong>menu lain</strong> pada menu utama.</div>
                    </li>
                    <li>
                      <div>Pilih <strong>transfer</strong>.</div>
                    </li>
                    <li>
                      <div>Pilih <strong>ke rekening {payment?.kode_bank?.toUpperCase()}</strong>.</div>
                    </li>
                    <li>
                      <div>Masukkan <strong>nomor rekening pembayaran</strong>.</div>
                    </li>
                    <li>
                      <div>Masukkan <strong>jumlah yang akan dibayar</strong>, lalu <strong>konfirmasi</strong>.</div>
                    </li>
                    <li>
                      <div>Pembayaran selesai.</div>
                    </li>
                    </ol>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Internet Banking</Accordion.Header>
                  <Accordion.Body>
                    <ol>
                      <li>
                      <div>Pilih <strong>transaksi</strong>, lalu <strong>info &amp; administrasi transfer</strong>.</div>
                    </li>
                    <li>
                      <div>Pilih <strong>atur rekening tujuan</strong>.</div>
                    </li>
                    <li>
                      <div>Masukkan <strong>informasi rekening</strong>, lalu <strong>konfirmasi</strong>.</div>
                    </li>
                    <li>
                      <div>Pilih <strong>transfer<strong>, lalu <strong>transfer ke rekening {payment?.kode_bank?.toUpperCase()}</strong>.</strong>
                    </strong>
                    </div>
                    </li>
                    <li>
                      <div>Masukkan <strong>detail pembayaran</strong>, lalu <strong>konfirmasi</strong>.</div>
                    </li>
                    <li>
                      <div>Pembayaran selesai.</div>
                    </li>
                    </ol>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                  <Accordion.Header>Mobile Banking</Accordion.Header>
                  <Accordion.Body>
                    <ol>
                      <li>
                      <div>Pilih <strong>transfer</strong>.</div>
                    </li>
                    <li>
                      <div>Pilih <strong>transfer ke bank {payment?.kode_bank?.toUpperCase()}</strong>.</div>
                    </li>
                    <li>
                      <div>Pilih <strong>rekening debit</strong> yang akan digunakan.</div>
                    </li>
                    <li>
                      <div>Masukkan <strong>nomor rekening diatas</strong>, lalu <strong>konfirmasi</strong>.</div>
                    </li>
                    <li>
                      <div>Pembayaran selesai.</div>
                    </li>
                    </ol>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <br/>
            
          </section>
        </main>
        <Footer/>
      </div>
    );
  }
}
const reduxState = (state) => ({
  isLoading: state.isLoading,
});

const reduxDispatch = (dispatch) => ({
  reduxTransaksiCheckStatusPayment: (transaksiId, callback) => dispatch(action_transaksi_check_status_payment(transaksiId,callback)),
});

export default connect(reduxState, reduxDispatch)(withRouter(
  withSnackbar(KuponDetail, {
    style: {
      zIndex: 100,
    },
  })
));
