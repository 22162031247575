import React, { Component, Fragment } from "react";
import Header1 from "../../../parts/app/Header1";
import { Link } from "react-router-dom";
import { Store, Email } from "./../../../components/mikro/Icon";

import CardNavigationList from "../../../components/makro/CardNavigationList";
import { getAuth } from "../../../utils/authUtils";
import { PAGE_TITLE } from "../../../enums/aplikasi";
import { connect } from "react-redux";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = { nama: "", nomor_hp: "" };
  }
  componentDidMount() {
    this.setState(getAuth());
  }

  render() {
    const { email, gambar, nama, nomor_hp } = this.state;
    return (
      <div
        style={{
          paddingTop: "56px",
          minHeight:"calc(100vH - 56px)",
          backgroundColor: "#eeeeee",
        }}
      >
        <Header1
          title="Akun"
          linkLeft={<div></div>}
          linkNotification={true}
          linkCart={true}
        />
        <main
          class="main-wrap account-page"
          style={{ paddingBottom: "65px" }}
        >
          <div className="account-wrap section-b-t pt-3">
            {nomor_hp && <div className="user-panel bg-white">
              <div className="media">
                <div className="media-body title-color d-flex flex-column">
                  <div className="fw-bold font-md mb-1">{nama || 'User'}</div>
                    <span class="content-color font-sm mb-1">{nomor_hp}</span>
                </div>
              </div>
            </div>}
            <CardNavigationList
              title={"Profil Saya"}
              data={[
                { title: "Edit Profil", to: "/akun/profil" },
              ]}
            />
            
            <CardNavigationList
              title={"Transaksi"}
              data={[
                { title: "Alamat", to: "/akun/alamat" },
                { title: "Transaksi Saya", to: "/transaksi-saya" },
              ]}
            />

            { nomor_hp && <CardNavigationList
              title={"Keamanan"}
              data={[
                { title: "Keluar", to: "/akun/keluar" },
              ]}
            />}
          </div>
        </main>
      </div>
    );
  }
}



const reduxState = (state) => ({
  toko: state.toko,
  isLoading: state.isLoading,
});


export default connect(reduxState, null)(index);


