import React from "react";

const Loading = () => {
  return (
    <div>
      <div className="modal-backdrop fade show mx-auto d-flex justify-content-center" style={{
        background:"hsla(0,0%,100%,.5)"
      }}>
        <h5 style={{ textAlign: "center" }} className="my-auto text-white">
          Loading...
        </h5>
      </div>
    </div>
  );
};

export default Loading;
