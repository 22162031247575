import React, { Component } from "react";
import ModalOptionsList from "../../../components/makro/ModalOptionsList";
import FooterButton from "../../../components/mikro/FooterButton";
import LabelForm from "../../../components/mikro/LabelForm";
import Header1 from "../../../parts/app/Header1";
import  { addItem, deleteItem, getList } from "../../../utils/api";
import { withRouter } from "../../../utils/WithRouter";
import { withSnackbar } from "react-simple-snackbar";
import { Navigate } from "react-router-dom";
import Loading from "../../../components/mikro/Loading";
import ModalConfirm from "../../../components/makro/ModalConfirm";

class FormAlamat extends Component {
  state = {
    page: this.props?.params?.id ? "Edit" : "Tambah",
    id: this.props?.params?.id,
    panelShow: { lokasi: false, confirm: false },
    data: {
      judul: "",
      nama_penerima: "",
      nomor_hp: "",
      kecamatan_id: "",
      wilayah_label: "",
      alamat: "",
    },
    navigateTo: "",
    isLoading: false,
  };

  componentDidMount() {
    if (this.state.page === "Edit") {
      this.setState({ isLoading: true },()=>this.getDetailAlamat());
    }
  }
  getDetailAlamat(){
    getList(this.props?.params?.kodeToko+"/alamat/" + this.state.id).then((res) => {
        if (res.code === 200) {
          this.setState({
            data: {
              judul: res?.data?.judul,
              nama_penerima: res?.data?.nama_penerima,
              nomor_hp: res?.data?.nomor_hp,
              kecamatan_id: res?.data?.kecamatan_id,
              wilayah_label: res?.data?.wilayah_label,
              alamat: res?.data?.alamat,
            },
            isLoading: false,
          });
        }
      });
  }
  handleChange(e) {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  }
  handleChangeLokasi(v) {
    this.setState({
      data: {
        ...this.state.data,
        kecamatan_id: v.subdistrict_id || 0,
        wilayah_label: v.label || "",
      },
    });
  }
  handleSimpan(e) {
    e.preventDefault();
    const { data } = this.state;
    this.setState({ isLoading: true });
    const arrParams = {
      judul: data.judul,
      nama_penerima: data.nama_penerima,
      nomor_hp: data.nomor_hp,
      kecamatan_id: data.kecamatan_id,
      alamat: data.alamat,
    };

    addItem(
        this.props?.params?.kodeToko+"/alamat" +
          (this.state.page === "Edit" ? "/" + this.state.id :""),
        this.state.page === "Edit"
          ? { ...arrParams, _method: "put" }
          : arrParams
      )
      .then((res) => {
        if (res.code === 200) {
          this.props.openSnackbar(res.message, 2000);
          setTimeout(
            () =>
              this.setState({ navigateTo: "/"+this.props.params.kodeToko+"/akun/alamat", isLoading: false }),
            2000
          );
        } else {
          this.props.openSnackbar(res.message, 10000);
          this.setState({ isLoading: false });
        }
      });
  }
  
  handleHapus() {
    if (this.state.page === "Edit") {
      this.setState({ isLoading: true },()=>{
        deleteItem(this.props.params.kodeToko+"/alamat",this.state.id)
        .then((res) => {
          if (res.code === 200) {
            this.props.openSnackbar(res.message, 2000);
            setTimeout(
              () =>
              this.setState({ navigateTo: "/"+this.props.params.kodeToko+"/akun/alamat", isLoading: false }),
              2000
            );
          } else {
            this.props.openSnackbar(res.message, 10000);
            this.setState({ isLoading: false });
          }
        })
        .catch(function (error) {
          this.setState({ isLoading: false });
        });
      });
        
    }
  }
  render() {
    const { page, panelShow, navigateTo, isLoading } = this.state;
    const { judul, nama_penerima, nomor_hp, wilayah_label, alamat } =
      this.state.data;
    return navigateTo !== "" ? (
      <Navigate to={navigateTo} />
    ) : (
      <div
        style={{
          paddingTop: "56px",
        }}
      >
        {isLoading ? <Loading /> : ""}
        <Header1
          title={page + " Alamat"}
          linkBackTo={'/'+this.props.params.kodeToko+'/akun/alamat'}
        />
        <main
          class="main-wrap setting-page pt-4  px-4 custom-form"
          style={{ paddingBottom: "3rem", position: "relative" }}
        >
          <form onSubmit={(e) => this.handleSimpan(e)}>
            <LabelForm label="Judul Alamat" isRequired={false} />
            <div className="input-box text-start">
              <input
                type="text"
                name="judul"
                placeholder="Rumah Pak Budi"
                required
                className="form-control"
                value={judul}
                onChange={(e) => this.handleChange(e)}
              />
            </div>
            <LabelForm label="Nama Penerima" isRequired={false} />
            <div className="input-box text-start">
              <input
                type="text"
                name="nama_penerima"
                placeholder="Bapak Budi Sutomo"
                required
                className="form-control"
                value={nama_penerima}
                onChange={(e) => this.handleChange(e)}
              />
              <i className="iconly-Profile icli"></i>
            </div>

            <LabelForm label="Nomor HP Penerima" isRequired={false} />
            <div className="input-group mb-3 input-group-lg">
              <span class="input-group-text font-sm" id="basic-addon1">
                +62
              </span>
              <input
                type="number"
                name="nomor_hp"
                placeholder="81234567890"
                required
                className="form-control py-2"
                value={nomor_hp}
                onChange={(e) => this.handleChange(e)}
              />
              <span class="input-group-text">
                <i className="iconly-Call icli"></i>
              </span>
            </div>

            <LabelForm label="Alamat " isRequired={false} />
            <div
              className="input-box mb-4"
              onClick={() =>
                this.setState({ panelShow: { ...panelShow, lokasi: true } })
              }
            >
              <textarea
                name="alamat"
                placeholder="Ketik Alamat Penerima"
                required
                className="form-control pointer"
                value={wilayah_label || ""}
              ></textarea>
              <i className="iconly-Location icli"></i>
            </div>

            <LabelForm label="Rincian Alamat" isRequired={false} />
            <div className="input-box mb-4">
              <textarea
                placeholder="Contoh: Nama Jalan, RT/RW, Kelurahan, Dll."
                required
                className="form-control"
                name="alamat"
                value={alamat}
                onChange={(e) => this.handleChange(e)}
              ></textarea>
              <i className="iconly-Location icli"></i>
            </div>

            {page === "Edit" ? (
              <div
                className="btn py-2 btn-outline-danger w-100 mb-5"
                onClick={() =>
                  this.setState({ panelShow: { ...panelShow, confirm: true } })
                }
              >
                Hapus Alamat
              </div>
            ) : (
              ""
            )}
            <FooterButton title="Simpan" buttonType="submit" />
          </form>
        </main>
        <ModalOptionsList
          onRef={(ref) => (this.refModal = ref)}
          url={this.props.params.kodeToko+"/cek-ongkos-kirim/wilayah"}
          panelShow={panelShow.lokasi}
          isSingleValue={true}
          title={"Lokasi Detail"}
          height={"80%"}
          onChangeValue={(e) => this.handleChangeLokasi(e)}
          onClose={() =>
            this.setState({ panelShow: { ...panelShow, lokasi: false } })
          }
        />
        <ModalConfirm
          title="Konfirmasi"
          content={
            <p className="font-md">
              Apakah anda yakin akan menghapus alamat ini?
            </p>
          }
          panelShow={panelShow.confirm}
          onClose={() =>
            this.setState({ panelShow: { ...panelShow, confirm: false } })
          }
          onConfirm={() =>
            this.setState({ panelShow: { ...panelShow, confirm: false } }, () =>
              this.handleHapus()
            )
          }
        />
      </div>
    );
  }
}

export default withRouter(
  withSnackbar(FormAlamat, {
    style: {
      zIndex: 100,
    },
  })
);
