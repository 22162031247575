import React from "react";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";

const CardBrand = ({ data }) => {
  return (
    <Link to={data.linkTo}>
      <div className="product-card mb-2" style={{
        minHeight:'unset'
      }}>
        <div
          className=""
          style={{
            background: '#dedede center/cover url('+data?.gambar+') no-repeat',
            width: 'auto',
            height: '100px',
            WebkitBackgroundSize: 'cover',
            MozBackgroundSize: 'cover',
            OBackgroundSize: 'cover',
            backgroundSize: 'cover',
          }}
        ></div>
        <div className="content-wrap">
          <div className="font-xs fw-bold title-color mb-1 text-limit-2rows">
            {data?.nama}
          </div>
         
          {data?.harga && (
            <div className="title-color font-xs text-secondary">Mulai Dari</div>
          )}
          {data?.harga && (
            <div className="title-color font-md  fw-bold text-danger mb-1">
              <NumberFormat
                value={data?.harga}
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator=","
                prefix={"Rp"}
              />
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};

export default CardBrand;
