import React from "react";
const SectionInformasi = ({ data }) => {
  return (
    <section className=" mb-2 product-section py-2 border-top">
      <div className="d-flex flex-column w-100  py-1">
        
        <div className="d-flex gap-1 mb-3">
          {/* <div>
            <Star width={"16"} height={"16"}/>

           <span className="font-xs content-color pt-1">
              {data.rating }
              {"/" + data.ulasan_pembeli_count}
            </span>
          </div> */}
          <div>
            {data?.bisnis?.lokasi_kota && 
              <span
                className={
                  "font-xs content-color " +
                  (data.rating ? " ps-1 " : " ms-0 ") +
                  " pt-1"
                }
              >
                <i className="iconly-Location icli font-xs"></i>{" "}
                {data?.bisnis?.lokasi_kota || ""}
              </span>}
          </div>
        </div>
        <h1 className="font-md mb-3">{data?.nama || "Unknown"}</h1>
      </div>
    </section>
  );
};

export default SectionInformasi;
