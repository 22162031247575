import React, { Component, useState } from "react";
import { Cross } from "../../components/mikro/Icon";
import { APLIKASI_TYPE } from "../../enums/aplikasi";
import LabelForm from "../../components/mikro/LabelForm";
import { useDispatch } from "react-redux";
import {action_transaksi_set_pesanan_komplain} from './../../store/action/transaksi_action'
import {waComplain} from './../../utils/LinkUrl'
import { useParams } from "react-router-dom";
const ModalKomplain = ({data,...props}) => {
  const [message, set_message] = useState("")
  const dispatch = useDispatch()
  const {kodeToko} = useParams()

  const handleComplain = () =>{
    dispatch(action_transaksi_set_pesanan_komplain(kodeToko, data?.transaksiId, data?.pesananId, (res)=>{
      waComplain(message, data.wa)
      props.onClose();
    }))
  }
  return (
      <>
        <div
          className={
            "shop-fillter offer-filter offcanvas offcanvas-bottom bg-white" +
            (props.panelShow ? " show" : "")
          }
          style={{
            boxShadow: "rgb(0 0 0 / 10%) 0px 0px 3px 1px",
            height: props.height || "auto",
          }}
        >
          <div className="offcanvas-header d-flex flex-column border-bottom p-0">
            <div className="w-100 d-flex">
              <div
                class="p-3 pointer"
                onClick={() => props.onClose()}
              >
                <Cross width="20px" height="20px" />
              </div>
              <h4 class="title-color m-0 font-md fw-bold flex-fill w-100 text-start py-3">
                Komplain Pesanan
              </h4>
            </div>
          </div>
          <div
            className="main-wrap offcanvas-body small py-3 bg-white custom-form"
            style={{
              height: props.height || "auto",
            }}
          >
           <LabelForm label="Ketikkan Komplain Anda" isRequired={true} />
            <div className="input-box mb-4">
              <textarea
                placeholder="Contoh: Produk rusak"
                required
                className="form-control"
                value={message}
                onChange={(e) => set_message(e.target.value)}
              ></textarea>
              <i className="iconly-Message icli"></i>
            </div>
          </div>
          <div className="offcanvas-footer">
            <div className="btn-box">
              <button
                className={
                  "btn btn-primary py-2 font-sm  w-50 " +
                  ( message.length > 8 ? "" : "disabled")
                }
                onClick={() => handleComplain()}
              >
                Kirim Komplain ke {APLIKASI_TYPE.malmora}
              </button>
            </div>
          </div>
        </div>

      </>
    );
}

export default ModalKomplain;
