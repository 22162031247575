import React, { Component } from "react";
import { useParams } from "react-router-dom";

import CardProduct from "../../../components/makro/CardProduct";

const  SectionProduk = ({items, hasMore}) => {
  const {kodeToko} = useParams()
  return <section className="low-price-section mb-2 py-2 inside-wrap">
    <div className="row px-1">
      {items.map((v, i) => (
        <div className="col-lg-3 col-md-4 col-sm-4 col-6 px-1 mb-1" key={i}>
          <CardProduct data={{...v, linkTo:`/${kodeToko}/p/${v.id}`}} />
        </div>
      ))}
    </div>
  </section>
}

export default SectionProduk
    