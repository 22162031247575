import React, { useState } from "react";
import NumberFormat from "react-number-format";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { ArrowRight } from "../../../components/mikro/Icon";
import { action_keranjang_edit_kupon } from "../../../store/action";
const SectionPromo = ({data}) => {
  const [kode, set_kode] = useState()
  const dispatch = useDispatch()
  const {kodeToko} = useParams()
  const handleRedeem = (e) => {
    dispatch(
      action_keranjang_edit_kupon({
        kode: kode,
      },kodeToko)
    );
  }
  return (
    <section className="my-1 product-section p-0 border-top">
      <div className="top-content d-flex justify-content-between inside-wrap pt-3 pb-2">
        <div className="font-sm text-dark fw-bold">Makin Hemat Pakai Promo</div>
      </div>
      <div className="d-flex w-100 py-3 flex-column inside-wrap border-top justify-content-between gap-2">
        <div className="flex-fill">
          <div className="input-group py-1">
            <input
              type="text"
              className="form-control font-sm py-2"
              placeholder="Masukkan Kode Promo"
              value={kode}
              onChange={(e) => set_kode(e.target.value)}
            />
            <div className="btn btn-outline-secondary" onClick={()=>handleRedeem()}>
              Gunakan
            </div>
          </div>
        </div>
        {data?.kupon && <div className="flex-fill card border rounded-pill">
          <div className="card-body d-flex align-items-center gap-2 px-3 py-1">
              <span style={{ fontSize:'2.5rem'}}>
                <i class="iconly-Discount icli text-success"></i>
              </span>
              <div class="media-body d-flex flex-column flex-fill">
                <div className="fw-bold">
                  Kamu hemat hingga <small>Rp</small>
                  <NumberFormat
                    value={data.total_diskon || 0}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator=","
                  />
                </div>
                <div class="content-color text-truncate">Promo {data?.kupon?.nama || ''}</div>
              </div>
          </div>
        </div>}
      </div>
    </section>
  );
};

export default SectionPromo;
