import ShopFilterOptionBtn from "../mikro/ShopFilterOptionBtn";
import React, { Component } from "react";
import ModalOptionsList from "./ModalOptionsList";

export default class ShopFilterSection extends Component {
  constructor(props) {
    super(props);
    this.state = { data: props.data, panelShow: false };
  }
  componentDidMount() {
    this.props.onRef(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data.length !== this.props.data.length) {
      let arr = [];
      this.props.data.forEach((v) => {
        arr.push(v);
      });
      this.setState({ data: arr });
    }
  }

  componentWillUnmount() {
    this.props.onRef(null);
  }
  handleReset() {
    let arr = [];
    this.props.data.forEach((v) => {
      arr.push({ ...v, active: false });
    });
    this.setState({ data: arr, panelShow: false }, () => {
      this.refModal.handleReset();
    });
  }
  handleClick(value) {
    let { data } = this.state;

    let val = null;
    //kekurangannya adalah ketika di non aktifkan dengan cara di klik maka masih tetap menyimpan data sebelumnya
    // solusinya harus mendeteksi data sebelumnya aktif atau tidak
    if (this.props.multiple === false) {
      const i = data.findIndex((v) => v.title === value.value);

      if (data[i].active === false) {
        for (let index = 0; index < data.length; index++) {
          data[index].active = i === index ? true : false;
          if (i === index) val = data[index];
        }
      } else {
        data[i].active = false;
        val = data[i];
      }
    } else {
      const i = data.findIndex((v) => v.title === value.value);
      data[i].active = value.active;

      val = [];
      for (let index = 0; index < data.length; index++) {
        if (data[index].active === true) val.push(data[index].title);
      }
    }
    this.setState({ data: data }, () => this.props.onChangeValue(val));
  }

  handleModalValue(val) {
    let arr = [];
    val.forEach((v) => {
      arr.push({ title: v, active: true });
    });

    this.setState({ data: arr.length > 0 ? arr : this.props.data }, () => {
      this.props.onChangeValue(val);
    });
  }

  render() {
    const { title, seeAll } = this.props;
    const { data, panelShow } = this.state;

    return (
      <>
        <section className=" check-delivery-section low-price-section unmain-wrap mb-2 inside-wrap pt-3  bg-white">
          <div className="top-content mb-2">
            <div>
              <h4 className="title-color m-0 font-sm">{title}</h4>
            </div>
            <div className="pointer">
              {seeAll && (
                <div onClick={() => this.setState({ panelShow: true })}>
                  <a className="font-xs font-theme">Lihat Semua </a> &nbsp;
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20px"
                    height="20px"
                    fill="currentColor"
                    className="bi bi-arrow-right-short fw-bold font-theme"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                    />
                  </svg>
                </div>
              )}
            </div>
          </div>
          <div className="service-section">
            {this.props.children}
            {data.map((v, i) => {
              return (
                <span key={i}>
                  <ShopFilterOptionBtn
                    title={v.title}
                    onClick={(v) => this.handleClick(v)}
                    value={v.active}
                  />
                </span>
              );
            })}
          </div>
        </section>

        <ModalOptionsList
          onRef={(ref) => (this.refModal = ref)}
          url={this.props.url}
          panelShow={panelShow}
          title={title}
          height={"100vH"}
          onChangeValue={(value) => this.handleModalValue(value)}
          onClose={() => this.setState({ panelShow: false })}
        />
      </>
    );
  }
}
