export const TAMBAH_PRODUK = "TAMBAH_PRODUK";
export const EDIT_PRODUK = "EDIT_PRODUK";
export const GET_KERANJANG = "GET_KERANJANG";
export const DELETE_PRODUK = "DELETE_PRODUK";

export const EDIT_ALAMAT = "EDIT_ALAMAT";

export const UPDATE_RESPONSE = "UPDATE_RESPONSE";
export const UPDATE_LOADING = "UPDATE_LOADING";
export const UPDATE_REDIRECT = "UPDATE_REDIRECT";


// POINT
export const SET_ALL_PROMO_POINT = "SET_ALL_PROMO_POINT";
export const SET_DATA_TOKO = "SET_DATA_TOKO";