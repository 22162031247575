export const APLIKASI_TYPE = {
    malmora: "Malmora" ,
    berberkah: "Berberkah" 
};
export const KURIR_TYPE = {
    REGULER: "REGULER" ,
    COD: "COD",
    EKSPEDISI_CUSTOM: "EKSPEDISI_CUSTOM" 
};
export const PAGE_TITLE = {
    kategori:"Kategori",
    toko_saya:"Toko Saya",
    transaksi_saya:"Transaksi Saya",
    toko:['Produk','Pengaturan']
};