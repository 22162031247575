import React from "react";
import { Link } from "react-router-dom";
export default function FooterButton({ title, ...props }) {
  return props.to ? (
    <Link to={props.to || "/"} className="font-md text-white" {...props}>
      <Footer title={title} />
    </Link>
  ) : (
    <button
      type={props.buttonType || "button"}
      className="font-md text-white"
      {...props}
    >
      <Footer title={title} />
    </button>
  );
}

function Footer({ title }) {
  return (
    <footer className="footer-wrap footer-button">{title || "Tombol"}</footer>
  );
}
