import React, { useEffect, useState } from "react";
import Image from "react-bootstrap/esm/Image";

export default function ImageFrame({ fallbackSrc, src, ...props }) {
  const [imgSrc, setImgSrc] = useState(src);
  const onError = () => setImgSrc(fallbackSrc);
  useEffect(() => {
    setImgSrc(src)
  },[src])
  return  <Image  src={imgSrc ? imgSrc : fallbackSrc} onError={onError} {...props}/>;
}
