import React, { Component } from "react";
import { Link } from "react-router-dom";

import SearchBox from "../../../components/mikro/SearchBox";
import { getList } from "../../../utils/api";
import { ArrowLeft } from "../../../components/mikro/Icon";
import { withRouter } from "../../../utils/WithRouter";
import { getAuth } from "../../../utils/authUtils";
import EmptyListPage from "../../../components/mikro/EmptyListPage";

class Alamat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      current_page: 0,
      last_page: 0,
      per_page: 15,
      total: 0,
      search: "",

      isLoading: false,
    };
  }

  
  componentDidMount(){
    this.getAlamat();
    this.refs.iScrollAlamat.addEventListener("scroll", () => {
      if(this.refs.iScrollAlamat.scrollTop % this.state.per_page === 0)
        this.setState({
          scrollNumber:this.refs.iScrollAlamat.scrollTop
        });
      if (this.refs.iScrollAlamat.scrollTop + this.refs.iScrollAlamat.clientHeight >= this.refs.iScrollAlamat.scrollHeight - 100){
        this.getAlamat();
      }
    });
  }

  getAlamat() {
    const { items, current_page, search,isLoading,per_page } = this.state;

    if(items.length % per_page === 0 && isLoading === false)
      this.setState({ isLoading:true},
        ()=>{
        getList(this.props?.params?.kodeToko+"/alamat", {
          page: current_page + 1,
          search: search,
        })
        .then((res) => {
          if (res.code === 200) {
            let { items } = this.state;
            this.setState({
              items: current_page + 1  === 1 ? res?.data : items.concat(res?.data),
              current_page: res?.meta?.current_page || 0,
              last_page: res?.meta?.last_page || 0,
              per_page: res?.meta?.per_page || 0,
              total: res?.meta?.total || 0,
              isLoading: false,
            });
          }
        });
      });
  }
  handleReset() {
    this.setState({
      items: [],
      current_page: 0,
      last_page: 0,
      per_page: 15,
      total: 0,
    },()=>this.getAlamat());
  }
  handleSearch(search) {
    this.setState({ search: search, isLoading: false }, () => this.handleReset());
  }

  render() {
    return (
      <>
        <div
          className="shop-fillter offer-filter offcanvas offcanvas-bottom bg-white show"
          style={{
            height: "100%",
            boxShadow: "rgb(0 0 0 / 10%) 0px 0px 3px 1px",
          }}
        >
          <div className="offcanvas-header d-flex flex-column border-bottom p-0">
            <div className="w-100 d-flex ">
              <Link className="p-3" to={"/"+this.props?.params?.kodeToko+"/akun"}>
                <ArrowLeft width="24px" height="24px" />
              </Link>
              <h4 className="title-color m-0 font-md fw-bold flex-fill w-100 text-start py-3">
                Daftar Alamat Pengiriman
              </h4>
            </div>
            <div className="w-100 pb-2 px-3 ">
              <SearchBox
                placeholder={"Cari Alamat Pengiriman"}
                readOnly={false}
                onChange={(v) => this.handleSearch(v)}
                onEnter={(e)=>{}}

              />
            </div>
          </div>
          
          <div
            className={"main-wrap offcanvas-body small bg-white px-0 scrollable-hiddenbar position-relative "+(this.state.items.length === 0 && "d-flex justify-content-center align-items-center")}
            style={{
              height: "100%",
            }}

            ref="iScrollAlamat"

          >
            {this.state.items.length === 0 && <EmptyListPage/>}
            {this.state.items.length > 0 && <ul className="list-group list-group-flush prevent-select order-history pt-1  w-100">
              {this.state.items.map((v, i) => (
                <li
                  className="list-group-item d-flex justify-content-between align-items-center py-2  border-0"
                  key={i}
                  onClick={() => this.handleClick(v, i)}
                >
                  <div className="order-box w-100 border">
                    <div className="media  mb-2">
                      <div className="content-box flex-fill">
                        <div className="font-xs text-dark fw-bold">
                          {v.judul}
                        </div>
                        <div className="font-md text-dark fw-bold">
                          {v.nama_penerima}
                        </div>
                        <div className="font-xs text-dark fw-500">
                          HP: {v.nomor_hp}
                        </div>
                        <div className="font-xs text-dark fw-500">
                          {v.label_alamat}
                        </div>
                      </div>
                    </div>
                    <div className="media">
                      <Link
                        to={"/"+this.props?.params?.kodeToko+"/akun/alamat/" + v.id + "/ubah"}
                        className="btn btn-sm btn-outline-primary flex-fill"
                      >
                        Ubah Alamat
                      </Link>
                    </div>
                  </div>
                </li>
              ))}
            </ul>}
          </div>
          <div className="offcanvas-footer">
            <Link to={"/"+this.props?.params?.kodeToko+"/akun/alamat/tambah-alamat"}>
              <div className="btn-box">
                <button
                  className="btn-solid font-md"
                >
                  Tambah Alamat Baru
                </button>
              </div>
            </Link>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(Alamat);