import React from 'react';
import Button from 'react-bootstrap/Button';
import { useNavigate, useParams } from 'react-router-dom';

function Footer() {
    const navigate = useNavigate();
  const {kodeToko} = useParams()

    return <footer className="footer-wrap p-0 border-top prevent-select bg-white">
          <div className="d-grid p-2">
            <Button variant="primary" className="font-sm py-2 w-100"
                onClick={() =>
                    navigate("/"+kodeToko+"/transaksi/pembayaran/selesai")
                }
            >Saya telah melakukan pembayaran</Button>
          </div>
        </footer>;
}

export default Footer;