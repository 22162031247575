import React from 'react'
import { useSelector } from 'react-redux';

export default function CardContentHeader() {
  const toko = useSelector((state) => state.toko)
  return (
    <div className="w-100 d-flex bg-page justify-content-center  position-relative py-0" style={{
      backgroundRepeat:'no-repeat',
      backgroundPosition: 'center ',
      backgroundSize:'cover',
      height:'300px',
      width:'100%',
      backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url("+(toko.gambar || "")+")",
    }}>
      <div className="p-2 align-middle" style={{
        margin: 0,
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)'
      }}>
        <div className="fw-bold font-md text-white mb-1" style={{
          textShadow: '1px 1px 1px #000, 3px 3px 5px #000',
        }}>{toko.nama}</div>
      </div>
    </div>
  )
}
