import { Navigate, Outlet } from "react-router";
import AuthUser from "../utils/AuthUser";
import {useSelector} from 'react-redux';

const useAuth = () => {
  const { getUser } = AuthUser();
  return getUser() && getUser().nomor_hp ? true : false;
};

const ProtectedRoute = () => {
  
  const toko = useSelector(state=>state.toko);
  const isAuth = useAuth();
  return isAuth ? <Outlet /> : <Navigate to={"/"+toko?.url+"/login"} />;
};

export default ProtectedRoute;
