import React, { Component, useEffect, useState,  } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import Header1 from "../../../../parts/app/Header1";
import CardNavigationList from "../../../../components/makro/CardNavigationList";
import {getList} from "../../../../utils/api";
import { Cross } from "../../../../components/mikro/Icon";
import { action_transaksi_charge_payment } from "../../../../store/action/transaksi_action";

function PilihPembayaran (props){

  const navigate = useNavigate();

  const [bank, setBank] = useState([]);

  const {kodeToko, idTransaksi} = useParams();
  const dispatch = useDispatch();

  useEffect(()=>{
    checkIdValid();
  },[]);

  const checkIdValid = () => {
    getList(kodeToko+"/transaksi/"+idTransaksi+"/status")
      .then((res) => {
        if (res.status === "SUCCESS" && res?.data?.id === idTransaksi ) {
          getBanks();
        }
      });
  }

  const getBanks = () => {
    getList(kodeToko+"/payment/bank")
      .then((res) => {
        if (res.code === 200) {
          let listBank = [];
          res.data.forEach(d => {
            listBank.push({ 
              title: d.nama, 
              code:d.code,
              icon:d.img, 
              description:"ATM, Internet Banking, & m-Banking", 
            });
          });
          setBank(listBank);
        }
      });
  }

  const handleChargePayment = (data) => {
    dispatch(action_transaksi_charge_payment(kodeToko,{kode_bank:data.code, transaksi_id: idTransaksi }, (link)=>{
      navigate(link);
    }));
  }

  return (
    <div
      style={{
        paddingTop: "56px",
        minHeight:"calc(100vh-56px)"
      }}
    >
      <Header1 linkLeft={
        (<Link to={'/'+kodeToko+"/transaksi/pembayaran/selesai"}>
          <div className="avatar-wrap pointer">
            <span class="font-xl p-1">
              <Cross width="24px" height="24px" />
            </span>
          </div>
        </Link>)
      } title={"Pilih Pembayaran"} />
      <main
        class="main-wrap account-page"
        style={{ backgroundColor: "#f8f8f8", paddingBottom: "65px", minHeight:'100vH' }}
      >
        <div className="account-wrap section-b-t">
          {bank.length > 0 ? <CardNavigationList
            title="Transfer Bank"
            classTitle="fw-bold text-sm"
            description="Transfer pembayaran Anda melalui salah satu bank dibawah ini. Jangan lupa melakukan konfirmasi setelah mengirimkan dana."
            data={bank}
            onClick={(data)=>handleChargePayment(data)}
          /> : null}
        </div>
      </main>
    </div>
  );
}

export default PilihPembayaran;




