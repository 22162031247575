import React, { useEffect, useState, Component } from "react";
import { connect, useDispatch } from "react-redux";

import ListBadge from "../../../components/mikro/ListBadge";
import HeaderDetailProduct from "../../../parts/app/HeaderDetailProduct";
import SectionSlideProduct from "../../../components/makro/SectionSlideProduct";
import AuthUser from "../../../utils/AuthUser";

import NumberFormat from "react-number-format";
import ModalPilihVariasiProduk from "../../../parts/app/ModalPilihVariasiProduk";
import SectionSlide from "./SectionSlide";
import SectionHarga from "./SectionHarga";
import SectionInformasi from "./SectionInformasi";
import SectionUlasanPembeli from "./SectionUlasanPembeli";
import SectionDeskripsi from "./SectionDeskripsi";
import SectionFooter from "./SectionFooter";
import { Link, useNavigate, useParams } from "react-router-dom";
import { encodeDataToURL } from "../../../utils/LinkUrl";

import ModalAlamatPengiriman from "../../../parts/app/ModalAlamatPengiriman";
import ModalUlasanPembeli from "../../../parts/app/ModalUlasanPembeli";
import NotFound from "../../../components/makro/NotFound";
import { action_keranjang_add_produk } from "../../../store/action";
import { getList } from "../../../utils/api";
import { getAuth } from "../../../utils/authUtils";

function ProductDetail(props) {
  const navigate = useNavigate();
  const { http } = AuthUser();

  const {kodeToko, id} = useParams()

  const [status, setStatus] = useState(0);
  const [data, setData] = useState({});
  const [produkLainBySupplier, setProdukLainBySupplier] = useState([]);
  const [produkLainByKategori, setProdukLainByKategori] = useState([]);

  const [hargaReseller, setHargaReseller] = useState(0);
  const [hargaCustomer, setHargaCustomer] = useState(0);
  const [komisi, setKomisi] = useState(0);
  const [ukuranSelected, setUkuranSelected] = useState("");
  const [tipeSelected, setTipeSelected] = useState("");
  const [slider, setSlider] = useState([]);
  const [variasiSelected, setVariasiSelected] = useState({});
  const [jumlah, setJumlah] = useState(1);

  const [panelVariasiShow, setPanelVariasiShow] = useState(0);
  const [panelAlamatPengirimanShow, setPanelAlamatPengirimanShow] = useState(0);
  const [panelUlasanPembeliShow, setPanelUlasanPembeliShow] = useState(0);
  const [alamatSelected, setAlamatSelected] = useState({});

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });

    getList(`${kodeToko}/produk/${id}`).then((resp) => {
      if (
        resp.code === 200 &&
        resp.data.variasi &&
        resp.data.variasi.length > 0
      ) {
        setData(resp.data);
        setStatus(1);

        const { variasi } = resp.data;

        setHargaReseller(variasi[0]?.harga_reseller || 0);
        setHargaCustomer(variasi[0]?.harga_customer || 0);
        setKomisi(variasi[0]?.komisi_reseller || 0);

        setUkuranSelected(variasi[0]?.ukuran || "");
        setTipeSelected(variasi[0]?.tipe || "");

        let slider = [resp.data.gambar];
        if (
          typeof resp.data.slide !== "undefined" &&
          resp.data.slide.length > 0
        )
          resp.data.slide.forEach((e) => {
            slider = [...slider, e.gambar];
          });
        setSlider(slider);

      } else {
        setStatus(2);
      }
    });
  }, []);

  const dispatch = useDispatch();

  const handleSelectedVariasi = () => {
    let index = -1;
    if (typeof data.variasi !== "undefined" && data.variasi.length > 0)
      index = data.variasi.findIndex(
        (v) =>
          v.ukuran &&
          ukuranSelected &&
          v.tipe &&
          tipeSelected &&
          v.ukuran &&
          v.ukuran.toLowerCase() ===
            (ukuranSelected.length > 0 && ukuranSelected !== null
              ? ukuranSelected.toLowerCase()
              : "") &&
          v.tipe.toLowerCase() ===
            (tipeSelected.length > 0 && tipeSelected !== null
              ? tipeSelected.toLowerCase()
              : "")
      );

    if (index >= 0) {
      setHargaReseller(data.variasi ? data.variasi[index]?.harga_reseller : 0);
      setHargaCustomer(data.variasi ? data.variasi[index]?.harga_customer : 0);
      setKomisi(data.variasi ? data.variasi[index]?.komisi_reseller : 0);
      setVariasiSelected((data.variasi && data.variasi[index]) || {});
    }
  };

  useEffect(() => {
    handleSelectedVariasi();
  }, [ukuranSelected, tipeSelected]);

  ///////////////////// FUNCTION /////////////////////

  const handlePanelVariasiChange = (value) => {
    dispatch(
      action_keranjang_add_produk(
        {
          produkId: value.id,
          jumlah: value.jumlah,
        },
        true,
        kodeToko,
        ()=>{
          navigate("/"+kodeToko+"/keranjang")
        }
      )
    );
  };

  const userAuth = getAuth()

  if (status === 0) return <></>;
  if (status === 2)
    return (
      <>
        <HeaderDetailProduct/>
        <NotFound object="produk" />
      </>
    );
  return (
    <div className="pb-1">
      <HeaderDetailProduct title={data.nama} />
      <main
        className="main-wrap product-page search-page index-page mb-xxl"
        style={{ backgroundColor: "#eeeeee" }}
      >
        {/* Banner Section End */}
        <SectionSlide data={slider} />
        {/* Product Section Section Start */}
        <SectionInformasi data={data} />  
        <SectionHarga data={{
          harga: variasiSelected.harga, 
          harga_diskon: variasiSelected.harga_diskon, 
          stok:variasiSelected.stok
        }} />
        
        <section className="low-price-section  recent-search unmain-wrap mb-2 inside-wrap  px-2 py-3 bg-white">
          <div className="top-content">
            <div>
              <h4 className="title-color m-0">Pilih Varian</h4>
            </div>
          </div>

          <div className="title-color mb-1 d-flex flex-column">
            <span className="font-sm text-secondary">Pilih Tipe</span>
            <ListBadge
              data={data.tipe}
              onChange={(value) => setTipeSelected(value)}
              default={tipeSelected}
            />
          </div>
          <div className="title-color mb-1 d-flex flex-column">
            <span className="font-sm text-secondary">Pilih Ukuran</span>
            <ListBadge
              data={data.ukuran}
              default={ukuranSelected}
              onChange={(value) => setUkuranSelected(value)}
            />
          </div>
        </section>
        <SectionDeskripsi deskripsi={data.deskripsi || ""} />
        <SectionUlasanPembeli data={data} onClickLihatSemua={() => {
          setPanelUlasanPembeliShow(1);
        }}/>

      </main>
      {/* Main End */}

      {/* Footer Start */}
      <SectionFooter
        data={data}
        onClickPesan={() => {
          if(userAuth === null) 
            navigate("/"+kodeToko+'/login')
          else
            setPanelVariasiShow(1);
        }}

      />

      <ModalPilihVariasiProduk
        buttonTitle={'Pesan Sekarang'}
        panelShow={panelVariasiShow === 1}
        counterShow={true}
        height="80%"
        data={data}
        onClose={() => setPanelVariasiShow(0)}
        onChange={(value) => {
          handlePanelVariasiChange(value);
        }}
      />
      <ModalUlasanPembeli
        panelShow={panelUlasanPembeliShow === 1}
        title={"UlasanPembeli"}
        height={"100%"}
        id={data.id}
        onClose={() => setPanelUlasanPembeliShow(0)}
      />
    </div>
  );
}

export default ProductDetail;
