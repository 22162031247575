import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import ProgressBar from 'react-bootstrap/ProgressBar';

const CardProduct = ({ data, isFlashSale }) => {
  return (
    <Link to={data.linkTo || ''}>
      <div className="product-card w-100 mb-2" style={{
        minHeight:"calc(270px + (24 - 18) * ((100vw - 320px) / (1920 - 320)))"
      }}>
        <div
          className="img-container"
          style={{
            backgroundImage: "url(" + data?.gambar + ")",
          }}
        ></div>
        <div className="content-wrap">
          <div className="font-xs fw-bold title-color mb-1 text-limit-2rows">
            {data?.nama}
          </div>
         
          {(data?.harga_diskon || data?.harga) && (
            <div className="title-color font-xs text-secondary">Mulai Dari</div>
          )}
          {data?.harga_awal && data?.is_diskon && (
            <div className="title-color font-xs  text-secondary">
              <del><NumberFormat
                value={data?.harga_awal || data?.harga}
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator=","
                prefix={"Rp"}
              /></del>
            </div>
          )}
          {(data?.harga_diskon || data?.harga) && (
            <div className="title-color font-md  fw-bold text-danger mb-1">
              <NumberFormat
                value={data?.harga_diskon || data?.harga}
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator=","
                prefix={"Rp"}
              />
            </div>
          )}

          {isFlashSale && <div className="my-3">
            <ProgressBar variant="primary"  now={100} label={`${data.stok} STOK TERSISA`} />
          </div>}
        </div>
      </div>
    </Link>
  );
};

CardProduct.propTypes = {
  title: PropTypes.string,
  komisi: PropTypes.number,
};

export default CardProduct;
