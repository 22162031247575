import React, { Component } from "react";
import Overlay from "../../components/mikro/Overlay";
import Loading from "../../components/mikro/Loading";
import api, { addItem } from "../../utils/api";
import { Cross } from "../../components/mikro/Icon";
import { withSnackbar } from "react-simple-snackbar";
import LabelForm from "../../components/mikro/LabelForm";
import ModalOptionsList from "../../components/makro/ModalOptionsList";
import { withRouter } from "../../utils/WithRouter";

class ModalAlamatPengiriman extends Component {
  state = {
    page: this.props?.params?.id ? "Edit" : "Tambah",
    id: this.props?.params?.id,
    panelShow: false,
    data: {
      judul: "",
      nama_penerima: "",
      nomor_hp: "",
      kecamatan_id: "",
      wilayah_label: "",
      alamat: "",
    },
    isRequired: true,
    isLoading: false,
  };

  handleChange(e) {
    let { data } = this.state;

    data = { ...data, [e.target.name]: e.target.value };
    this.handleValidate(data);
    this.setState({
      data: data,
    });
  }
  handleChangeLokasi(v) {
    let { data } = this.state;

    data = {
      ...data,
      kecamatan_id: v.subdistrict_id || "",
      wilayah_label: v.label || "",
    };
    this.handleValidate(data);
    this.setState({
      data: data,
    });
  }

  handleValidate({
    judul,
    nama_penerima,
    nomor_hp,
    kecamatan_id,
    wilayah_label,
    alamat,
  }) {
    if (
      judul !== "" &&
      nama_penerima !== "" &&
      nomor_hp !== "" &&
      kecamatan_id !== "" &&
      wilayah_label !== "" &&
      alamat !== ""
    )
      this.setState({ isRequired: false });
  }

  handleSimpan() {
    const { data, isLoading } = this.state;
    if (isLoading === false) {
      this.setState({ isLoading: true });
      const arrParams = {
        judul: data.judul,
        nama_penerima: data.nama_penerima,
        nomor_hp: data.nomor_hp,
        kecamatan_id: data.kecamatan_id,
        alamat: data.alamat,
      };

      addItem(this.props.params.kodeToko+"/alamat", arrParams).then((res) => {
        if (res.code === 200) {
          this.props.openSnackbar(res.message, 2000);
          setTimeout(() => this.handleClose(), 2000);
        } else {
          this.props.openSnackbar(
            res.message || "Terjadi Kesalahan",
            4000
          );
          this.setState({ isLoading: false });
        }
      });
    }
  }

  handleClose() {
    this.setState(
      {
        data: {
          judul: "",
          nama_penerima: "",
          nomor_hp: "",
          kecamatan_id: "",
          alamat: "",
        },
        isLoading: false,
        isRequired: true,
      },
      () => this.props.onClose()
    );
  }
  render() {
    const { panelShow, isLoading, isRequired } = this.state;
    const { 
      judul, 
      nama_penerima, 
      nomor_hp, 
      wilayah_label, 
      alamat 
    } = this.state.data;
    return (
      <>
        <div
          className={
            "shop-fillter offer-filter offcanvas offcanvas-bottom bg-white" +
            (this.props.panelShow ? " show" : "")
          }
          style={{
            boxShadow: "rgb(0 0 0 / 10%) 0px 0px 3px 1px",
            height: "100%",
          }}
        >
          <div className="offcanvas-header d-flex flex-column border-bottom p-0">
            <div className="w-100 d-flex">
              <div
                class="p-3 pointer"
                onClick={() => this.handleClose()}
              >
                <Cross width="20px" height="20px" />
              </div>
              <h4 className="title-color m-0 font-md fw-bold flex-fill w-100 text-start py-3">
                Tambah Alamat
              </h4>
            </div>
          </div>
          <div
            className="main-wrap setting-page pt-4  px-4 custom-form offcanvas-body small pb-5 bg-white px-0"
            style={{
              height: "100%",
            }}
          >
            <LabelForm label="Judul Alamat" isRequired={false} />
            <div className="input-box text-start">
              <input
                type="text"
                name="judul"
                placeholder="Rumah Pak Budi"
                required
                className="form-control"
                value={judul}
                onChange={(e) => this.handleChange(e)}
              />
            </div>
            <LabelForm label="Nama Penerima" isRequired={false} />
            <div className="input-box text-start">
              <input
                type="text"
                name="nama_penerima"
                placeholder="Bapak Budi Sutomo"
                required
                className="form-control"
                value={nama_penerima}
                onChange={(e) => this.handleChange(e)}
                
              />
              <i className="iconly-Profile icli"></i>
            </div>

            <LabelForm label="Nomor HP Penerima" isRequired={false} />
            <div className="input-box text-start">
              <input
                type="text"
                name="nomor_hp"
                placeholder="081234567890"
                required
                className="form-control"
                value={nomor_hp}
                onChange={(e) => this.handleChange(e)}
                
              />
              <i className="iconly-Call icli"></i>
            </div>

            <LabelForm label="Alamat " isRequired={false} />
            <div
              className="input-box mb-4"
              onClick={() => this.setState({ panelShow: true })}
            >
              <textarea
                name="alamat"
                placeholder="Ketik Alamat Penerima"
                required
                className="form-control pointer"
                value={wilayah_label || ""}
                readOnly={true}
                
              ></textarea>
              <i className="iconly-Location icli"></i>
            </div>

            <LabelForm label="Rincian Alamat" isRequired={false} />
            <div className="input-box mb-4">
              <textarea
                placeholder="Contoh: Nama Jalan, RT/RW, Kelurahan, Dll."
                required
                className="form-control"
                name="alamat"
                value={alamat}
                
                onChange={(e) => this.handleChange(e)}
              ></textarea>
            </div>
          </div>
          <div className="offcanvas-footer">
            <div className="btn-box">
              <button
                className={"btn btn-primary py-2 font-sm  w-50 "}
                onClick={() => this.handleSimpan()}
                disabled={isRequired}
              >
                Simpan
              </button>
            </div>
          </div>
        </div>
        <ModalOptionsList
          onRef={(ref) => (this.refModal = ref)}
          url={this.props.params.kodeToko+"/cek-ongkos-kirim/wilayah"}
          panelShow={panelShow}
          isSingleValue={true}
          title={"Lokasi Detail"}
          height={"80%"}
          onChangeValue={(e) => this.handleChangeLokasi(e)}
          onClose={() => this.setState({ panelShow: false })}
        />
        {this.props.panelShow ? <Overlay /> : ""}
      </>
    );
  }
}
export default withRouter(
  withSnackbar(ModalAlamatPengiriman, {
    style: {
      zIndex: 100,
    },
  })
);
