import React, { Children } from "react";
import { Link, useParams } from "react-router-dom";
import Img404 from "../../assets/images/empty.png";
const NotFound = ({ object, ...props }) => {
  const {kodeToko} = useParams()
  return (
    <div>
      <main className="main-wrap no-order mb-xxl">
        <div className="banner-box">
          <img src={Img404} className="img-fluid" alt="404" />
        </div>
        <section className="error mb-large">
          <h2 className="font-lg">{object.toUpperCase()} TIDAK DITEMUKAN</h2>
          <p className="content-color font-md">
            Maaf, sepertinya {object} yang kamu cari tidak tersedia atau telah
            dihapus. Silahkan kembali atau cari lagi.
          </p>
          <Link to={"/"+kodeToko} className="btn-solid">
            Kembali ke Beranda
          </Link>
        </section>
      </main>
    </div>
  );
};

export default NotFound;
