import React, { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
const SectionInformasiTransaksi = ({ data, ...props }) => {
  const [copied,setCopied] = useState(false)
  
  const handleClickCopied = () => {
    setCopied(true);

    setTimeout(()=>{
      setCopied(false);
    },3000)
  }
  return (
    <>
      <section className="product-section p-0  mb-2">
        <div className="top-content d-flex justify-content-between inside-wrap py-2 bg-page-dark ">
          <div className="font-sm text-dark fw-bold align-self-center">Kode Transaksi #{data.kode}</div>
          <CopyToClipboard text={data.kode || '-'}>
                  <div className={"btn btn-light fw-bold " + ( !copied ? "text-primary" : "text-success")}  onClick={()=>handleClickCopied()}>
                    {!copied ? "Salin" : "Disalin"}
                  </div>    
                </CopyToClipboard>
        </div>
        <div className="d-flex w-100 py-3 inside-wrap justify-content-between">
              <div className="font-xs text-dark fw-500">
                Status Transaksi
              </div>
              <div className="font-sm text-dark fw-bold">
                {data.status}
              </div>
            

        </div>
        <div className="d-flex w-100 py-3 inside-wrap border-top justify-content-between">
            <div className="font-xs text-dark fw-500">
            Waktu Pemesanan
            </div>
            <div className="font-sm text-dark fw-bold">
            {data.waktu_pemesanan_label}
            </div>
        </div>
      </section>
    </>
  );
};

export default SectionInformasiTransaksi;
