import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { ArrowRight } from "../../../components/mikro/Icon";
import { action_keranjang_edit_alamat } from "../../../store/action";
import ModalAlamatPengiriman from "../ModalAlamatPengiriman";

const SectionPesananAlamat = ({ alamat, pesananId, isTransaksi,...props }) => {
  const [isPanelAlamatShow, setIsPanelAlamatShow] = useState(false);
  const {kodeToko} = useParams()
  const dispatch = useDispatch();

  const handleAlamat = (alamatBaru) => {
      dispatch(
        action_keranjang_edit_alamat({
          alamatId: alamatBaru.id || "",
          pesananId: pesananId,
        },
        kodeToko)
      );
  };

  return (
    <>
      <section className={(!isTransaksi ? 'alamat-detail':'')+" product-section p-0 border-top"}>
        <div className="top-content d-flex justify-content-between inside-wrap pt-3 pb-2">
          <div className="font-sm text-dark fw-bold">Alamat Pengiriman</div>
          {alamat && !isTransaksi && <div className="pointer" onClick={() => setIsPanelAlamatShow(true)}>
            <span className="font-sm fw-bold text-primary">Ubah Alamat </span>
            &nbsp;
            <ArrowRight width="24px" height="24px" />
          </div>}
        </div>
        <div className="d-flex w-100 py-3 inside-wrap border-top">
          {alamat ? <div className="flex-fill">
            {alamat && alamat.judul && alamat.nama_penerima && (
              <div className="font-sm text-dark fw-bold">
                {alamat.judul} ({alamat.nama_penerima})
              </div>
            )}
            {alamat?.nomor_hp && (
              <div className="font-xs text-dark fw-500">
                HP: {alamat?.nomor_hp}
              </div>
            )}
            {alamat?.label_alamat && (
              <div className="font-xs text-dark fw-500">
                {alamat?.label_alamat || ""}
              </div>
            )}
          </div> : <div 
            className="btn btn-sm btn-outline-primary text-center w-100 py-2"
            onClick={() => setIsPanelAlamatShow(true)}
            >Pilih Alamat Pengiriman</div>}
        </div>
              
      </section>
      {!isTransaksi && <ModalAlamatPengiriman
        panelShow={isPanelAlamatShow}
        title={"Pilih Alamat Pengiriman"}
        height={"100%"}
        onChangeValue={(value) => {
          handleAlamat(value);
        }}
        onClose={() => setIsPanelAlamatShow(false)}
      />}
    </>
  );
};

export default SectionPesananAlamat;
