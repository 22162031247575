import React from "react";
import { ArrowRight } from "../../../components/mikro/Icon";
import NumberFormat from "react-number-format";

const SectionPesananMetodePengiriman = ({data, isTransaksi, ...props}) => {
  return (
    <section
      className=" product-section p-0"
      style={!isTransaksi ? {
        background: "#f4faff",
        borderTop: "1px solid #a0cdee",
        borderBottom: "1px solid #a0cdee",
      } : {}}
    >
      {!isTransaksi && <div className="top-content d-flex justify-content-between inside-wrap pt-3 pb-2">
        <div className="font-sm text-secondary fw-400">Layanan Pengiriman</div>
         <div className="pointer" onClick={()=>props.onClick()}>
          <span className="font-sm fw-bold text-primary">Ganti Kurir </span>
          &nbsp;
          <ArrowRight width="24px" height="24px" />
        </div>
      </div>}
      <div className="d-flex w-100 py-3 inside-wrap border-top justify-content-between">
        <div className="font-sm text-dark fw-bold me-2">{data.judul }</div>
        <div className="font-sm text-danger fw-bold">
          <NumberFormat
            value={data.harga}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator=","
            prefix="Rp"
          />
        </div>
      </div>
    </section>
  );
};

export default SectionPesananMetodePengiriman;
