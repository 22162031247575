import React, { useState } from "react";
import SectionPesananAlamat from "./SectionPesananAlamat";
import SectionPesananProduk from "./SectionPesananProduk";
import SectionPesananTotalHarga from "./SectionPesananTotalHarga";
import SectionPesananKurir from "./SectionPesananKurir";
import SectionPesananMetodePengiriman from "./SectionPesananMetodePengiriman";
import SectionPesananStatus from "./SectionPesananStatus";
import SectionPesananButtonKonfirmasi from "./SectionPesananButtonKonfirmasi";
import { Dikirim, Diterima } from "../../../enums/status_transaksi";

const SectionPesanan = ({ data, isKeranjang,isTransaksi,...props }) => {


  return (
    <div className=" mb-2">
      {isTransaksi && <SectionPesananStatus data={data} onClick={()=>props.onClickLacak()} />} 
      
      <SectionPesananAlamat
        alamat={data?.alamat || null}
        pesananId={data?.id || null}
        isTransaksi={isTransaksi || false}
      />
      <SectionPesananProduk
        alamat={data?.alamat || null}
        partner={data?.partner || null}
        produk={data?.produk || null}
        isKeranjang={isKeranjang}
      />

      {data.kurir ?
          <SectionPesananMetodePengiriman 
            data={data.kurir} 
            onClick={()=>data.alamat && props.onClickKurir(data.id)}
            isTransaksi={isTransaksi || false}
          /> : <SectionPesananKurir 
                data={data} 
                onClick={()=>data.alamat && props.onClickKurir(data.id)} 
              />}

      <SectionPesananTotalHarga 
        data={data}
        isKeranjang={isKeranjang}
       />
      { isTransaksi && 
        data.status === Diterima &&
        <SectionPesananButtonKonfirmasi
          onClickKomplain={()=>props.onClickKomplain()}
          onClickSelesai={()=>props.onClickSelesai()}
        />} 

    </div>
  );
};

export default SectionPesanan;
