import React from "react";
import Img404 from "./../../assets/images/empty.png";
import NotFound from "../../components/makro/NotFound";
export default function P404() {
  return (
    <>
      <NotFound object="halaman" />
    </>
  );
}
