import React from "react";
const SectionDeskripsi = (props) => {
  return (
    <section className="low-price-section unmain-wrap mb-2 inside-wrap  bg-white">
      <div className="top-content ">
        <div>
          <h4 className="title-color m-0">Deskripsi Produk</h4>
        </div>
      </div>

      <p className="content-color font-base">
        {props.deskripsi || props.deskripsi != "" ? (
          <div dangerouslySetInnerHTML={{ __html: props.deskripsi }} />
        ) : (
          ""
        )}
      </p>
    </section>
  );
};

export default SectionDeskripsi;
