import React from "react";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import CardListComments from "../../../components/makro/CardListComments";
import { ArrowRight, Star } from "../../../components/mikro/Icon";
const SectionUlasanPembeli = ({ data, ...props }) => {
  return data.ulasan_pembeli.length > 0 &&
    <section className=" check-delivery-section low-price-section unmain-wrap mb-2 inside-wrap pt-3 pb-0 bg-white">
      <div className="top-content">
        <div className="align-middle">
          <h4 className="title-color m-0">Ulasan Pembeli</h4>
          <span className="font-md">
            <Star width="15" height="15" />&nbsp;
            <span className="font-sm fw-bold content-color pt-1">{data.rating} / 5</span>
          </span>
          &nbsp;
          &nbsp;
          <span className="text-secondary font-xs">
            <NumberFormat
              value={data.ulasan_pembeli_count || 0}
              displayType={"text"}
              thousandSeparator={'.'}
              decimalSeparator={','}
            /> Ulasan 
          </span>
        </div>
        <div 
          className="font-theme mt-0 pointer font-sm fw-bold" 
          onClick={()=>props.onClickLihatSemua()}
        >
          Lihat Semua <ArrowRight width="22" height="22"/> 
        </div>
      </div>
      <CardListComments data={data.ulasan_pembeli} />
    </section>
};

export default SectionUlasanPembeli;
