import React, { Component } from "react";
import CardProduct from "../../../components/makro/CardProduct";
import Filter from "./Filter";

import { decodeParams } from "../../../utils/LinkUrl";
import {getList} from "../../../utils/api";
import { withRouter } from "../../../utils/WithRouter";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      panelShow: false,
      items: [],
      meta: {
        current_page: 0,
        last_page: 0,
        per_page: 0,
        total: 0,
      },

      filter: {},
    };
  }

  getProduk() {
    let { filter, meta } = this.state;
    filter = { ...filter, page: meta.current_page + 1 };
    getList(this.props.params.kodeToko+"/list-produk", filter).then((res) => {
      if (res.code === 200) {
        this.setState({
          items: res?.data,
        });
      }
    });
  }

  handleFilter(filter) {
    this.setState(
      {
        filter: filter,
        meta: {
          current_page: 0,
          last_page: 0,
          per_page: 0,
          total: 0,
        },
        items: [],
      },
      () => this.getProduk()
    );
  }

  render() {
    const { items } = this.state;
    const {kodeToko} = this.props.params;
    return (
      <Filter onFilter={(value) => this.handleFilter(value)}>
        <div className="">
          <div className="row px-1">
            {items && items.length > 0 && items.map((v, i) => (
              <div className="col-lg-4 col-md-4 col-sm-6 col-6 px-1" key={v.id}>
                <CardProduct data={{...v, linkTo:`/${kodeToko}/p/${v.id}`}} />
              </div>
            ))}

            {items && items.length === 0 && <div className="col-12"><h5 className="text-secondary my-5 text-center">Data tidak tersedia</h5></div>}
          </div>
        </div>
      </Filter>
    );
  }
}

export default withRouter(index);
