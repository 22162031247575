import React from "react";
import NumberFormat from "react-number-format";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { action_transaksi_checkout } from '../../../store/action/transaksi_action'
import {KURIR_TYPE} from '../../../enums/aplikasi'

const SectionTotalBayar = ({ isKeranjang, data, customButton }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { kodeToko } = useParams()
  const handleProccessData = () => {
    if(!isKeranjang) dispatch(action_transaksi_checkout(kodeToko, data?.jenis_pembayaran || KURIR_TYPE.REGULER, 
    (link)=>{
      navigate(link);
    })); 
    else navigate("/checkout");
  }

  return (
    <footer className="footer-wrap p-0 border-top prevent-select bg-white">
      <div className="d-flex justify-content-between py-2 inside-wrap align-items-center">
        <div className=" d-flex flex-column align-middle bg-white">
          <div className="font-xs fw-bold">
            Total&nbsp;
            {isKeranjang ? <span className="text-primary">
                {data?.total_kuantitas &&
                  "(" + data?.total_kuantitas + ") Produk"}
              </span> : "Pembayaran"}
          </div>
          <div class="text-danger font-md ">
            <NumberFormat
              className="fw-bold"
              value={isKeranjang ? (data?.total_harga || 0) : (data?.total || 0)}
              displayType={"text"}
              thousandSeparator={"."}
              decimalSeparator=","
              prefix="Rp"
            />
          </div>
        </div>
        <div className="text-center text-white pointer h-100  d-flex">
          {customButton || <button
            className="btn btn-primary font-sm py-2 px-5"
            onClick={() => handleProccessData()}
          >
            {isKeranjang ? "Checkout" : (data.jenis_pembayaran === KURIR_TYPE.COD ? "Buat Pesanan" : "Pilih Pembayaran")}
          </button>}
        </div>
      </div>
    </footer>
  );
};

export default SectionTotalBayar;
