import React, { Component, Fragment } from "react";
import { Link, Navigate } from "react-router-dom";

import { addItem } from "../../utils/api";
import Loading from "../../components/mikro/Loading";
import { withRouter } from "../../utils/WithRouter";
import { withSnackbar } from "react-simple-snackbar";
import { setToken, setAuth } from "../../utils/authUtils";
import { connect } from "react-redux";
import {set_loading} from '../../store/action/index'

import LogoBerberkahImg from './../../assets/logo-berberkah-with-name.png'

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_show_password: false,
      phone: "",
      token: "",
      inputOtp: false,
      otp: "",
      isLoading: false,
      error_otp:""
    };
  }

  
  sendPhoneOTP() {
    this.props.set_loading(true)
    const { phone } = this.state;
    addItem("login", {
      phone: "0" + phone,
    })
    .then((res) => {
      this.props.set_loading(false)
      if(res?.code !== 200){
        this.props.openSnackbar(res?.message || '',4000);
      }
      else if (res?.code === 200) {
        this.setState({
          inputOtp: true,
          token: res?.data?.token || ''
        })
      }
    }).catch(err => {
      this.props.set_loading(false)
    })


    setTimeout(()=>{
      this.props.set_loading(false)
    },10000)
  }

  verifyOTP() {
    this.props.set_loading(true)
    const { otp, token } = this.state;
    if (otp.length === 5)
      addItem("check-otp", { token, otp})
        .then((res) => {
          this.props.set_loading(false)
          if (res.code !== 200){
            this.props.openSnackbar(res?.message || '',4000);
          }
          else if (res.code === 200) {
            setToken(res?.data?.access_token);
            setAuth(res?.data?.user);
            const self = this
            this.props.set_loading(false)
            setTimeout(()=>{
              self.props.navigate("/"+this.props.params.kodeToko);
            },500);
          }
        }).catch(err=>{
          this.props.set_loading(false)
        });
    setTimeout(()=>{
      this.props.set_loading(false)
    },10000)
  }
  handleChangePhoneNumber = (e) => {
    let { value, name } = e.target;

    const number = /^[0-9\b]+$/;
    if (name === "phone" && (value === "" || number.test(value))) {
      value = String(value).trim();
      if (value.startsWith("+62")) {
        value = value.slice(3);
      } else if (value.startsWith("62")) {
        value = value.slice(2);
      } else if (value.startsWith("0")) {
        value = value.slice(1);
      }
      this.setState({
        phone: value
      }); 
    }
  };
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.sendPhoneOTP();
  }
  handleSubmitOtp(e) {
    e.preventDefault();
    this.verifyOTP();
  }

  render() {
    const { phone, otp, inputOtp, isLoading, error_otp } = this.state;
    return (
      <div>
        {/* Main Start */}
        <main className="main-wrap login-page py-5 text-center  container align-items-center d-flex flex-column">
          <img
            className="logo"
            src={LogoBerberkahImg}
            alt="logo"
          />

          <section className="login-section" style={{ width:'92%' }}>
            {!inputOtp && (
              <form
                className="custom-form container-sm"
                onSubmit={(e) => this.handleSubmit(e)}
              >
                <h1 className="font-xl mb-2  mt-4  title-color fw-600 text-center">
                  Login
                </h1>
                <p className="font-sm content-color mb-5 text-center">
                  <span>Masukkan nomor hp kamu</span>
                </p>
                {/* Email Input start */}
                <div className="input-group mb-5 bg-white">
                  <span class="input-group-text bg-white font-md ">+62</span>
                  <input
                    type="number"
                    name="phone"
                    value={phone}
                    placeholder="82345678xxx"
                    required
                    className="form-control font-md py-3 bg-white"
                    onChange={(e) => this.handleChangePhoneNumber(e)}
                  />
                </div>

                <div id="recaptcha-container"></div>

                <button type="submit" className="btn-solid font-md py-4 mb-5">
                  Kirim Kode Verifikasi
                </button>
                <div className="w-100">
                  <Link
                    className="font-md"
                    to={"/"+(this.props?.toko?.url || '')}
                  >
                    Kembali
                  </Link>
                </div>
              </form>
            )}

            {inputOtp && (
              <form
                className="custom-form"
                onSubmit={(e) => this.handleSubmitOtp(e)}
              >
                <h1 className="font-xl mb-2  mt-4  title-color fw-600 text-center">
                  Kode Verifikasi Sudah Dikirim
                </h1>
                <p className="font-sm content-color mb-5 text-center">
                  <span>
                    Cek kode verifikasi di HP-mu melalui Whatsapp dengan nomor <br />
                    <span className="text-info">+62 {phone}</span>
                  </span>
                </p>

                <div className="input-box mb-4">
                  <input
                    type="number"
                    name="otp"
                    value={otp}
                    placeholder="Masukkan Kode OTP "
                    required
                    className="form-control font-md py-3 bg-white text-center"
                    maxLength={6}
                    minLength={6}
                    onChange={(e) => this.handleChange(e)}
                  />
                  {error_otp && <p className="m-0 text-danger">{error_otp}</p>}
                </div>

                <button
                  type="submit"
                  className={
                    "font-md py-4 mb-4 " +
                    (parseInt(otp) > 99999 || otp === "" || otp.length < 5
                      ? " btn btn-secondary w-100 "
                      : "btn-solid")
                  }
                  disabled={
                    parseInt(otp) > 99999 || otp === "" || otp.length < 5
                  }
                >
                  Verifikasi
                </button>
                <p className="font-md content-color text-center mb-4">
                  Tidak mendapatkan kode ?&nbsp;
                </p>
                <div
                  className="btn font-md btn-outline-secondary w-100 py-3"
                  onClick={() =>
                    this.sendPhoneOTP()
                  }
                >
                  Kirim Ulang
                </div>
              </form>
            )}
          </section>
          {/* Login Section End */}
        </main>
        {/* Main End */}
        {isLoading ? <Loading /> : ""}
      </div>
    );
  }
}

const reduxState = (state) => ({
  toko: state.toko,
});
const reduxDispatch = (dispatch) => ({
  set_loading: (isLoading) => dispatch(set_loading(isLoading)),
});

export default connect(reduxState, reduxDispatch)(withRouter(
  withSnackbar(Login, {
    style: {
      zIndex: 100,
    },
  })
));