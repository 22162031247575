import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
export default function AuthUser() {
  const navigate = useNavigate();
  const getToken = () => {
    const tokenString = localStorage.getItem("t");
    const userToken = JSON.parse(tokenString);
    return userToken;
  };

  const getUser = () => {
    const userString = localStorage.getItem("u");
    const userDetail = JSON.parse(userString);
    return userDetail;
  };

  const [token, setToken] = useState(getToken());
  const [user, setUser] = useState(getUser());

  const saveToken = (user, token) => {
    localStorage.setItem("t", JSON.stringify(token));
    localStorage.setItem("u", JSON.stringify(user));

    setToken(token);
    setUser(user);
    
    navigate("/beranda");
  };

  const logout = () => {
    localStorage.clear();
    navigate("/");
  };

  const http = axios.create({
    baseURL:
      process.env.REACT_APP_ENV === "DEVELOPMENT"
        ? process.env.REACT_APP_API_DEVELOPMENT
        : process.env.REACT_APP_API_PRODUCTION,
    headers: {
      "Content-type": "application/json",
      Authorization: "Bearer " + token,
    },
  });

  return {
    setToken: saveToken,
    token,
    user,
    getToken,
    getUser,
    http,
    logout,
  };
}
