export const TAB_PROSES_TRANSAKSI = [
    { title: "Belum Bayar", active: true },
    { title: "Sudah Bayar", active: false },
    { title: "Diproses", active: false },
    { title: "Dikirim", active: false },
    { title: "Diterima", active: false },
    { title: "Dikomplain", active: false },
    { title: "Selesai", active: false },
    { title: "Dibatalkan", active: false },
    { title: "Kadaluarsa", active: false },
];