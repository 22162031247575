import React, { useState, useEffect, useRef } from "react";

const ListBadge = (props) => {
  const [selected, setSelected] = useState();
  const prevSelected = usePrevious(selected);
  useEffect(() => {
    if (props.default !== selected && prevSelected === selected) {
      setSelected(props.default);
    }
  });

  useEffect(() => {
    props.onChange(selected);
  }, [selected]);

  return (
    <section className="py-3 px-1">
      {props.data &&
        props.data.map((d, i) =>
          d.nama && (
            <div
              className={
                "font-sm title-color nowrap badge px-4 py-2 my-1 me-2 pointer rounded " +
                (d.nama === selected ? "bg-primary text-white" : "bg-white") +
                " border"
              }
              onClick={() => setSelected(d.nama)}
              key={i}
            >
              {d.nama}
            </div>
          )
        )}
    </section>
  );
};

export default ListBadge;

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
