import React from "react";
import { AngleRight, TruckIcon } from "../../../components/mikro/Icon";

const SectionPesananTotalHarga = ({ data, ...props}) => {
  return (
    <section className="product-section p-3 bg-light border-top">
      <div 
        className="border d-flex px-3 py-2 justify-content-between pointer bg-white" 
        style={{borderRadius: '0.5rem'}}
        onClick={()=>props.onClick()}>
        <div className="align-self-center">
          <span className="font-sm fw-bold"></span>
        </div>
        <div className="align-self-center">
        <TruckIcon width={'18'} height={'18'}/> &nbsp;
          <span className="font-sm fw-bold">Pilih Pengiriman</span>
        </div>
        <div className="font-xm  fw-bold align-self-center">
          <AngleRight/>
        </div>
      </div>
    </section>
  );
};

export default SectionPesananTotalHarga;

