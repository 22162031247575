import React from 'react';
import NumberFormat from 'react-number-format';

const SectionRincianPesanan = ({data}) => {
    return (
        <div class="card" >
            <div class="card-body" >
              <ul class="list-group list-group-flush">
                { data?.pesanan && data?.pesanan.map((d,i) =>(
                  <li class="list-group-item px-2" key={i}>
                    <h5 className="fw-bold font-sm ">{d.alamat.nama_penerima}</h5>
                    <div className="d-flex justify-content-between inside-wrap pt-1  align-items-center">
                      <div className="font-xs fw-500">Total Harga&nbsp;
                      <NumberFormat
                          value={d.total_jumlah || 0}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator=","
                          prefix={"("}
                          suffix={" Barang)"}
                        />
                      </div>
                      <div  className="font-xs fw-bold ms-2">
                        <NumberFormat
                          value={d.total_harga || 0}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator=","
                          prefix={"Rp"}
                        />
                      </div>
                    </div>
                    {d.kurir && <div className="d-flex justify-content-between inside-wrap pt-1  align-items-center">
                      <div className="font-xs fw-500">Total Ongkir&nbsp;
                      <NumberFormat
                          value={d.total_berat || 0}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator=","
                          prefix={"("}
                          suffix={" gr)"}
                        />
                      </div>
                      <div  className="font-xs fw-bold ms-2">
                        <NumberFormat
                          value={d?.kurir?.harga || 0}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator=","
                          prefix={"Rp"}
                        />
                      </div>
                    </div>}
                    <div className="d-flex justify-content-between inside-wrap pt-1  align-items-center">
                      <div className="font-xs fw-bold">Subtotal</div>
                      <div  className="font-xs fw-bold ms-2">
                        <NumberFormat
                          value={d.subtotal || 0}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator=","
                          prefix={"Rp"}
                        />
                      </div>
                    </div>
                  </li>
                ))}
                {data?.kupon?.jenis && data?.total_diskon > 0 && 
                  <li class="list-group-item px-2">
                    <div className="d-flex justify-content-between pe-3 pt-1  align-items-center">
                      <div className="font-xs fw-bold">Diskon {data?.kupon?.jenis}</div>
                      <div  className="font-xs fw-bold ms-2">
                        <NumberFormat
                          value={data?.total_diskon || 0}
                          displayType={"text"}
                          thousandSeparator={"."}
                          decimalSeparator=","
                          prefix={"-Rp"}
                        />
                      </div>
                    </div>
              </li>}
              </ul>
            </div>
        </div>
    );
};

export default SectionRincianPesanan;