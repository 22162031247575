import React, { useState } from "react";

const SearchBox = (props) => {
  const [value, setValue] = useState(props.value || "");
  const [typing, setTyping] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(0);


  const handleChange = (value) => {
    const self = this;

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setValue(value);
    setTyping(false);
    setTypingTimeout(
      setTimeout(function () {
        props.onChange(value);
      }, 500)
    );
  };
  const handleReset = () => {
    setValue("");
    props.onChange("");
  };

  return (
    <div
      className={
        "search-box flex-fill " + ((props.readOnly && " pointer") || "")
      }
      onClick={() => props.readOnly && props.onClick()}
    >
      <div>
        <i className="iconly-Search icli search"></i>
        <input
          className={"form-control " + ((props.readOnly && " pointer") || "")}
          type="search"
          name="search"
          value={props.readOnly ? props.value : value}
          autoComplete="off"
          onChange={(e) => handleChange(e.target.value)}
          placeholder={props.placeholder || "Cari produk di sini"}
          readOnly={props.readOnly}
          onKeyDown={(e) => e.key === "Enter" ? props.onEnter(e.target.value) : ""}
          style={{
            border: '1px solid #b7b7b7',
            padding: '10px 45px',
          }}
        />
        {value !== "" ? (
          <i
            style={{
              fontSize: "10px ",
              right: "16px",
              color: "rgb(255 0 0 / 62%)",
            }}
            onClick={() => handleReset()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-x-circle-fill"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
            </svg>
          </i>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default SearchBox;
