import React, { Component } from 'react';
import {zeroPad} from './../../utils/NumberFormat'
export default class CountDown extends Component {
  constructor(props) {
    super(props);
    this.state = { time: {}, seconds: 3};
    this.timer = 0;
    this.countDown = this.countDown.bind(this);
  }

  secondsToTime(secs){
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      "h": hours,
      "m": minutes,
      "s": seconds
    };
    return obj;
  }

  componentDidMount() {
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar });
    this.timer = setInterval(this.countDown, 1000);
  }

  countDown() {
    let seconds = (this.state.seconds > 3 ? this.state.seconds : this.props.seconds) - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });
    
    if (seconds === 0) { 
      if(this.props.onClearTime)
        this.props.onClearTime();
      clearInterval(this.timer);
    }
  }

  render() {
    const { time,seconds } = this.state
    return(
        seconds > 1 ? <div className="d-flex" {...this.props}>
            <NumberBox title={this.props.isWithoutTitle ? '' : "Jam"} time={time.h}/>
            <Divider/>
            <NumberBox title={this.props.isWithoutTitle ? '' : "Menit"} time={time.m}/>
            <Divider/>
            <NumberBox title={this.props.isWithoutTitle ? '' : "Detik"} time={time.s}/>
        </div>:<div className="d-flex"  {...this.props}>
          <div className="px-2 py-1 bg-warning font-xs text-center text-white rounded mb-1"><span>Waktu Habis</span></div>
        </div>
    );
  }
}

function NumberBox({title, time}){
    return <div className='d-flex flex-column'>
                <div className="px-2 py-1 bg-danger font-xs text-center text-white rounded mb-1">
                    <span>{zeroPad(time,2)}</span>
                </div>
                <div className='font-xs text-muted text-center'>{title}</div>
            </div>
}

function Divider(){
    return <div className="p-1">:</div>
}