import React, { Fragment } from 'react';
import Image from 'react-bootstrap/Image'
import CountDown from '../makro/CountDown'

export function BelumBayarMessage({ data }){
  return <Fragment>
            <div className="px-3 text-center align-self-center">
               <Image 
                rounded={true}
                src={require("../../assets/images/transaction-timer.png")}
                className="img-fluid"
                width={'40px'}
                height={'40px'}
              />
            </div>
            <div className="title-color font-sm flex-grow-1 flex-column justify-content-start">
              <div className="mb-1"><CountDown seconds={data.sisa_waktu_pembayaran || 0} onClearTime={()=>this.props.onClearTime()}/></div>
              <div className="text-start text-muted font-xs">Selesaikan pembayaran sebelum:</div>
              <div className="text-start m-0 font-xs fw-bold">{data.waktu_kadaluarsa_label}</div>
            </div>
        </Fragment>
}

export function SudahBayarFooter({ status,...props }){
  return <Fragment>
            <div class="title-color font-sm flex-grow-1 flex-column gap-0 justify-content-start">
              <div className="text-start fw-bold">Pesanan {status}</div>
              <div className="text-start font-xs m-0 text-muted">Paket akan diproses untuk segera dikirim</div>
            </div>
          </Fragment>
}


export function KadaluarsaMessage({ data }){
  return <Fragment>
            <div className="px-3 text-center align-self-center">
               <Image 
                rounded={true}
                src={require("../../assets/images/transaction-timer.png")}
                className="img-fluid"
                width={'40px'}
                height={'40px'}
              />
            </div>
            <div className="title-color font-sm flex-grow-1 py-1 flex-column justify-content-start">
              <div className="text-start fw-bold text-dark font-sm">Waktu Pembayaran Habis</div>
              <div className="text-start text-muted font-xs">Silahkan order kembali pesananmu</div>
            </div>
        </Fragment>
}

export function BelumBayarFooter(props){
  return <Fragment>
            <div className="title-color font-sm flex-grow-1 flex-column gap-0 justify-content-start">
              <div className="text-start fw-bold">Selesaikan Pembayaran</div>
              <div className="text-start font-xs m-0 text-muted">Sebelum waktunya habis</div>
            </div>
            <div className="btn btn-primary btn-sm px-3 py-2" onClick={()=>props.onClickBayar()} >Bayar Sekarang</div>
          </Fragment>
}



export function DikirimFooter(props){
  return <Fragment>
            <div className="title-color font-sm flex-grow-1 flex-column gap-0 justify-content-start">
              <div className="text-start font-xs m-0 text-muted">Pesanan sedang dalam pengiriman</div>
            </div>
            <div className="btn btn-success btn-sm px-3" onClick={()=>props.onClick()}>Lacak</div>
          </Fragment>
}


export function DiterimaFooter(props){
  return <Fragment>
            <div className="title-color font-sm flex-grow-1 flex-column gap-0 justify-content-start">
              <div className="text-start font-xs m-0 text-muted">Konfirmasi terima produk sebelum 12-12-2022</div>
            </div>
            <div className="btn btn-success btn-sm px-3" onClick={()=>props.onClick()}>Pesanan Diterima</div>
          </Fragment>
}

export function SelesaiFooter({ data,...props }){
  return <Fragment>
            <div className="title-color font-sm flex-grow-1 flex-column gap-0 justify-content-start">
              <div className="text-start fw-bold">Selesai</div>
              <div className="text-start font-xs m-0 text-muted">Pesananmu telah selesai</div>
            </div>
          </Fragment>
}