import React, { useState, useEffect } from "react";

const FilterButtonAndBadge = ({ data, ...props }) => {
  const [selected, setSelected] = useState(null);
  const [oldItems, setOldItems] = useState(0);
  const [items, setItems] = useState(data);
  const [allButtonActive, setAllButtonActive] = useState(true);

  useEffect(() => {
    if (data.length > 0 && oldItems === 0) {
      setOldItems(oldItems+1);
      setItems(data);
    }
  }, [data]);

  const handleClick = (key) => {
    let newArr = [...items];
    let valueArr = [];
    for (let index = 0; index < newArr.length; index++) {
      if (key === index) newArr[key].active = !newArr[key].active;
      if (newArr[index].active) valueArr.push(newArr[index].value);
    }
    setItems(newArr);
    if (valueArr.length > 0) setAllButtonActive(false);
    props.onChangeValue(valueArr);
  };
  const handleSelect = (key) => {
    let newArr = [];
    let valueArr = null;
    for (let index = 0; index < items.length; index++) {
        newArr.push({title: items[index].title, active: key === index})
      if (key === index) valueArr = items[index].title;
    }
    setItems(newArr);
    props.onChangeValue(valueArr);
  };

  const handleReset = () => {
    let newArr = [...items];
    for (let index = 0; index < newArr.length; index++) {
      newArr[index].active = false;
    }
    setItems(newArr);
    setAllButtonActive(true);
    props.onChangeValue([]);
  };

  return (
    <section className="recent-search p-0 prevent-select">
      <ul class="nav nav-tab nav-pills  bg-transparent m-0 px-0 py-1">
        {!props.isWithoutAllBtn && <li
          class={
            "nav-item px-3  " + (allButtonActive ? "bg-primary-light" : " bg-white border")
          }
          onClick={() => handleReset()}
        >
          <button class={"nav-link " + (allButtonActive ? "text-white" : "")}>
            Semua
          </button>
        </li>}
        {items.map((d, i) => (
          <li
            class={"nav-item px-3 " + (d.active ? "bg-primary-light" : "bg-white border")}
            key={i}
            onClick={() => !props.isWithoutAllBtn ? handleClick(i): handleSelect(i)}
          >
            <button className={"nav-link " + (d.active ? "text-white" : "")}>
              {d.label || d.title}
            </button>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default FilterButtonAndBadge;
