import React, { useRef, useEffect, useState } from "react";

const SectionPesananTambahCatatan = (props) => {
  const [isTulisCatatan, setIsTulisCatatan] = useState(false);
  const refOne = useRef(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
  }, []);

  const handleClickOutside = (e) => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      setIsTulisCatatan(false);
      props.onClickOutside();
    }
  };

  return (
    !props.isKeranjang ? <div className="prevent-select py-1">{props.catatan}</div>:
    <div className="row" ref={refOne}>
      {!isTulisCatatan && (props.catatan === "" ||props.catatan === null) && (
        <div
          className="mt-2 pointer text-primary fw-bold pointer  prevent-select"
          onClick={() => setIsTulisCatatan(true)}
        >
          Isi Catatan
        </div>
      )}
      {!isTulisCatatan &&  props.catatan !== "" && props.catatan!== null && (
        <div className="m-0 col-12 pointer w-100 prevent-select">
          <div className="font-sm text-primary fw-bold mb-1">Catatan</div>
          <div className="row">
            <div className="col">{props.catatan}</div>
            <div
              className=" col font-sm text-info fw-bold text-end"
              onClick={() => setIsTulisCatatan(true)}
            >
              Ubah
            </div>
          </div>
        </div>
      )}
      {isTulisCatatan && (
        <div className="col">
          <label
            className="font-sm text-primary fw-bold mb-1 prevent-select"
            onClick={() => setIsTulisCatatan(true)}
          >
            Tulis Catatan
          </label>
          <input
            type="text"
            name="catatan"
            placeholder="Silahkan Tinggalkan Pesan"
            className="form-control input-sm bg-white pointer w-100 py-2"
            value={props.catatan}
            onChange={(e) => props.onChange(e.target.value)}
          />
          <small className="text-muted prevent-select">
            <i>*Pastikan tidak terdapat data pribadi</i>
          </small>
        </div>
      )}
    </div>
  );
};

export default SectionPesananTambahCatatan;
