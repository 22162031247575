import React from "react";
import PropTypes from "prop-types";

const LabelForm = (props) => {
  return (
    <div className="font-sm mb-2 text-start">
      {props.label}
      {props.isRequired ? <a className="text-danger">*</a> : ""}
    </div>
  );
};

LabelForm.propTypes = {
  label: PropTypes.string.isRequired,
  isRequired: PropTypes.bool.isRequired,
};

export default LabelForm;
