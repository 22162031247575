import React, { Component } from 'react';
import { FilterIcon } from '../../../components/mikro/Icon';
import FilterPencarian from '../../../parts/app/FilterPencarian';

class SectionFilter extends Component {
  state = { 
    isPanelShow:false
  }
  handleFilter(value){
    this.setState({ isPanelShow:false });
    this.props.onChange(value);
  }
  render() { 
    return ( 
      <div>
        <FilterPencarian 
          isPanelShow={this.state.isPanelShow} 
          onClose={()=>this.setState({ isPanelShow:false })}
          onChange={(value) => this.handleFilter(value)}
        />
         <section className="category low-price-section p-0 mb-2 inside-wrap  bg-white default-card-shadow">
          <div className="top-content m-0">
            <div className="d-flex flex-row justify-content-between w-100 align-items-center">
                <p className="m-0">Total Produk: {this.props.total}</p>
                <div className="d-flex flex-row align-items-center" onClick={()=>this.setState({ isPanelShow:true })}>
                  <div className="p-3 d-flex gap-2 pointer">
                    <FilterIcon width="20" height="20"/><span>Filter</span>
                  </div> 
                </div>
            </div>
          </div>
        </section>
      
      </div>
     );
  }
}
 
export default SectionFilter;