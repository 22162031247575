import React, { Component } from "react";

class ShopFilterOptionBtn extends Component {
  constructor(props) {
    super(props);
    this.state = { active: this.props.value };
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.value !== prevProps.value &&
      this.props.value !== this.state.active
    ) {
      this.setState({ active: this.props.value });
    }
  }
  handleClick = (active) => {
    const { title } = this.props;
    this.setState({ active: active }, () =>
      this.props.onClick({ active: active, value: title })
    );
  };

  render() {
    const { active } = this.state;
    const { title } = this.props;
    return (
      <span
        className={
          "badge  font-xs py-2 px-3 m-1 border fw-light pointer prevent-select " +
          (active ? "bg-primary-light text-white" : "bg-white text-dark")
        }
        onClick={() => this.handleClick(!active)}
      >
        {title}
      </span>
    );
  }
}

export default ShopFilterOptionBtn;
