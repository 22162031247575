import { SET_ALL_PROMO_POINT, SET_DATA_TOKO, UPDATE_LOADING, UPDATE_REDIRECT, UPDATE_RESPONSE } from "../../enums/redux_action";

let initialState = {
  _token: "",
  keranjang: {},
  toko: {},

  //payload general
  response: null,
  isLoading: false,
  redirect: false,
};

const reducer = (state = initialState, action) => {
  if (action.type === "SET_DATA_KERANJANG") {
    return {
      ...state,
      keranjang: action.value,
    };
  }
  if (action.type === SET_DATA_TOKO) {
    return {
      ...state,
      toko: action.value,
    };
  }
  if (action.type === UPDATE_RESPONSE) {
    return {
      ...state,
      response: action.value,
    };
  }
  if (action.type === UPDATE_LOADING) {
    return {
      ...state,
      isLoading: action.value,
    };
  }

  if (action.type === UPDATE_REDIRECT) {
    return {
      ...state,
      redirect: action.value,
    };
  }


  // if (action.type === SET_ALL_PROMO_POINT) {
  //   return {
  //     ...state,
  //     promo_point: action.value,
  //   };
  // }
  return state;
};

export default reducer;
