import React from "react";
import { useDispatch, useStore } from "react-redux";
import Header1 from "../../../../parts/app/Header1";
import SectionPesanan from "../../../../parts/app/keranjang/SectionPesanan";
import SectionTotalBayar from "../../../../parts/app/keranjang/SectionTotalBayar";
import { Link, useParams } from "react-router-dom";
import { ArrowLeft } from "../../../../components/mikro/Icon";
import {PAGE_TITLE} from '../../../../enums/aplikasi'
import { action_transaksi_show_detail, action_transaksi_set_pesanan_selesai } from "../../../../store/action/transaksi_action";
import SectionInformasiTransaksi from "../../../../parts/app/keranjang/SectionInformasiTransaksi";
import SectionRincianPesanan from "../../../../parts/app/keranjang/SectionRincianPesanan";
import ModalLacakPengiriman from "../../../../parts/app/ModalLacakPengiriman";
import { useState } from "react";
import ModalKomplain from "../../../../parts/app/ModalKomplain";
import { Belum_Bayar, Dikirim, Diterima, Menunggu_Konfirmasi, Selesai } from "../../../../enums/status_transaksi";
import {
  BelumBayarMessage,
  KadaluarsaMessage,
  BelumBayarFooter,
  SudahBayarFooter,
  DikirimFooter,
  DiterimaFooter,
  SelesaiFooter,
} from './../../../../components/mikro/ButtonTransaction'

const DetailViaMalmora = () => {
  const dispatch = useDispatch()

  const store = useStore()

  const {kodeToko, idTransaksi} = useParams()

  const [transaksi, set_transaksi] = useState(null)
  const [pesananId, set_pesananId] = useState(null)
  const [isPanelLacak, set_isPanelLacak] = useState(false)
  const [isPanelKomplain, set_isPanelKomplain] = useState(false)

  const getTransaksi = () =>{
    dispatch(action_transaksi_show_detail(kodeToko,idTransaksi, (transaksi)=>{
      set_transaksi(transaksi)
    }))
  }
  const setDiterima = (idPesanan) =>{
    dispatch(action_transaksi_set_pesanan_selesai(kodeToko,transaksi.id, idPesanan, ()=>{
      getTransaksi()
    }))
  }

  useState(()=>{
    getTransaksi()
  },[])

  return (
    <div
      className="pb-1"
      style={{
        height: "100%",
        paddingTop: "58px",
      }}
    >
      <Header1 linkLeft={(<Link to={'/'+kodeToko+"/transaksi-saya"}>
        <div className="avatar-wrap pointer">
          <span class="font-xl p-1">
            <ArrowLeft width="24px" height="24px" />
          </span>
        </div>
        </Link>)} 
        title={PAGE_TITLE.transaksi_saya}
      />
      <div
        className="main-wrap product-page search-page index-page"
        style={{
          position: "relative",
          paddingBottom: "100px",
          backgroundColor:
            transaksi && transaksi?.pesanan ? "rgb(238, 238, 238)" : "#fff",
          height: "100%",
        }}
      >
        {transaksi &&
          transaksi?.pesanan &&
          transaksi?.pesanan.map((d, i) => (
            <div>
              <SectionInformasiTransaksi
                data={transaksi}
              />
            </div>
          ))}
        {transaksi &&
          transaksi?.pesanan &&
          transaksi?.pesanan.map((d, i) => (
            <div>
              <SectionPesanan
                data={d}
                isKeranjang={false}
                isTransaksi={true}
                onClickLacak={()=>{
                  set_pesananId(d.id);
                  setTimeout(()=>{
                    set_isPanelLacak(true);
                  },100)
                }}

                onClickKomplain={()=>{
                  set_pesananId(d.id);
                  setTimeout(()=>{
                    set_isPanelKomplain(true);
                  },100)
                }}

                onClickSelesai={()=>setDiterima(d.id)}
              />
            </div>
          ))}

          {transaksi && transaksi?.pesanan?.length > 0 && <SectionRincianPesanan data={transaksi}/>}
      </div>
      {/* Main End */}

      {/* Footer Start */}
      {transaksi && transaksi?.pesanan?.length > 0 && (
        <SectionTotalBayar
          isUsePoin={false}
          data={transaksi}
          customButton={<div>
            {/* {data.status === Belum_Bayar && <BelumBayarFooter onClickBayar={() => handleClickBayar()}  onClearTime={()=>this.props.onClearTime()}/>}
            {(data.status === Menunggu_Konfirmasi || data.status === Diproses) && <SudahBayarFooter status={data.status} />}
            {data.status === Dikirim && <DikirimFooter status={data.status} onClick={()=>handleClickList()}/>}
            {data.status === Diterima && <DiterimaFooter status={data.status} onClick={()=>handleClickList()}/>}
            {data.status === Selesai && <SelesaiFooter/>} */}
          </div>}
        />
      )}

      {transaksi && transaksi?.id && pesananId && <ModalLacakPengiriman 
        panelShow={isPanelLacak} 
        height={"100%"} 
        data={{transaksiId : transaksi?.id,pesananId:pesananId }}
        onClose={(e) => set_isPanelLacak(false)}/>}

      {transaksi && transaksi?.id && pesananId && <ModalKomplain 
        panelShow={isPanelKomplain} 
        height={"100%"} 
        data={{transaksiId : transaksi?.id,pesananId:pesananId,wa:transaksi?.reseller?.wa ?? '' }}
        onClose={(e) => {
          set_isPanelKomplain(false);
          getTransaksi()
        } }/>}
    </div>
  );
}

export default DetailViaMalmora;