import React from "react";
import { AngleRight } from "./../mikro/Icon";
import { useNavigate } from "react-router-dom";
import { appendSearchHistory } from "../../utils/history";
import Image from 'react-bootstrap/Image'
import { useSelector } from "react-redux";
const CardNavigationList = (props) => {
  const navigate = useNavigate();
  const toko = useSelector(state => state.toko) 
  return (
    <div>
      {props.title ? (
        <div className="px-2 py-3">
        <h5 className={"" + (props.classTitle || "")}>
          {props.title}
        </h5>
          {props.description ? <p className="w-100 text-start m-0 text-muted">{props.description}</p> : null}
        </div>
      ) : (
        ""
      )}
      <section className="order-id-section p-0 unmain-wrap bg-white border-top ">
        <ul class="navigation">
          {props.data.map((v, i) => (
            <List
              data={v}
              key={i}
              arrow={!props.withoutArrow}
              onClick={(dt) =>{
                  if(!props.isNotUseHistory && props.recordClick && v.title !== "")
                    appendSearchHistory(v.title);

                  if(v.to) navigate('/'+toko.url + v.to);

                  if(props.onClick)
                    props.onClick(v);                  
                }
              }
            />
          ))}
        </ul>
      </section>
    </div>
  );
};
export default CardNavigationList;

const List = ({ data, key, arrow,  ...props }) => {
  return (
    <div className="pointer" key={key} onClick={() => props.onClick() }>
      <li className="border-bottom">
        {data?.icon ? 
          <Image 
            rounded={true}
            src={data.icon}
            className="img-fluid me-4"
            width={'30px'}
            height={'30px'}
          /> : null }
        <div className="nav-link title-color font-sm flex-grow-1 flex-column gap-0 justify-content-start">
          <div className={"w-100 text-start " + (data.description ?" fw-bold font-md " : "")}>{data.title}</div>
          {data.description ? <div className="w-100 text-start m-0 text-muted">{data.description}</div> : null}
          {data.priceLabel ? <div className="w-100 text-start m-0 fw-bold text-danger">{data.priceLabel}</div> : null}
        </div>
        {arrow ? (
          <div className="arrow">
            <AngleRight />
          </div>
        ) : (
          ""
        )}
      </li>
    </div>
  );
};
