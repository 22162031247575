const Belum_Bayar = "Belum Bayar";
const Sudah_Bayar = "Sudah Bayar";
const Menunggu_Konfirmasi = "Menunggu Konfirmasi";
const COD = "COD";
const Diproses = "Diproses";
const Dikirim = "Dikirim";
const Diterima = "Diterima";
const Dikomplain = "Dikomplain";
const Selesai = "Selesai";
const Dibatalkan = "Dibatalkan";
const Kadaluarsa = "Kadaluarsa";

export {
    Belum_Bayar,
    Sudah_Bayar,
    Menunggu_Konfirmasi,
    COD,
    Diproses,
    Dikirim,
    Diterima,
    Dikomplain,
    Selesai,
    Dibatalkan,
    Kadaluarsa
}