import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination } from "swiper";

export default function Carousel(props) {
  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        grabCursor={true}
        autoplay={{
          delay: 1000000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination]}
        className="mySwiper"
      >
        {props.data &&
          props.data.map((v, i) => {
            return (
              <SwiperSlide>
                <div
                  class="banner-box bg-size"
                  style={{
                    maxHeight: props.height || "50vH",
                    backgroundImage: "url(" + v + ")",
                    backgroundSize: props.bgSize || "cover",
                    backgroundPosition: "center center",
                    backgroundRepeat: "no-repeat",
                    display: "block",
                  }}
                ></div>
              </SwiperSlide>
            );
          })}
      </Swiper>
    </>
  );
}
