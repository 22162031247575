import React, { Component } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import LabelForm from "../../../components/mikro/LabelForm";
import Header1 from "../../../parts/app/Header1";
import api, { addItem } from "../../../utils/api";
import { withRouter } from "../../../utils/WithRouter";
import { withSnackbar } from "react-simple-snackbar";
import MediaInput from "../../../components/mikro/MediaInput";
import { AtEmail, Calendar, Job } from "../../../components/mikro/Icon";
import { getAuth, setAuth } from "../../../utils/authUtils";
class EditProfil extends Component {
  state = {
    nama: "",
    nomor_hp: "",
    email: "",
    gambar: "",
    tanggal_lahir: new Date(),
    pekerjaan: "",
    jenis_kelamin: "Laki-laki",
    alamat: "",
  };
  componentDidMount() {
    const {
      nama,
      nomor_hp,
    } = getAuth();
    this.setState({
      nama: nama,
      nomor_hp: nomor_hp,
    });
  }
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  handleSimpan(e) {
    e.preventDefault();
    const {
      nama,
    } = this.state;
    addItem("profil", {nama}).then((res) => {
      if (res.code === 200) {
        setAuth(res?.data);
        this.props.openSnackbar(res.message, 500);
      }
    });
  }
  render() {
    const {
      nama,
      nomor_hp,
    } = this.state;
    return (
      <div
        style={{
          paddingTop: "56px",
        }}
      >
        <Header1
          title="Edit Profil"
          linkNotification={false}
          linkCart={false}
        />
        <main class="main-wrap setting-page my-5 pb-5">
          <form class="custom-form" onSubmit={(e) => this.handleSimpan(e)}>
            <LabelForm label="Nama" isRequired={false} />
            <div className="input-box text-start">
              <input
                type="text"
                name="nama"
                value={nama}
                placeholder="(contoh: Budi Darma)"
                required
                className="form-control"
                onChange={(e) => this.handleChange(e)}
              />
              <i className="iconly-Profile icli"></i>
            </div>

            <LabelForm label="Nomor HP Aktif" isRequired={false} />
            <div className="input-box text-start">
              <input
                className="form-control"
                value={nomor_hp}
                readOnly={true}
              />
              <i className="iconly-Call icli"></i>
            </div>
            <button type="submit" class="btn-solid py-3">
              Simpan
            </button>
          </form>
        </main>
      </div>
    );
  }
}

export default withRouter(
  withSnackbar(EditProfil, {
    style: {
      zIndex: 100,
    },
  })
);
