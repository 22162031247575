import React, { Component } from "react";
import Header1 from "../../../parts/app/Header1";
import { getList } from "../../../utils/api";
import { withRouter } from "../../../utils/WithRouter";
import SectionBrand from "./SectionBrand";
import SectionKategori from "./SectionKategori";
class Kategori extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kategori: [],
      merek:[],
    };
  }

  componentDidMount() {
    this.getIndex();
    this.getMerek();
  }

  getIndex() {
    getList(this.props.params.kodeToko+"/list-kategori", {}).then((res) => {
      if (res.code === 200) {
        this.setState(
          {
            kategori: res?.data,
          },
        );
      }
    });
  }

  getMerek(){
    getList(this.props.params.kodeToko+"/list-merek")
    .then((res) => {
      if (res.code === 200) {
        this.setState({merek:res?.data});
      }
    })
  }

  render() {
    const { kategori, merek } = this.state;
    const {kodeToko} = this.props.params
    return (
      <div
        style={{
          paddingTop: "56px",
          minHeight:"calc(100vH - 56px)",
          backgroundColor: "#eeeeee"
        }}
      >
        <Header1
         
          linkBackTo={'/'+kodeToko}
          title="Kategori"
          linkNotification={false}
          linkCart={true}
        />
        <div
          className="catagories-wide "
          style={{ position: "relative", marginBottom: "40px" }}
        >
            
          <div className="w-100 h-100">
            <SectionBrand items={merek}/>

            <SectionKategori items={kategori}/>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Kategori)