import React from "react";

const CardListChecklist = () => {
  return (
    <span class="card-list-checked">
      <svg
        width="18"
        height="15"
        viewBox="0 0 35 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M2 9.33333L9.75 24L33 2" stroke="white" stroke-width="4" />
      </svg>
    </span>
  );
};

export default CardListChecklist;
