import React from "react";
import { Trash } from "./Icon";
const CartTrashButton = (props) => {
  return (
    <div
      className="media py-1 m-0 text-danger pointer d-flex flex-column"
      {...props}
    >
      <div className="flex-fill ms-auto">
        <Trash width="20" height="20" />
      </div>
    </div>
  );
};

export default CartTrashButton;
