import React from "react";
import { Link, useParams } from "react-router-dom";
import { Cart } from "../../../components/mikro/Icon";
const EmptyCart = () => {

  const {kodeToko} = useParams()

  return (
    <div className="main-wrap empty-cart mb-xxl text-center d-flex flex-column">
      <div className="mx-auto mt-5 mb-3">
        <img
          src={require("../../../assets/images/empty-cart.png")}
          className="img-fluid"
          alt="404"
        />
      </div>

      <div className="error mb-large section-p-tb">
        <h2 className="font-lg">Keranjang belanja masih kosong</h2>
        <p className="content-color font-sm text-center">
          Yuk isi keranjang belanjamu sekarang!
        </p>
        <Link to={'/'+kodeToko} className="btn btn-primary py-2 px-4">
          <div className=" d-flex align-items-center ">
            <Cart width="22" height="22" />
            &nbsp;
            <span>Mulai Belanja</span>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default EmptyCart;
